import React from "react";
import PropertyRegistry from "../registryForms/PopertyRegistry";
import { uniqueIdBuilder } from "../../util/Dynamics";

function PropertyCard(props) {

    const onRemove = ({ id }) => {
        props.remove(id);
    }

    return (
        <>
            {
                props.info.map((itm, idx) => {
                    return (
                        <div className="cmp-info-button-card radio-card" key={uniqueIdBuilder()}>
                            <div className="card p-3 p-lg-4">
                                <div className="card-header mb-0 p-0">
                                    <div className="form-check m-0">
                                        <input
                                            type="button"
                                            value="Rimuovi"
                                            onClick={() => onRemove(itm)}
                                            style={{
                                                borderRadius: "4px",
                                                outline: "none",
                                                border: "none",
                                                background: "var(--primary)",
                                                color: "white",
                                                fontSize: "12px",
                                                float: "right"
                                            }} />
                                    </div>
                                </div>
                                <div className="card-body p-0">
                                    <p className="card-info">Tipologia <br /> <span>{itm?.dati_immobile?.tipologia_immobile}</span></p>
                                    <PropertyRegistry
                                        year={props.year}
                                        card={itm}
                                        update={props.update}
                                        key={uniqueIdBuilder()} />
                                </div>
                            </div>
                        </div>
                    )
                })
            }

        </>
    )
}

export default PropertyCard;