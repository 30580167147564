import React, { useState } from "react";
import { useSelector } from "react-redux";
import SummaryCard from "../../../UI-component/summary/SummaryCard";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import FormBoxField from "../../../UI-component/ui-el/FormBoxField";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import { useDispatch } from "react-redux";
import stepSlice from "../../../context/StepsContext";
import Loader from "../../../UI-component/Loader";
import { useBonus } from "./bonus";

function Summary(props) {
  const {data, values, loading, error, save, savePrefs, update, isValidStep, getBonusId} = useBonus(props.save, props?.info?.data || {}, props.send);

  const onNext = async () => {
    save(true);
  }


  const renderCreditInfo = () => {
    return(
      <SummaryCard
        type="Dati per l'accredito"
        cardTitle={`${credit?.intestatario}`}
        mod={true}
        update={update}
        id={"credito"}
        taxID={""}
        service={props.info.code}
        registryData={credit}
      >
        <FormBoxField title="Intestatario" value={credit?.intestatario} />
        <FormBoxField title="Banca" value={credit?.banca} />
        <FormBoxField title="Filiale" value={credit?.filiale} />
        <FormBoxField title="Indirizzo" value={credit?.indirizzo} />
        <FormBoxField title="Codice IBAN" value={credit?.codice_iban} />
        <FormBoxField title="Documento IBAN" value={credit?.iban} />
      </SummaryCard>
    )
  }

  const renderFamilyInfo = () => {
    // console.log("renderFamilyInfo", data?.nucleo_familiare)
    return data?.nucleo_familiare?.map((member, index) => {
      return(
        <SummaryCard
          type="nucleo_familiare"
          title={`Componente nucleo familiare: ${member?.anagrafica?.nome} ${member?.anagrafica?.cognome}`}
          cardTitle={`${member?.anagrafica?.nome} ${member?.anagrafica?.cognome}`}
          mod={true}
          update={(data) => update({...data, index})}
          id={"nucleo_familiare"}
          taxID={""}
          service={"DBE-family"}
          registryData={member}
        >
          <FormBoxField title="Nome" value={member?.anagrafica?.nome} />
          <FormBoxField title="Cognome" value={member?.anagrafica?.cognome} />
          <FormBoxField title="Data di nascita" value={member?.anagrafica?.data_di_nascita} />
          <FormBoxField title="Anni" value={member?.anagrafica?.anni} />
          <FormBoxField title="Grado parentela" value={member?.anagrafica?.grado_parentela} />
        </SummaryCard>
      )
    })
  }

  const renderExtraInfo = () => {
    const bonusId = getBonusId();
    switch(bonusId){
      case "BA3":
        return (
          <>
            {renderFamilyInfo()}

            <SummaryCard
              type="Dichiaro"
              mod={false}
              cardTitle={``}
              service="DBE"
              >
                {data.dichiaro.map(el => {
                    return <FormBoxField title="" value={el?.indexOf("-") === 1 ? el?.slice(2) : el} key={el} />
                })}
            </SummaryCard>

            <SummaryCard
              type="Consapevole"
              mod={false}
              cardTitle={``}
              service="DBE">
                {data.consapevole.map(el => {
                    return <FormBoxField title="" value={el?.indexOf("-") === 1 ? el?.slice(2) : el} key={el} />
                })}
            </SummaryCard>
            
          </>
        )
      default:
        return renderCreditInfo();
    }
  }


  const applicant = data?.richiedente?.[0] || {}
  const isee = data?.isee?.[0] || {}
  const credit = data?.credito || {}

  return (
    <div className="row">
      <div className="col-12 col-lg-8 offset-lg-2">
        <div className="steppers-content" aria-live="polite">
          <div className="it-page-sections-container">

            <div style={{ position: "fixed", top: "50%", left: "50%", zIndex: "10000000000000" }}>
              <Loader loading={loading} />
            </div>

            <SummaryCard
              type="richiedente"
              cardTitle={`${applicant.anagrafica?.nome} ${applicant.anagrafica?.cognome}`}
              mod={true}
              update={update}
              id={"richiedente"}
              taxID={applicant?.anagrafica.codice_fiscale}
              service={props.info.code}
              registryData={applicant}
            >
              <FormBoxField title="Nome" value={applicant?.anagrafica.nome} />
              <FormBoxField title="Cognome" value={applicant?.anagrafica.cognome} />
              <FormBoxField title="Codice Fiscale" value={applicant?.anagrafica.codice_fiscale} />
              <FormBoxField title="Data di nascita" value={applicant?.anagrafica.data_di_nascita} />
              <FormBoxField title="Luogo di nascita" value={applicant?.anagrafica.luogo_di_nascita} />
              <FormBoxField title="Indirizzo residenza" value={applicant?.indirizzi.residenza} />
              <FormBoxField title="Comune residenza" value={applicant?.indirizzi.domicilio} />
              <FormBoxField title="Telefono" value={applicant?.contatti.telefono} />
              <FormBoxField title="Email" value={applicant?.contatti.email} />
              <FormBoxField title="Carta di identità " value={applicant?.documenti.carta_di_identita} />
            </SummaryCard>

            <SummaryCard
              type="isee"
              cardTitle={`${isee?.valore_isee}`}
              mod={true}
              update={update}
              id={"isee"}
              taxID={""}
              service={props.info.code}
              registryData={isee}
            >
              <FormBoxField title="Valore ISEE" value={isee?.valore_isee} />
              <FormBoxField title="Scadenza ISEE" value={isee?.scadenza_isee} />
              <FormBoxField title="Anno protocollo" value={isee?.anno_protocollo} />
              <FormBoxField title="Numero protocollo" value={isee?.numero_protocollo} />
              <FormBoxField title="Codice filiale protocollo" value={isee?.codice_filiale_protocollo} />
              <FormBoxField title="Certificato isee" value={isee?.certificato_isee} />
            </SummaryCard>

            {renderExtraInfo()}

            <SummaryCard
              type="Preferenze"
              mod={true}
              cardTitle={`Preferenze di servizio`}
              service="DBE"
              registryData={data}
              choiceData={[
                {
                  "options": values.bonuses || [],
                  "title": [
                    "BONUS",
                    "TIPOLOGIA"
                  ],
                  "type": "Select"
                }
              ]
              }
              update={update}>
              <FormBoxField title="Tipologia" value={data.tipologia || "Non compilato"} />
            </SummaryCard>

            <Loader loading={loading} fixedPosition/>
            
            <NextBtnForm
              last={true}
              send={onNext}
              back={props.back}
              noSave={false}
              saveRequest={save}
              disabled={!isValidStep("summary")} />


            {
              props.saved &&
              <SuccessfullPopup />
            }

            {
              props.fail &&
              <SuccessfullPopup isError={true} />
            }
          </div>
        </div>
      </div>
    </div>
  )
}


export default Summary;