import React from "react";
import PopupTextField from "../ui-el/PopupTextField";
import { onDataChange2, onFileSet2 } from "../../util/Dynamics";
import PopupBox from "../PopupBox";


function DeceasedUiForm(props) {
  const isDealer = props.id.toLowerCase() === "concessionario";
  // const enableBlockedFields = municipalityCode === "B214";
  let user = localStorage.getItem("user") ? JSON.parse(JSON.parse(localStorage.getItem("user"))?.data) : {};
  const prefData = props.prefData;
  // console.log("props?.defaultValue", props?.defaultValue, (isDealer || props.edit) ? (props?.defaultValue?.anagrafica?.data_di_nascita || prefData?.anagrafica?.data_di_nascita || user.dateOfBirth) : "")

  return (
    <PopupBox close={props.close} save={props.save} title={props?.id}>
      {
        props.error &&
        <p style={{ color: "red", fontWeight: "bolder" }}>Controlla i campi email o codice fiscale/Completa tutti i campi</p>
      }

      <PopupTextField
        disabled={isDealer ? true : false}
        defaultValue={(isDealer || props.edit) ? (props?.defaultValue?.anagrafica?.nome || prefData?.anagrafica?.nome) : ""}
        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
        fieldType="text"
        id="nome"
        fieldTitle="Nome*"
        description={isDealer ? " " : ""}
        error={props.error}
      />
      <PopupTextField
        defaultValue={(isDealer || props.edit) ? (props?.defaultValue?.anagrafica?.cognome || prefData?.anagrafica?.cognome) : ""}
        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
        fieldType="text"
        id="cognome"
        fieldTitle="Cognome*"
        disabled={isDealer ? true : false}
        description={isDealer ? " " : ""}
        error={props.error}
      />
      <PopupTextField
        description={isDealer ? " " : ""}
        defaultValue={(isDealer || props.edit) ? (props?.defaultValue?.anagrafica?.codice_fiscale || prefData?.anagrafica?.codice_fiscale) : ""}
        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
        fieldType="text"
        id="codice_fiscale"
        fieldTitle="Codice Fiscale*"
        disabled={isDealer ? true : false}
        regex="^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]{1}$"
        error={props.error}
      />
      <PopupTextField
        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
        fieldType="date"
        id="data_di_nascita"
        fieldTitle="Data di nascita*"
        defaultValue={(isDealer || props.edit) ? (props?.defaultValue?.anagrafica?.data_di_nascita || prefData?.anagrafica?.data_di_nascita || user.dateOfBirth) : ""}
        error={props.error}
        disabled={isDealer ? true : false}
        // disabled={false}
      />
      <PopupTextField
        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
        fieldType="text"
        id="luogo_di_nascita"
        fieldTitle="Luogo di nascita*"
        defaultValue={(isDealer || props.edit) ? (props?.defaultValue?.anagrafica?.luogo_di_nascita || prefData?.anagrafica?.luogo_di_nascita || user.placeOfBirth) : ""}
        error={props.error}
        disabled={isDealer ? true : false}
        // disabled={false}
      />
      {
        isDealer ?
          <>
            <PopupTextField
              onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
              fieldType="text"
              id="residenza"
              fieldTitle="Indirizzo residenza*"
              defaultValue={props?.defaultValue?.indirizzi?.residenza || prefData?.indirizzi?.residenza}
              error={props.error}
            />
            <PopupTextField
              onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
              fieldType="text"
              id="domicilio"
              fieldTitle="Comune residenza*"
              defaultValue={props?.defaultValue?.indirizzi?.domicilio || prefData?.indirizzi?.domicilio}
              error={props.error}
            />
            <>
              <PopupTextField
                onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                fieldType="text"
                id="telefono"
                fieldTitle="Telefono*"
                defaultValue={props?.defaultValue?.contatti?.telefono || prefData?.contatti?.telefono}
                regex="[0-9]{8}"
                error={props.error}
              />
              <PopupTextField
                onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                fieldType="email"
                id="email"
                fieldTitle="Email*"
                defaultValue={props?.defaultValue?.contatti?.email || prefData?.contatti?.email}
                regex={`[\\w\\D]+.[\\w\\D]+@[\\w\\D]{3,20}[.][a-zA-Z\\D]{2,10}`}
                error={props.error}
              />
            </>
            <PopupTextField
              onChange={(e) => { onFileSet2(e, props.setCustomerData) }}
              defaultValue={props.defaultValue?.documenti?.carta_di_identita || prefData?.documenti?.carta_di_identita || ""}
              fieldType="file"
              id={"carta_di_identita"}
              fieldTitle={"Carta di identità*"}
              error={props.error}
            /></>
          :
          <>
            <PopupTextField
              onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
              fieldType="date"
              id="data_di_morte"
              fieldTitle="Data di morte*"
              defaultValue={props?.defaultValue?.anagrafica?.data_di_morte || prefData?.anagrafica?.data_di_morte}
              error={props.error}
            />
            <PopupTextField
              onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
              fieldType="text"
              id="luogo_di_morte"
              fieldTitle="Luogo di morte*"
              defaultValue={props?.defaultValue?.anagrafica?.luogo_di_morte || prefData?.anagrafica?.luogo_di_morte}
              error={props.error}
            />
            <PopupTextField
              onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
              fieldType="text"
              id="paternita"
              fieldTitle="Paternità*"
              defaultValue={props?.defaultValue?.anagrafica?.paternita || prefData?.anagrafica?.paternita}
              error={props.error}
            />
            <PopupTextField
              onChange={(e) => { onFileSet2(e, props.setCustomerData) }}
              defaultValue={props?.defaultValue?.documenti?.certificato_di_morte || prefData?.documenti?.certificato_di_morte || ""}
              fieldType="file"
              id={"certificato_di_morte"}
              fieldTitle={"Certificato di morte*"}
              error={props.error} />

          </>
      }

    </PopupBox>





  )
}

export default DeceasedUiForm;