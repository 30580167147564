import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideList from "../../../UI-component/SideList";
import ErrorAlert from "../../../UI-component/ErrorAlert";
import CustomerFormBox from "../../../UI-component/registryForms/CustomerFormBox";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import stepSlice from "../../../context/StepsContext";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import Loader from "../../../UI-component/Loader";
import SummaryCard from "../../../UI-component/summary/SummaryCard";
import PopupTextField from "../../../UI-component/ui-el/PopupTextField";
import { onFileSet2 } from "../../../util/Dynamics";
import IseeFormBox from "../../../UI-component/registryForms/IseeFormBox";
import FamilyFormBox from "../../../UI-component/registryForms/FamilyFormBox";
import { getTenantCode } from "../../../util";
import { useBonus } from "./bonus";
import CheckedFormBox from "../../../UI-component/CheckedFormBox";

function Specific(props) {
  const iseeRequired = useSelector(() => true);
  const isStopped = useSelector(state => state.stepSlice.data.stopped);

  // console.log("bonus", ctx);
  const {data, loading, error, save, savePrefs, update, isValidStep, getBonusId} = useBonus(props.save, props?.info?.data || {});

  const [files, setFiles] = useState({});

  const [familyMembers, setFamilyMembers] = useState([]);

  const onNext = () => {
    savePrefs()
    props.next();
  }

  const onChange = (e) => {
    update({
      id: "credito",
      internalId: e.target.id,
      data: e.target.value
    })
  }

  useEffect(() => {
    if(files?.["base64_iban"] || files?.["iban"]){
      update([
        {
          id: "credito",
          internalId: "base64_iban",
          data: files?.["base64_iban"]
        },
        {
          id: "credito",
          internalId: "iban",
          data: files?.["iban"]
        }
      ])
    }
  }, [files])

  const getInfoList = () => {
    const bonusId = getBonusId();
    switch(bonusId){
      case "BA3":
        return [
          { element: "Richiedente", id: "#report-author" },
          { element: "ISEE", id: "#report-author" },
          { element: "Nucleo familiare", id: "#report-author" },
        ]
      default:
        return [
          { element: "Richiedente", id: "#report-author" },
          { element: "ISEE", id: "#report-author" },
          { element: "Dati per l'accredito", id: "#report-author" },
        ]
    }
  }

  const renderCreditInfo = () => {
    return(
      <SummaryCard
        type="Dati per l'accredito*"
        description={"Inserisci i dati per l'accredito"}>
        <PopupTextField
          defaultValue={data?.credito?.intestatario}
          fieldType="text"
          id="intestatario"
          fieldTitle="Intestatario*"
          description="Inserisci l'intestatario"
          max="18"
          onChange={onChange}
        />
        <PopupTextField
          defaultValue={data?.credito?.banca}
          fieldType="text"
          id="banca"
          fieldTitle="Banca*"
          description="Inserisci il nome della banca"
          max="18"
          onChange={onChange}
        />
        <PopupTextField
          defaultValue={data?.credito?.filiale}
          fieldType="text"
          id="filiale"
          fieldTitle="Filiale*"
          description="Inserisci la filiale"
          max="18"
          onChange={onChange}
        />
        <PopupTextField
          defaultValue={data?.credito?.indirizzo}
          fieldType="text"
          id="indirizzo"
          fieldTitle="Indirizzo*"
          description="Inserisci l'indirizzo"
          max="18"
          onChange={onChange}
        />
        <PopupTextField
          defaultValue={data?.credito?.codice_iban}
          fieldType="text"
          id="codice_iban"
          fieldTitle="Codice IBAN*"
          description="Inserisci il codice IBAN"
          max="34"
          min="34"
          straightErr={error?.credito?.codice_iban}
          onChange={onChange}
        />

        <PopupTextField
          onChange={(e) => { onFileSet2(e, setFiles) }}
          fieldType="file"
          id="iban"
          defaultValue={data?.credito?.iban || ""}
          fieldTitle="Documento IBAN*"
          description="Allegare il documento IBAN" />

      </SummaryCard>
    )
  }

  const renderExtraContent = () => {
    const bonusId = getBonusId();
    switch(bonusId){
      case "BA3":
        return(
          <>
            <FamilyFormBox id="nucleo_familiare"
              cards={data?.nucleo_familiare?.length > 0 ? [data?.nucleo_familiare] : []}
              type={false}
              description="Informazione sui componenti del tuo nucleo familiare"
              title="Nucleo familiare"
              service={props.info.code}
              update={update}
              prefData={props.info.data?.nucleo_familiare || []}
            />
            <CheckedFormBox
              type="checkbox"
              title={["Bonus economici", "Dichiaro"]}
              description=""
              choices={[
                  { value: "1-di impegnarsi a comunicare tempestivamente al Comune di Brugnato il venir meno di uno qualunque dei requisiti previsti dal Bando;" },
                  { value: "2-di aver preso visione e di accettare quanto stabilito nel Banco di cui all’oggetto e di essere consapevole che: i Buoni Alimentari sono spendibili esclusivamente per l’acquisto di prodotti alimentari per soddisfare le necessità più urgenti ed essenziali presso esercizi commerciali convenzionati; i requisiti dovranno permanere per tutto il tempo di validità della graduatoria, pena la decadenza dalla stessa; ai Cittadini, collocatisi utilmente in graduatoria, verranno corrisposti i Buoni nella misura indicata nel Bando, fino a concorrenza dei fondi stanziati, fatta salva fatta salva la rideterminazione dell’importo sulla base del numero del numero delle istanze ammesse a seguito i controlli effettuati dai preposti uffici; i Cittadini in graduatoria non possono in nessun modo vantare alcun diritto alla corresponsione dei buoni se non nella misura dei fondi stanziati, fatta salva la rideterminazione dell’importo sulla base del numero del numero delle istanze ammesse a seguito i controlli effettuati dai preposti uffici." }
              ]}
              defaultValue={data?.dichiaro}
              update={update}
            />

            <CheckedFormBox
              type="checkbox"
              title={["Bonus economici", "Consapevole"]}
              description=""
              choices={[
                  { value: "1-ai sensi dell’art. 71 del DPR. 445/2000 spetta all’Amministrazione comunale procedere ad idonei controlli, anche avvalendosi della collaborazione della Guardia di Finanza, per accertare la veridicità delle dichiarazioni rese e la documentazione presentata dai richiedenti. Ferme restando le sanzioni penali previste dall’art. 76 del D.P.R. n. 445/2000, qualora dal controllo emerga la non veridicità del contenuto delle dichiarazioni rese, il dichiarante decadrà dai benefici conseguiti, fermo restando le denunce alle Autorità competenti. L’Amministrazione comunale agirà per il recupero delle somme indebitamente percepite." },
                  { value: "2-ai fini del rispetto delle vigenti disposizioni in materia di privacy (D. Lgs 196/2003) e del Regolamento Europeo N. 679/2016 si rende noto che le INFORMAZIONI SUL TRATTAMENTO DEI DATI PERSONALI, ai sensi dell’artt. 13 e 14 del Regolamento UE 2016/679 sono allegate al presente modulo." }
              ]}
              defaultValue={data?.consapevole}
              update={update}
            />
          </>
        )
      default:
        return renderCreditInfo();
    }
  }

  // console.log("is valid step", isValidStep("specific"), error)

  return (
    <div className="row">
      <SideList
        infoList={getInfoList()} />

      <div className="col-12 col-lg-8 offset-lg-1">
        <div className="steppers-content" aria-live="polite">
          <div className="it-page-sections-container">
            {
              <ErrorAlert errors={[{ name: "Richiedente", id: "#isee-info" }]} err={error.richiedente || isStopped} />
            }

            {
              <ErrorAlert errors={[{ name: "ISEE", id: "#isee-info" }]} err={error.isee} />
            }

            {
              <ErrorAlert errors={[{ name: "Dati per l'accredito", id: "#isee-info" }]} err={error.credito} />
            }

            <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-55%,-50%)", zIndex: "10000" }}>
              <Loader loading={loading} />
            </div>
            <CustomerFormBox id="richiedente"
              cards={data?.richiedente?.length > 0 ? [data?.richiedente] : []}
              type={false}
              description="Informazione su di te"
              title="Richiedente"
              service={props.info.code}
              update={update}
              prefData={props.info.data?.richiedente}
            />

            <IseeFormBox
              id={"isee"}
              description={"Informazioni sul valore dell’ISEE del tuo nucleo familiare basato sulla tua ultima dichiarazione"}
              optionalTitle={false}
              cards={data?.isee?.length > 0 ? data?.isee : []}
              update={update}
              placeholderData={props.info?.data?.isee}
              required={iseeRequired}
            />

            
            {renderExtraContent()}

          </div>
        </div>
        <NextBtnForm
          next={onNext}
          back={props.back}
          noSave={false}
          saveRequest={save}
          // disabled={error.richiedente || error.credito || error.isee || error.nucleo_familiare || isStopped} />
          disabled={!isValidStep("specific")} />

        {
          props.saved &&
          <SuccessfullPopup />
        }

        {
          props.fail &&
          <SuccessfullPopup isError={true} />
        }
      </div>
    </div>
  )
}


export default Specific;