import { createSlice } from '@reduxjs/toolkit';
import { getStoredConfiguration } from '../../../util';

const initialState = {
  configuration: getStoredConfiguration() || {}
}

export const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    clearState: () => {
      return initialState
    },
    setConfiguration: (state, action) => {
       state.configuration = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const {setConfiguration} = mainSlice.actions
export const configSelector = (state) => state.main.configuration;
export default mainSlice.reducer;