import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SummaryCard from "../../../UI-component/summary/SummaryCard";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import FormBoxField from "../../../UI-component/ui-el/FormBoxField";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import { useNavigate } from "react-router-dom";
import stepSlice from "../../../context/StepsContext";
import Loader from "../../../UI-component/Loader";

function Summary(props) {
    const ctxApplicant = useSelector(state => state.stepSlice.data.richiedente?.[0]);
    const ctxInvoice = useSelector(state => state.stepSlice.data.fatture?.[0]);
    const ctxPayment = useSelector(state => state.stepSlice.data.pagamento);
    const ctxFine = useSelector(state => state.stepSlice.data.multa);
    const ctxPractice = useSelector(state => state.stepSlice.data.practiceNumber);
    const [loading, setLoading] = useState(false);
    const dispatcher = useDispatch();
    const navigate = useNavigate();


    const onNext = async () => {
        const data = {
            richiedente: [ctxApplicant],
            fatture: { ...ctxInvoice },
            pagamento: { ...ctxPayment }
        }

        // console.log("summary", data)

        const res = await props.send(data);


        navigate("/payment-pa", {
            state:
            {
                data:
                {
                    ...res.data, amount: data.fatture.importo, title: data.fatture.causale, iuv: data.fatture.iuv,
                    headTitle: props.info.headData.title,
                    headDesc: props.info.headData.description
                }
            }
        });
    }

    useEffect(() => {

    }, [])

    const save = async () => {
        const data = {
            richiedente: [ctxApplicant],
            fatture: [ctxInvoice],
            multa: ctxFine,
            practice_number: ctxPractice
        //   richiedente: applicant?.length > 0 ? [{ ...applicant[0], selected: true }] : []
        }
        setLoading(true);
        // console.log("data", applicant);
    
        dispatcher(stepSlice.actions.dynamicSave({ id: "Richiedente", data: { ...ctxApplicant, selected: true } }));
        dispatcher(stepSlice.actions.dynamicSave({ id: "fattura", data: [ctxInvoice] }));
        await props.save(data);
    

        setLoading(false);
    }

    return (

        <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2">
                <div className="steppers-content" aria-live="polite">
                    <div className="it-page-sections-container">
                        <SummaryCard
                            type="Effettuato da"
                            cardTitle={`${ctxApplicant?.anagrafica?.nome} ${ctxApplicant?.anagrafica.cognome}`} >
                            <FormBoxField title="Nome" value={ctxApplicant?.anagrafica.nome} />
                            <FormBoxField title="Cognome" value={ctxApplicant?.anagrafica.cognome} />
                            <FormBoxField title="Codice Fiscale" value={ctxApplicant?.anagrafica.codice_fiscale} />
                            <FormBoxField title="Data di nascita" value={ctxApplicant?.anagrafica.data_di_nascita} />
                            <FormBoxField title="Luogo di nascita" value={ctxApplicant?.anagrafica.luogo_di_nascita} />
                            <FormBoxField title="Indirizzo residenza" value={ctxApplicant?.indirizzi.residenza} />
                            <FormBoxField title="Comune residenza" value={ctxApplicant?.indirizzi.domicilio} />
                            <FormBoxField title="Telefono" value={ctxApplicant?.contatti.telefono} />
                            <FormBoxField title="Email" value={ctxApplicant?.contatti.email} />
                        </SummaryCard>

                        <SummaryCard type="Avviso di pagamento">
                            <FormBoxField title="Codice IUV" value={ctxInvoice?.iuv} />
                            <FormBoxField title="Causale" value={ctxInvoice?.causale} />
                            <FormBoxField title="Ente" value={ctxInvoice?.ente} />
                            <FormBoxField title="Codice Fiscale ente" value={ctxInvoice?.partita_iva_ente} />
                            <FormBoxField title="Intestatario" value={ctxInvoice?.intestatario} />
                            <FormBoxField title="Codice Fiscale intestatario" value={ctxInvoice?.codice_fiscale} />
                            <FormBoxField title="Data scadenza" value={ctxInvoice?.data_scadenza} />
                            <FormBoxField title="Importo dovuto" value={ctxInvoice?.importo} />
                            <FormBoxField title="Targa" value={ctxInvoice?.targa} />
                        </SummaryCard>

                        <Loader loading={loading} fixedPosition/>

                        <NextBtnForm
                            // send={onNext}
                            next={onNext}
                            back={props.back}
                            noSave={false}
                            saveRequest={save}
                            disabledSave={loading}
                            disabled={loading}
                        />
                        {
                            props.saved &&
                            <SuccessfullPopup />
                        }

                        {
                            props.fail &&
                            <SuccessfullPopup isError={true} />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Summary;