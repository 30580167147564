import React, { useEffect, useState } from "react";
import { ConfigurationAPI } from "../Apis/ConfigurationAPI";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { configSelector } from "../context/store/slices/mainSlice";
import { getStoredConfiguration, updateAppointmentUrl, updateLink } from "../util";


function BaseFooter() {
  const [footer, setFooter] = useState(null);
  const location = useLocation();
  const configuration = useSelector(configSelector);

  function b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }

  const footerDynamic = async (newConfiguration) => {
    try {
      if (newConfiguration?.data?.footer) {
        const foot = b64DecodeUnicode(newConfiguration.data?.footer);
        setFooter(foot);
        return;
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    footerDynamic(configuration);
  }, [configuration])

  useEffect(() => {
    updateAppointmentUrl();
  }, [location])

  useEffect(() => {
    if(footer){
      // console.log("BaseFooter ->>>>>> configuration changed", configuration?.data)
      updateAppointmentUrl();
    }
  }, [footer])

  return (
    <div >
      {
        footer &&
        <div dangerouslySetInnerHTML={{ __html: footer }}></div>
      }
    </div>
  )
}


export default BaseFooter;