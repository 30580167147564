import { api, apiAuth } from "./Configs/AxiosConfigs";

export const ServiceDetailsAPI = {
  getServiceDetails: async function (code) {
    const response = await api.request({
      url: "/service-details/" + code,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      }
    })
    return response.data;
  },
  getSaved: async function (id) {
    const response = await api.request({
      url: "/preference/" + id,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      }
    })
    return response.data;
  },

  getBreadcrumbs: async function (id) {
    const response = await api.request({
      url: "/get-breadcrumbs/" + id,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      }
    })
    return response.data;
  },


}; 
