import React, { useState } from "react";
import PopupBox from "../PopupBox";
import PopupTextField from "../ui-el/PopupTextField";
import { useDispatch } from "react-redux";
import stepSlice from "../../context/StepsContext";
import { checkValidity, onDirectDataChange, onSelectData2 } from "../../util/Dynamics";
import PopupSelectField from "../ui-el/PopupSelectField";


function CoobEditPopup(props) {
    const [_, setSelection] = useState(false);
    const [error, setError] = useState(false);

    const [cobData, setCobData] = useState({
        codice_fiscale: props.registryData.codice_fiscale,
        identificativo_coobbligato: props.registryData.identificativo_coobbligato
    })

    const save = () => {
        if (checkValidity(cobData)) {
            // dispatcher(stepSlice.actions.dynamicEdit({id: "Coobbligato",data: cobData}));
            props.update({
                id: "coobbligato",
                data: cobData
            });
            props.close();
        }
        else
            setError(true);
    }

    return (
        <PopupBox
            close={props.close}
            save={save}
            title="Coobligato"
            cobData={props.registryData}>
            <PopupTextField
                defaultValue={cobData?.codice_fiscale ?? ""}
                id="codice_fiscale"
                onChange={(e) => { onDirectDataChange(e, setCobData) }}
                fieldType="text"
                fieldTitle="Codice Fiscale*"
                cdescription="" />
            <PopupSelectField
                defaultValue={cobData.identificativo_coobbligato}
                onChange={(e) => { onSelectData2(e, "coobbligato", setCobData, setSelection) }}
                id="identificativo_coobbligato" fieldTitle="Identificativo coobbligato"
                description="Seleziona la categoria del servizio per cui vuoi richiedere assistenza"
                options={[
                    "02 - Genitore / Tutore / Amministratore di sostegno",
                    "03 - Curatore fallimentare",
                    "07 - Erede",
                    "08 - Defunto",
                    "09 - Committente (Ris. 109/E/2019 AdE)",
                    "50 - Obbligato solidale",
                    "51 - Intervento sostitutivo",
                    "60 - Garante/terzo datore",
                    "61 - Soggetto aderente al consolidato",
                    "62 - Soggetto diverso dal fruitore del credito",
                    "63 - Controparte",
                    "64 - Gestore del deposito fiscale o del deposito del destinatario registrato",
                    "70 - Impresa assicuratrice estera fiscalmente rappresentata",
                    "71 - Soggetto che ha proposto l'atto introduttivo del giudizio",
                    "72 - Rappresentante fiscale",
                    "73 - Contribuente",
                    "74 - Soggetto risultante dall'operazione straordinaria"
                ]} />
        </PopupBox>
    )
}

export default CoobEditPopup;