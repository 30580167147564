import { useEffect, useState } from "react";

import AuthenticatedTenant from "../../Layouts/AuthenticatedLayoutTenant";
import Privacy from "../_Partials/Privacy";
import PersonaFisica from "../_Partials/PersonaFisica";
import WorkPath from "../../UI-component/WorkPath";
import CompactInfo from "../../UI-component/CompactInfo";
import DynamicStepContent from "../../UI-component/DynamicStepContent";

export default function IscrizioneScuolaInfanzia(props) {
  const [header, setHeader] = useState("");
  const [user, setUser] = useState("");

  //initialize localStorage conf once when component mounts ?
  useEffect(() => {
    //inizioalize first view first step
    if (localStorage.getItem("configuration")) {
      const config = JSON.parse(localStorage.getItem("configuration"));
      setHeader(config.data.current);
    }
    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      setUser(user);
    }
  }, []);

  const frm = (
    <form className="form-group">
      <input id="temporary" type="text" placeholder="Permanent Address" style={{ marginBottom: "30px" }} />
      <input id="address" type="text" placeholder="Temporary Address" />
    </form>
  )

  return (
    <AuthenticatedTenant auth={user} errors={props.errors} header={header}>
      {/* TODO: LINK COMING FROM AN API I GUESS */}
      <WorkPath workPathInfo={{
        base: ["Home", header?.siteUrl],
        medium: ["Servizi", header?.siteUrl + "servizi/"],
        end: "Iscrizioni"
      }} />

      <div className="container" id="main-container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10">
            <CompactInfo title={"Iscrizioni alla Scuaola dell'infanzia"} description={`
                Inserisci le informazioni necessarie per richiedere l’iscrizione
                alla Scuaola dell'infanzia. <br />
                Potrai ricontrollare tutti i dati nel riepilogo, prima di
                inviare la richiesta.`} />
          </div>

          <DynamicStepContent stepList={["INFORMATIVA SULLA PRIVACY", "DATI GENERALE", "RIEPILOGO"]} steps={[
            { component: Privacy, info: header?.description },
            { component: PersonaFisica, info: user },
            { component: frm }
          ]} />
        </div>
      </div>
    </AuthenticatedTenant>
  );
}
