import { api, apiAuth } from "./Configs/AxiosConfigs"
import { defineCancelApiObject } from "./Configs/AxiosUtils"

export const FastIdAPI = {

  getToken: async function (idp, cancel = false) {
    const response = await api.request({
      url: "/getToken/",
      method: "GET",
      signal: cancel ? cancelApiObject[this?.SPIDlogin?.name]?.handleRequestCancellation()?.signal : undefined,
    })
    return response.data;
  },
  getProfile: async function (token, cancel = false) {
    const response = await api.request({
      url: "/getProfile/"+token,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
      signal: cancel ? cancelApiObject[this?.SPIDlogin?.name]?.handleRequestCancellation()?.signal : undefined,
    })
    
    return response.data;
  },

}

// defining the cancel API object for SpidAPI
const cancelApiObject = defineCancelApiObject(FastIdAPI)