import React, { useState } from "react";
import "../global.css"
import { MessagePopup } from "./AddingForms/MessagePopup";

function MessasgeTable(props) {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const popupOpener = () => {
        setIsPopupOpen(true);
    }

    const close = () => {
        setIsPopupOpen(false);
    }

    return (
        <>
            {
                isPopupOpen &&
                <MessagePopup
                    close={close}
                    subTitle={props.subTitle}
                    message={props.message}
                    link={props.link}
                    linkName={props.linkName}
                    date={props.date}
                    title={props.title} />
            }
            {
                <div className="cmp-card-latest-messages mb-3" onClick={popupOpener}>
                    <div className="card shadow-sm px-4 pt-4 pb-4">
                        <span className="visually-hidden">
                            Categoria:
                        </span>
                        <div className="card-header border-0 p-0 m-0">
                            <p className="date-xsmall">{props.date}</p>
                        </div>
                        <div className="card-body p-0 my-2">
                            <h3 className="title-small-semi-bold t-primary m-0 mb-1">
                                <p className="text-decoration-none">{props.subTitle}</p>
                            </h3>
                            <p className="text-paragraph text-truncate">{props?.messageShort}</p>
                        </div>
                    </div>
                </div>
            }
        </>

    )
}


export default MessasgeTable;