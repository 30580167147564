import React, { useState } from "react";
import ReactDOM from "react-dom";

import "../../global.css";

import { checkValidity, createDocs } from "../../util/Dynamics";
import TransportUiForm from "../UIform/TransportUiForm";
import CafeteriaUiForm from "../UIform/CafeteriaUiForm";
import ImuUiForm from "../UIform/ImuUiForm";
import DisserviceUiForm from "../UIform/DisserviceUiForm";
import SoilUiForm from "../UIform/SoilUiForm,";
import VotiveUiForm from "../UIform/VotieUiForm";
import DeceasedUiForm from "../UIform/DeceasedUiForm";
import AccessUiForm from "../editForms/AccessUiForm";
import ParkingUIForm from "../UIform/ParkingUIForm";
import BonusForm from "../UIform/BonusForm";
import TowUiForm from "../UIform/TowUiForm";
import FeeUiForm from "../UIform/FeeUiForm";
import { useDispatch } from "react-redux";
import stepSlice from "../../context/StepsContext";
import MarriageUiForm from "../UIform/MarriageUiForm";
import FamilyMemberForm from "../UIform/FamilyMemberForm";


// Popup that gets created to add to that specific form box
function AddConsumerPopup(props) {
    const [error, setError] = useState(false);
    let user = localStorage.getItem("user") ? JSON.parse(JSON.parse(localStorage.getItem("user"))?.data) : {}
    const pref = Array.isArray(props?.prefData) && props?.prefData[0];
    const documents = createDocs(pref);
    const dispatcher = useDispatch();

    //Dynamically filterts the creation of
    //an object based on the given params
    const dynamicStepCreation = (docKey, registry = false, addresses = false, contacts = false, type, compiled = true) => {

        const generic = Array.isArray(props?.generic) ? props?.generic[0] : false;

        const defaultObj = {
            registry: {
                "nome": compiled ? pref?.anagrafica?.nome || user.name || "" : "",
                "cognome": compiled ? pref?.anagrafica?.cognome || user.familyName || "" : "",
                "codice_fiscale": compiled ? pref?.anagrafica?.codice_fiscale || user.fiscalNumber || "" : "",
                "data_di_nascita": compiled ? pref?.anagrafica?.data_di_nascita || user.dateOfBirth || "" : "",
                "luogo_di_nascita": compiled ? pref?.anagrafica?.luogo_di_nascita || user.placeOfBirth || "" : "",
                //"provincia_di_nascita": compiled ? pref?.anagrafica?.provincia_di_nascita || user.countyOfBirth || "" : "",
            },
            addresses: {
                "residenza": compiled ? pref?.indirizzi?.residenza || user.domicileStreetAddress || "" : "",
                "domicilio": compiled ? pref?.indirizzi?.domicilio || user.domicileMunicipality || "" : "",
            },
            contacts: {
                "telefono": compiled ? pref?.contatti?.telefono || generic?.contatti?.telefono || user.mobilePhone || "" : "",
                "email": compiled ? pref?.contatti?.email || generic?.contatti?.email || user.email || "" : "",
            }
        }

        const createdObj = {}

        if (registry)
            createdObj.anagrafica = { ...defaultObj.registry };
        if (addresses)
            createdObj.indirizzi = { ...defaultObj.addresses };
        if (contacts)
            createdObj.contatti = { ...defaultObj.contacts };
        if (type)
            createdObj.type = { type };
        if (docKey)
            createdObj.documenti = { ...documents.get(docKey) };

        return createdObj;
    }

    const dynamicBaseObjectCalculation = () => {
        let baseObj = {};
        let tmp = {};

        switch (props.service) {
            case 'RTS':
                if (props?.id === "Beneficiario") {

                    const type = props?.typeData ? props.typeData : "";

                    baseObj = dynamicStepCreation("RTSbeneficiary", true, true, false, type, false);
                    break;
                }

                baseObj = dynamicStepCreation("baseDoc", true, true, true, null, true);

                break;
            case 'RMS':
                if (props?.id === "Beneficiario") {
                    const type = props?.typeData ? props.typeData : "";
                    baseObj = dynamicStepCreation("RMSbeneficiary", true, true, false, type, false);

                    break;
                }

                baseObj = dynamicStepCreation("baseDoc", true, true, true, null, true);

                break;
            case 'PTI':
                if (props.typeData === "Persona giuridica") {
                    // console.log(props);
                    baseObj.anagrafica = {
                        "ragione_sociale": props?.generic?.[0]?.anagrafica?.demoninazione ?? "",
                        "codice_fiscale": props?.generic?.[0]?.anagrafica?.iva ?? ""
                    }

                    baseObj.type = { type: props.typeData };

                    tmp = {
                        ...baseObj,
                        ...dynamicStepCreation("", false, false, true, null, true)
                    }

                    baseObj = tmp;
                }
                else {
                    baseObj = dynamicStepCreation("", true, false, true, props.typeData, true);
                    // baseObj.anagrafica.provincia_di_nascita = user?.countyOfBirth;
                }
                break;
            case 'SSD':
                tmp = {};

                baseObj.anagrafica = {
                    "nome": pref?.anagrafica?.nome ? pref?.anagrafica?.nome : user.name,
                    "cognome": pref?.anagrafica?.cognome ? pref?.anagrafica?.cognome : user.familyName,
                    "codice_fiscale": pref?.anagrafica?.codice_fiscale ? pref?.anagrafica?.codice_fiscale : user.fiscalNumber,
                }

                tmp = {
                    ...baseObj,
                    ...dynamicStepCreation("", false, false, true, null, true)
                }
                baseObj = tmp;

                baseObj.selected = true;

                break;
            case 'PCL':
                baseObj = dynamicStepCreation("", true, true, true, null);

                break;
            case 'PCCO':
                baseObj = dynamicStepCreation("", true, true, true, null);

                break;
            case 'PCO':
                baseObj = dynamicStepCreation("", true, true, true, null);

                break;
            case 'PSP':
                if (props.typeData !== "Persona giuridica") {
                    baseObj = dynamicStepCreation("PSPbaseDoc", true, true, true, null, true);
                    baseObj.anagrafica.type = props.typeData;


                    baseObj.selected = true;
                } else {
                    const generic = Array.isArray(props?.generic) ? props?.generic[0] : false;
                    //ADD IN CASE
                    baseObj.anagrafica = {
                        "type": props.typeData,
                        "demoninazione": pref?.anagrafica?.demoninazione || generic?.anagrafica?.demoninazione || "",
                        "iva": pref?.anagrafica?.iva || generic?.anagrafica?.iva || user?.ivaCode,
                        "nome_legale": pref?.anagrafica?.nome_legale || generic?.anagrafica?.nome_legale || user?.name,
                        "cognome_legale": pref?.anagrafica?.cognome_legale || generic?.anagrafica?.cognome_legale || user?.familyName,
                        "codice_fiscale": pref?.anagrafica?.codice_fiscale || generic?.anagrafica?.codice_fiscale || user?.fiscalNumber,
                        "data_nascita_legale": pref?.anagrafica?.data_nascita_legale || generic?.anagrafica?.data_nascita_legale || user?.dateOfBirth,
                        "luogo_nascita_legale": pref?.anagrafica?.luogo_nascita_legale || generic?.anagrafica?.luogo_nascita_legale || user?.placeOfBirth,
                    };
                    baseObj.indirizzi = {
                        "addr_sede_legale": pref?.indirizzi?.addr_sede_legale || generic?.indirizzi?.addr_sede_legale || "",
                        "mun_sede_legale": pref?.indirizzi?.mun_sede_legale || generic?.indirizzi?.mun_sede_legale || "",
                        "addr_res_legale": pref?.indirizzi?.addr_res_legale || generic?.indirizzi?.addr_res_legale || "",
                        "mun_res_legale": pref?.indirizzi?.mun_res_legale || generic?.indirizzi?.mun_res_legale || ""
                    };

                    const tmp = {
                        ...baseObj,
                        ...dynamicStepCreation("PSPbaseDoc", false, false, true, null)
                    }

                    baseObj = tmp;

                    baseObj.selected = true;
                }
                break;
            case "RPM":
                if (props.id === "Richiedente 2")
                    baseObj = dynamicStepCreation("RPMbaseDoc", true, true, true, null, false);
                else
                    baseObj = dynamicStepCreation("RPMbaseDoc", true, true, true, null, true);

                baseObj.anagrafica.stato_civile = "";
                baseObj.anagrafica.con_chi = "";
                baseObj.anagrafica.comune_di_registrazione_atto = "";
                baseObj.anagrafica.data_di_registrazione_atto = "";

                break;
            case "RSD":
                if (props?.id === "Defunto") {

                    baseObj = dynamicStepCreation("RSDdoc", true, false, false, null, false, "deac");
                    const nonStandard = {
                        data_di_morte: pref?.anagrafica?.data_di_morte ?? "",
                        luogo_di_morte: pref?.anagrafica?.luogo_di_morte ?? "",
                        paternita: pref?.anagrafica?.paternita ?? "",
                    };

                    baseObj.anagrafica = { ...baseObj.anagrafica, ...nonStandard };
                    break;
                }

                baseObj = dynamicStepCreation("baseDoc", true, true, true, null);

                break;
            case "RAAT":
                baseObj = dynamicStepCreation("baseDoc", true, true, true, null);
                break;
            case 'PPI':
                if (props?.id === "Disabile") {

                    const type = props?.typeData ? props.typeData : "";

                    let tmp = {};

                    baseObj.documenti = {
                        "carta_di_identita": "",
                        "base64_carta_di_identita": "",
                        "fototessera": "",
                        "base64_fototessera": "",
                        "certificato_ufficio": "",
                        "base64_certificato_ufficio": "",
                        "certificato_medico": "",
                        "base64_certificato_medico": "",
                        "denuncia": "",
                        "base64_denuncia": ""
                    }

                    tmp = {
                        ...dynamicStepCreation("", true, true, true, type, true),
                        ...baseObj
                    }

                    baseObj = tmp;
                    baseObj.selected = true;

                    break;
                }



                let temp = {};

                baseObj.documenti = {
                    "carta_di_identita": pref?.documenti?.carta_di_identita,
                    "base64_carta_di_identita": pref?.documenti?.base64_carta_di_identita,
                    "attestazione_esercente": pref?.documenti?.attestazione_esercente,
                    "base64_attestazione_esercente": pref?.documenti?.base64_attestazione_esercente,
                }

                temp = {
                    ...dynamicStepCreation("", true, true, true, null),
                    ...baseObj,
                }

                baseObj = temp;

                const tmpTes = { tipo_richiedente: pref?.anagrafica?.tipo_richiedente, ...baseObj.anagrafica };

                baseObj.anagrafica = tmpTes;

                baseObj.selected = true;

                break;
            case "DBE":
                baseObj = dynamicStepCreation("baseDoc", true, true, true, null, true);

                break;
            case "DBE-family":
                baseObj = {
                    anagrafica: {
                        "nome": "",
                        "cognome": "",
                        "anni": "",
                        "data_di_nascita": "",
                        "grado_parentela": ""
                    }
                }

                break;
            case "PPC":
                baseObj = dynamicStepCreation("baseDoc", true, true, true, null, true);

                break;
            default:
                break;
        }
        return baseObj;
    }

    const [customerData, setCustomerData] = useState(dynamicBaseObjectCalculation());

    const save2 = () => {
        if (props.service === "RPM") {

            if (customerData.anagrafica.stato_civile === "Celibe/nubile" ||
                customerData.anagrafica.stato_civile === "Libero/a dal vincolo matrimoniale"
            ) {
                customerData.anagrafica.con_chi = " ";
                customerData.anagrafica.comune_di_registrazione_atto = " ";
                customerData.anagrafica.data_di_registrazione_atto = " ";
            }
        }

        if (checkValidity(customerData)) {
            if (props.service === "PSP" || props.service === "PTI")
                dispatcher(stepSlice.actions.alive());

            if (props.service === "PPI" && !customerData.anagrafica?.tipo_richiedente === "") {
                setError(true);
                return;
            }

        // console.log("checkValidity(customerData)", checkValidity(customerData), props.id);

            props.update({ id: props.id, data: [{ ...customerData }] });
            props.close();
        }
        else
            setError(true);
    }

    //Filters the shown form based on service the client is in
    const dynamicObjectCalculation = () => {
        switch (props.service) {
            case 'RTS':
                return (
                    <>
                        <TransportUiForm
                            close={props.close}
                            save={save2}
                            title={props?.id}
                            id={props.id}
                            error={error}
                            defaultValue={customerData}
                            setCustomerData={setCustomerData}
                            user={user}
                            prefData={pref}
                        />
                    </>
                )
            case 'RMS':
                return <CafeteriaUiForm
                    close={props.close}
                    save={save2}
                    title={props?.id}
                    id={props.id}
                    error={error}
                    defaultValue={customerData}
                    setCustomerData={setCustomerData}
                    user={user}
                    prefData={pref}

                />

            case 'PTI':
                return <ImuUiForm
                    close={props.close}
                    save={save2}
                    title={props?.id}
                    id={props.id}
                    error={error}
                    defaultValue={customerData}
                    setCustomerData={setCustomerData}
                    user={user}
                />
            case 'SSD':
                return <DisserviceUiForm
                    close={props.close}
                    save={save2}
                    title={props?.id}
                    id={props.id}
                    error={error}
                    defaultValue={customerData}
                    setCustomerData={setCustomerData}
                    user={user}
                    prefData={pref}
                />
            case 'PCL':
                return (
                    <>
                        <VotiveUiForm
                            close={props.close}
                            save={save2}
                            title={props?.id}
                            id={props.id}
                            error={error}
                            defaultValue={customerData}
                            setCustomerData={setCustomerData}
                            user={user}
                            prefData={pref}
                        />
                    </>
                )
            case 'PCO':
                return (
                    <>
                        <VotiveUiForm
                            close={props.close}
                            save={save2}
                            title={props?.id}
                            id={props.id}
                            error={error}
                            defaultValue={customerData}
                            setCustomerData={setCustomerData}
                            user={user}
                            prefData={pref}
                        />
                    </>
                )
            case 'PSP':
                return <SoilUiForm
                    close={props.close}
                    save={save2}
                    title={props?.id}
                    id={props.id}
                    error={error}
                    defaultValue={customerData}
                    setCustomerData={setCustomerData}
                    user={user}
                    typeData={props.typeData}
                    prefData={pref}
                />
            case 'RPM':
                return <MarriageUiForm
                    close={props.close}
                    save={save2}
                    title={props?.id}
                    id={props.id}
                    error={error}
                    defaultValue={customerData}
                    setCustomerData={setCustomerData}
                    user={user}
                    typeData={props.typeData}
                    prefData={pref}
                />
            case "RSD":
                return <DeceasedUiForm
                    close={props.close}
                    save={save2}
                    title={props?.id}
                    id={props.id}
                    error={error}
                    defaultValue={customerData}
                    setCustomerData={setCustomerData}
                    user={user}
                    prefData={pref}
                />

            case "RAAT":
                return (
                    <>
                        <AccessUiForm
                            close={props.close}
                            save={save2}
                            title={props?.id}
                            id={props.id}
                            error={error}
                            defaultValue={customerData}
                            setCustomerData={setCustomerData}
                            user={user}
                            prefData={pref}
                        />
                    </>
                )
            case "PPI":
                return (
                    <>
                        <ParkingUIForm
                            close={props.close}
                            save={save2}
                            title={props?.id}
                            id={props.id}
                            error={error}
                            defaultValue={customerData}
                            setCustomerData={setCustomerData}
                            user={user}
                            prefData={pref}
                        />
                    </>
                )
            case "DBE":
                return <BonusForm
                    close={props.close}
                    save={save2}
                    title={props?.id}
                    id={props.id}
                    error={error}
                    defaultValue={customerData}
                    setCustomerData={setCustomerData}
                    user={user}
                    prefData={pref}
                />
            case "DBE-family":
                return <FamilyMemberForm
                    close={props.close}
                    save={save2}
                    title={props?.title}
                    id={props.id}
                    error={error}
                    defaultValue={customerData}
                    setCustomerData={setCustomerData}
                    user={user}
                    prefData={pref}
                />
            case "PPC":
                return <TowUiForm
                    close={props.close}
                    save={save2}
                    title={props?.id}
                    id={props.id}
                    error={error}
                    defaultValue={customerData}
                    setCustomerData={setCustomerData}
                    user={user}
                    prefData={pref}
                />
            case "PCCO":
                return <FeeUiForm
                    close={props.close}
                    save={save2}
                    title={props?.id}
                    id={props.id}
                    error={error}
                    defaultValue={customerData}
                    setCustomerData={setCustomerData}
                    user={user}
                    prefData={pref}
                />
            default:
                break;
        }
    }

    return (
        <>
            {
                dynamicObjectCalculation()
            }
        </>
    )
}

const AddConsumerPopupModal = (props) => {
    return (
        <React.Fragment>
            {
                ReactDOM.createPortal(<AddConsumerPopup
                    close={props.close}
                    id={props.id}
                    title={props.title}
                    typeData={props.typeData}
                    service={props.service}
                    update={props.update}
                    prefData={props.prefData}
                    generic={props?.generic} />,
                    document.getElementById("overlay-root"))
            }
        </React.Fragment>
    )
}

export { AddConsumerPopupModal as AddConsumerPopup };