import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideList from "../../../UI-component/SideList";
import ErrorAlert from "../../../UI-component/ErrorAlert";
import CustomerFormBox from "../../../UI-component/registryForms/CustomerFormBox";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import stepSlice from "../../../context/StepsContext";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import { PaymentApi } from "../../../Apis/PaymentApi";
import CompletitionBoxMsg from "../../../UI-component/CompletitionBoxMsg";
import { uniqueIdBuilder } from "../../../util/Dynamics";

function General(props) {
  const dispatcher = useDispatch();
  const ctxApplicant = useSelector(state => state.stepSlice.data.richiedente);

  const [applicant, setApplicant] = useState(/*props.info.data?.richiedente ??*/ ctxApplicant);
  const [error, setError] = useState(true);

  const errorChecker = () => {
    if (!applicant || applicant?.length <= 0)
      setError(true);
    else
      setError(false);
  }

  useEffect(() => {
    errorChecker();
  }, [applicant])

  const update = (data) => {
    if (typeof data === "object") {
      if (data.removing) {
        setApplicant(undefined);
        return;
      }

      setApplicant(data.data);
    }

    if (Array.isArray(data)) {
      setApplicant([]);
    }
  }

  const onNext = () => {
    dispatcher(stepSlice.actions
      .dynamicSave({ id: "Richiedente", data: { ...applicant[0], selected: true } }));

    props.next();
  }

  return (
    <div className="row">
      <SideList
        infoList={[
          { element: "Effettuato da", id: "#report-author" },
        ]} />

      <div className="col-12 col-lg-8 offset-lg-1">
        <div className="steppers-content" aria-live="polite">
          <div className="it-page-sections-container">

            {
              <ErrorAlert errors={[{ name: "Effettuato da", id: "#isee-info" }]} err={error} />
            }

            <CustomerFormBox id="richiedente"
              cards={applicant?.length > 0 ? [applicant] : []}
              type={false}
              description="Informazione su di te"
              title="Effettuato da"
              service={props.info.code}
              update={update}
              prefData={props.info.data?.richiedente}
            />
          </div>
        </div>
        <NextBtnForm
          next={onNext}
          back={props.back}
          noSave={true}
          disabled={error} />
        {
          props.saved &&
          <SuccessfullPopup />
        }

        {
          props.fail &&
          <SuccessfullPopup isError={true} />
        }
      </div>
    </div>
  )
}

export default General;