import React, { useState } from "react";
import SummaryCard from "../../../../UI-component/summary/SummaryCard";
import FormBoxField from "../../../../UI-component/ui-el/FormBoxField";
import { titleExtractor, uniqueIdBuilder } from "../../../../util/Dynamics";
import NextBtnForm from "../../../../UI-component/NexBtnForm";
import { useDispatch, useSelector } from "react-redux";
import SuccessfullPopup from "../../../../UI-component/SuccessfullPopup";

export default function Summary(props) {
  const dispatcher = useDispatch();

  const data = useSelector(state => state.stepSlice.data.autore_della_segnalazione);
  const disserviceCtx = useSelector(state => state.stepSlice.data.disservizio);

  const [testData, setTestData] = useState(data);

  const update = (data) => {
    setTestData(data.data);
  }

  const onNext = () => {
    const data = {
      disservizio: { ...disserviceCtx },
      autore_della_segnalazione: testData
    }

    props.send(data, true);
  }


  return (
    <div className="row">
      <div className="col-12 col-lg-8 offset-lg-2">
        <div className="steppers-content" aria-live="polite">
          <div className="it-page-sections-container">
            {
              testData.map((itm, idx) => {
                if (itm?.selected) {
                  return (
                    <React.Fragment key={uniqueIdBuilder()}>
                      <h2>Dati generali</h2>
                      <br></br>
                      <SummaryCard
                        taxID={itm?.anagrafica.codice_fiscale}
                        mod={true}
                        cardTitle={`${itm?.anagrafica?.nome} ${itm?.anagrafica.cognome}`}
                        type="Autore della segnalazione"
                        service={props.info.code}
                        // key={uniqueIdBuilder()}
                        registryData={itm}
                        update={update}
                      >
                        {
                          Object.keys(itm).map((key, id) => {
                            return Object.keys(itm[key]).map(iKey => {
                              return <FormBoxField
                                title={iKey.includes("fiscale") ? "Codice Fiscale" : titleExtractor(iKey)}
                                value={itm[key][iKey] ? itm[key][iKey] : "Non Compilato"}
                                key={uniqueIdBuilder()} />
                            })
                          })
                        }
                      </SummaryCard>
                    </React.Fragment>
                  )
                }
              })
            }

            <h2>Segnalazione</h2>
            <br></br>
            <SummaryCard
              mod={false}
              cardTitle=""
              type="Disservizio">
              <FormBoxField
                title={"Indirizzo"}
                value={disserviceCtx?.cerca_un_luogo || "Non Compilato"} />
              <FormBoxField
                title={"Tipo di disservizio"}
                value={disserviceCtx?.tipo_di_disservizio || "Non Compilato"} />
              <FormBoxField
                title={"Titolo"}
                value={disserviceCtx?.titolo || "Non Compilato"} />
              <FormBoxField
                title={"Dettagli"}
                value={disserviceCtx?.dettagli || "Non Compilato"} />
              <FormBoxField
                title={"Allegato 1"}
                value={disserviceCtx?.immagine1 || "Non Compilato"} />
              <FormBoxField
                title={"Allegato 2"}
                value={disserviceCtx?.immagine2 || "Non Compilato"} />
              <FormBoxField
                title={"Allegato 3"}
                value={disserviceCtx?.immagine3 || "Non Compilato"} />
              <FormBoxField
                title={"Allegato 4"}
                value={disserviceCtx?.immagine4 || "Non Compilato"} />
              <FormBoxField
                title={"Allegato 5"}
                value={disserviceCtx?.immagine5 || "Non Compilato"} />
              <FormBoxField
                title={"Allegato 6"}
                value={disserviceCtx?.immagine6 || "Non Compilato"} />
              <FormBoxField
                title={"Allegato 7"}
                value={disserviceCtx?.immagine7 || "Non Compilato"} />
              <FormBoxField
                title={"Allegato 8"}
                value={disserviceCtx?.immagine8 || "Non Compilato"} />
              <FormBoxField
                title={"Allegato 9"}
                value={disserviceCtx?.immagine9 || "Non Compilato"} />
              <FormBoxField
                title={"Allegato 10"}
                value={disserviceCtx?.immagine10 || "Non Compilato"} />
            </SummaryCard>
          </div>
        </div>
        <NextBtnForm
          saveRequest={props.saveRequest}
          send={onNext}
          back={props.back}
          last={true}
          noSave={true} />

        {
          props.saved &&
          <SuccessfullPopup />
        }

        {
          props.fail &&
          <SuccessfullPopup isError={true} />
        }
      </div>
    </div>
  );
}




