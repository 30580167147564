import React from "react";
import { useSelector } from "react-redux";
import SummaryCard from "../../../UI-component/summary/SummaryCard";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import FormBoxField from "../../../UI-component/ui-el/FormBoxField";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import { useNavigate } from "react-router-dom";

function Summary(props) {
    const ctxApplicant = useSelector(state => state.stepSlice.data.richiedente[0]);
    const ctxInvoice = useSelector(state => state.stepSlice.data.fattura);

    const navigate = useNavigate();

    const onNext = async () => {
        const data = {
            richiedente: [ctxApplicant],
            fatture: { ...ctxInvoice },
        }

        const res = await props.send(data);

        navigate("/payment-pa", {
            state: {
                data: {
                    ...res.data,
                    title: ctxInvoice?.descrizione,
                    code: ctxInvoice?.code,
                    iuv: ctxInvoice.iuv,
                    email: ctxApplicant?.contatti?.email,
                    headTitle: props.info.headData.title,
                    headDesc: props.info.headData.description
                }
            }
        });
    }

    return (

        <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2">
                <div className="steppers-content" aria-live="polite">
                    <div className="it-page-sections-container">
                        <SummaryCard
                            type="Effettuato da"
                            cardTitle={`${ctxApplicant?.anagrafica?.nome} ${ctxApplicant?.anagrafica.cognome}`} >
                            <FormBoxField title="Nome" value={ctxApplicant?.anagrafica.nome} />
                            <FormBoxField title="Cognome" value={ctxApplicant?.anagrafica.cognome} />
                            <FormBoxField title="Codice Fiscale" value={ctxApplicant?.anagrafica.codice_fiscale} />
                            <FormBoxField title="Data di nascita" value={ctxApplicant?.anagrafica.data_di_nascita} />
                            <FormBoxField title="Luogo di nascita" value={ctxApplicant?.anagrafica.luogo_di_nascita} />
                            <FormBoxField title="Indirizzo residenza" value={ctxApplicant?.indirizzi.residenza} />
                            <FormBoxField title="Comune residenza" value={ctxApplicant?.indirizzi.domicilio} />
                            <FormBoxField title="Telefono" value={ctxApplicant?.contatti.telefono} />
                            <FormBoxField title="Email" value={ctxApplicant?.contatti.email} />
                        </SummaryCard>

                        <SummaryCard type="Fattura">
                            <FormBoxField title="Codice IUV" value={ctxInvoice?.iuv} />
                            <FormBoxField title="Data emissione" value={ctxInvoice?.data_emissione} />
                            <FormBoxField title="Data scadenza" value={ctxInvoice?.data_scadenza} />
                            <FormBoxField title="Ente" value={ctxInvoice?.ente} />
                            <FormBoxField title="Codice Fiscale ente" value={ctxInvoice?.codice_fiscale_ente} />
                            <FormBoxField title="Intestatario" value={ctxInvoice?.intestatario} />
                            <FormBoxField title="Codice Fiscale Intestatario" value={ctxInvoice?.codice_fiscale_intestatario} />
                            <FormBoxField title="Importo dovuto" value={ctxInvoice?.importo_dovuto} />
                            <FormBoxField title="Importo già pagato" value={ctxInvoice?.importo_gia_pagato} />
                        </SummaryCard>

                        <NextBtnForm
                            next={onNext}
                            back={props.back}
                            noSave={true}
                        />
                        {
                            props.saved &&
                            <SuccessfullPopup />
                        }

                        {
                            props.fail &&
                            <SuccessfullPopup isError={true} />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Summary;