import React, { useEffect, useState } from "react";
import PopupBox from "../PopupBox";
import PopupTextField from "../ui-el/PopupTextField";
import { useDispatch, useSelector } from "react-redux";
import stepSlice from "../../context/StepsContext";
import { checkValidity, onDirectDataChange, onFileSet2 } from "../../util/Dynamics";


function IseeEditPopup(props) {
    const dispatcher = useDispatch();
    // const iseeFormData = useSelector(state => state.stepSlice.data.isee);

    const [error, setError] = useState(false);

    const [iseeData, setIseeData] = useState({ ...props?.iseeData });

    // useEffect(() => {
    //     for(let i of iseeFormData) {
    //         setIseeData(i);
    //         break;
    //     }
    // },[])


    const save = () => {
        if (checkValidity(iseeData, true)) {
            props.update({
                id: "isee",
                data: [iseeData]
            })
            props.close();
        }
        else
            setError(true);
    }

    return (
        <PopupBox close={props.close} save={save} title="ISEE">
            {
                error &&
                <p style={{ color: "red", fontWeight: "bolder" }}>Completa tutti i campi</p>
            }

            <br></br>
            <PopupTextField
                defaultValue={iseeData?.valore_isee}
                onChange={(e) => { onDirectDataChange(e, setIseeData) }}
                id="valore_isee"
                fieldType="number"
                fieldTitle="Valore ISEE*"
                error={error} />
            <PopupTextField
                defaultValue={iseeData?.scadenza_isee}
                onChange={(e) => { onDirectDataChange(e, setIseeData) }}
                id="scadenza_isee"
                fieldType="date"
                fieldTitle="Scadenza ISEE*"
                error={error} />
            <PopupTextField
                defaultValue={iseeData?.anno_protocollo}
                onChange={(e) => { onDirectDataChange(e, setIseeData) }}
                id="anno_protocollo"
                fieldType="number"
                fieldTitle="Anno Protocollo*"
                error={error} />
            <PopupTextField
                defaultValue={iseeData?.numero_protocollo}
                onChange={(e) => { onDirectDataChange(e, setIseeData) }}
                id="numero_protocollo"
                fieldType="number"
                fieldTitle="Numero Protocollo*"
                error={error} />
            <PopupTextField
                defaultValue={iseeData?.codice_filiale_protocollo}
                onChange={(e) => { onDirectDataChange(e, setIseeData) }}
                id="codice_filiale_protocollo"
                fieldType="text"
                fieldTitle="Codice Filiale Protocollo*"
                error={error} />
            <PopupTextField
                onChange={(e) => { onFileSet2(e, setIseeData) }}
                defaultValue={iseeData.certificato_isee}
                id="certificato_isee"
                fieldType="file"
                fieldTitle="Certificato ISEE*"
                error={error} />
        </PopupBox>
    )
}

export default IseeEditPopup;