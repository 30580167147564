import React, { useState } from "react";
import ReactDOM from "react-dom";

import PopupTextField from "../ui-el/PopupTextField";
import PopupBox from "../PopupBox";
import "../../global.css";
import { checkValidity, onDirectDataChange, onFileSet2 } from "../../util/Dynamics";

// Popup that gets created to add to that specific form box
function IseeAddPopup(props) {
    const [error, setError] = useState(false);
    const placeholder = Array.isArray(props?.placeholderData) ? props.placeholderData[0] : [];

    const [iseeData, setIseeData] = useState({
        valore_isee: placeholder?.valore_isee || "",
        scadenza_isee: placeholder?.scadenza_isee || "",
        anno_protocollo: placeholder?.anno_protocollo || "",
        numero_protocollo: placeholder?.numero_protocollo || "",
        codice_filiale_protocollo: placeholder?.codice_filiale_protocollo || "",
        base64_certificato_isee: placeholder?.base64_certificato_isee || "",
        certificato_isee: placeholder?.certificato_isee || "",
        selected: true
    });

    const save = () => {
        // if(checkValidity(iseeData,true)) {
        //     dispatcher(stepSlice.actions.dynamicSave({id: "ISEE",data: iseeData}));
        // }
        // else
        //     setError(true);

        if (checkValidity(iseeData, true)) {
            props.update({
                id: "isee",
                data: [iseeData]
            });
            props.close();
        } else
            setError(true);


    }

    return (
        <>
            <PopupBox close={props.close} save={save} title={"ISEE"}>
                {
                    error &&
                    <p style={{ color: "red", fontWeight: "bolder" }}>Completa tutti i campi</p>
                }
                <PopupTextField
                    onChange={(e) => { onDirectDataChange(e, setIseeData) }}
                    fieldType="number"
                    id="valore_isee"
                    fieldTitle="Valore ISEE*"
                    error={error}
                    defaultValue={iseeData.valore_isee} />
                <PopupTextField
                    onChange={(e) => { onDirectDataChange(e, setIseeData) }}
                    fieldType="date"
                    id="scadenza_isee"
                    fieldTitle="Scadenza ISEE*"
                    error={error}
                    defaultValue={iseeData.scadenza_isee}
                />
                <PopupTextField
                    onChange={(e) => { onDirectDataChange(e, setIseeData) }}
                    fieldType="number"
                    id="anno_protocollo"
                    fieldTitle="Anno protocollo*"
                    error={error}
                    defaultValue={iseeData.anno_protocollo}
                />
                <PopupTextField
                    onChange={(e) => { onDirectDataChange(e, setIseeData) }}
                    fieldType="number"
                    id="numero_protocollo"
                    fieldTitle="Numero protocollo*"
                    error={error}
                    defaultValue={iseeData.numero_protocollo}
                />
                <PopupTextField
                    onChange={(e) => { onDirectDataChange(e, setIseeData) }}
                    fieldType="text"
                    id="codice_filiale_protocollo"
                    fieldTitle="Codice filiale protocollo*"
                    error={error}
                    defaultValue={iseeData.codice_filiale_protocollo} />
                <PopupTextField
                    onChange={(e) => { onFileSet2(e, setIseeData) }}
                    fieldType="file"
                    id={"certificato_isee"}
                    fieldTitle={"Certificato ISEE*"}
                    error={error}
                    defaultValue={iseeData.certificato_isee} />
            </PopupBox>
        </>
    )
}

const IseeAddPopupPortal = (props) => {
    return (
        <React.Fragment>
            {
                ReactDOM.createPortal(<IseeAddPopup
                    close={props.close}
                    id={props.id}
                    typeData={props.typeData}
                    service={props.service}
                    update={props.update}
                    placeholderData={props.placeholderData} />,
                    document.getElementById("overlay-root"))
            }
        </React.Fragment>
    )
}

export { IseeAddPopupPortal as IseeAddPopup };