import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import WorkPath from "../../../../UI-component/WorkPath";
import CompactInfo from "../../../../UI-component/CompactInfo";
import SideList from "../../../../UI-component/SideList";
import { ServiceRequestAPI } from "../../../../Apis/ServiceRequestAPI";
import Privacy from "../../../_Partials/Privacy";
import SummaryCard from "../../../../UI-component/summary/SummaryCard";
import PopupTextField from "../../../../UI-component/ui-el/PopupTextField";
import PopupSelectField from "../../../../UI-component/ui-el/PopupSelectField";
import SuccessfullBox from "../../../../UI-component/SuccessfullBox";
import { useDispatch, useSelector } from "react-redux";
import stepSlice from "../../../../context/StepsContext";
import { onDataChange2, onSelectData2 } from "../../../../util/Dynamics";
import ErrorAlert from "../../../../UI-component/ErrorAlert";
import SuccessfullPopup from "../../../../UI-component/SuccessfullPopup";
import Loader from "../../../../UI-component/Loader";
import Guest from "../../../../Layouts/GuestLayout";
import { PublicServiceRequestAPI } from "../../../../Apis/PublicServiceRequestAPI";
import { PublicCategoryAPI } from "../../../../Apis/PublicCategoryAPI";

export default function PublicRichiestaAssistenza(props) {
  const dispatcher = useDispatch();

  const ctx = useSelector(state => state.stepSlice.privacy);
  const [header, setHeader] = useState("");
  const [user, setUser] = useState("");
  const [category, setCategory] = useState([]);
  const [service, setService] = useState(null);
  const [activeStep, setActiveStep] = useState(1);
  const [_, setSelection] = useState("");
  const [loading, setLoading] = useState(false);
  const pathname = window.location.pathname.replace("ext-", "");
  const searchPath = window.location.search;

  const home = JSON.parse(localStorage.getItem("configuration")).data.current.siteUrl;

  const [formData, setFormData] = useState({
    richiedente: {
      nome: "",
      cognome: "",
      email: "",
      telefono: "",
      codice_fiscale: ""
    },
    richiesta: {
      servizio: "",
      categoria_di_servizio: "",
      dettaglio: ""
    }
  });

  const [error, setError] = useState({
    applicant: false,
    application: false
  });

  const [singleError, setSingleError] = useState({
    email: false,
    codice_fiscale: false,
    telefono: false,
    all: false
  });

  const [fail, setFail] = useState(false);
  const [saved, setSaved] = useState(false);

  const [successData, setSuccessData] = useState({
    title: "",
    message: ""
  });

  //Send the request
  const send = async () => {

    dispatcher(stepSlice.actions.dynamicSave({ id: "Richiesta", data: formData }));

    try {
      setLoading(true);
      const res = await PublicServiceRequestAPI.sendServiceRequest(
        {
          service_data: {
            service_id: "SRA",
            status: "Inviato",
            data: { data: formData }
          }
        },
        false
      );

      if (!res.success) {
        setFail(true);
        reset();
        return;
      }

      setActiveStep(prev => {
        if (prev > 0 && prev <= 2)
          return prev + 1;
      })

      setSuccessData(prev => {
        return { ...prev, title: res.data.title, message: res.data.message };
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setFail(true);
      reset();
    }
  }

  const reset = () => {
    setTimeout(() => {
      setFail(false);
      setSaved(false);
    }, 5000);
  }

  //Retrieves categories from api and it sets them up
  const retrieveServiceCategory = async () => {
    // const data = dataForCtegoryEservices.data;

    const data = await PublicCategoryAPI.getCategory();

    const categories = [];
    const services = new Map();

    data.data.forEach(itm => {
      categories.push(itm.title);
      const tmp = [];
      itm.services.forEach(el => {
        tmp.push(el.title);
      })
      services.set(itm.title, tmp);
    })

    return {
      categories,
      services
    }
  }

  const onChange = (e) => {
    setFormData(prev => {

      const obj = structuredClone(prev);
      obj.richiedente.codice_fiscale = e.target.value;

      return { ...obj }
    });
  }

  const errorChecker = () => {
    // const isError = {
    //   applicant: false,
    //   application: false
    // };

    // const reg = new RegExp(`[\\w\\D]+.[\\w\\D]+@[\\w\\D]{3,20}[.][a-zA-Z\\D]{2,10}`);

    // if (!reg.test(formData.richiedente.email))
    //   isError.applicant = true;

    // if (!formData.richiesta.servizio === ""
    //   || formData.richiesta.categoria_di_servizio === ""
    //   || formData.richiesta.dettaglio === "")
    //   isError.application = true;

    // setError(isError);




    let err = false;

    const isError = {
      applicant: false,
      application: false
    };

    const reg = new RegExp(`[\\w\\D]+.[\\w\\D]+@[\\w\\D]{3,20}[.][a-zA-Z\\D]{2,10}`);

    if (reg.test(formData.richiedente.email)) {
      setSingleError(prev => {
        return { ...prev, email: false }
      });
    }
    else {
      setSingleError(prev => {
        return { ...prev, email: formData.richiedente.email === "" ? false : true }
      });
      err = true;
    }

    const regCF = new RegExp("^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]{1}$");

    if (regCF.test(formData.richiedente.codice_fiscale)) {
      setSingleError(prev => {
        return { ...prev, codice_fiscale: false }
      });
    }
    else {
      setSingleError(prev => {
        return { ...prev, codice_fiscale: formData.richiedente.codice_fiscale === "" ? false : true }
      });
      err = true;
    }

    const regTel = new RegExp("[0-9]{8}");

    if (regTel.test(formData.richiedente.telefono)) {
      setSingleError(prev => {
        return { ...prev, telefono: false }
      });
    }
    else {
      setSingleError(prev => {
        return { ...prev, telefono: formData.richiedente.telefono === "" ? false : true }
      });
      err = true;
    }



    setSingleError((prev) => {
      return { ...prev, all: err }
    });

    isError.applicant = err;


    if (!formData.richiesta.servizio === ""
      || formData.richiesta.categoria_di_servizio === ""
      || formData.richiesta.dettaglio === "")
      isError.application = true;

    setError(isError);


  }

  const emailCheck = (e) => {
    setFormData(prev => {
      return { ...prev, richiedente: { ...prev.richiedente, email: e.target.value } }
    });
  }

  useEffect(() => {
    (async () => {

      //inizioalize first view first step
      if (localStorage.getItem("configuration")) {
        const config = JSON.parse(localStorage.getItem("configuration"));
        setHeader(config.data.current);
      }
      if (localStorage.getItem("user")) {
        const user = JSON.parse(localStorage.getItem("user"));
        setUser(user);
        // setApplicant(JSON.parse(user.data)); 
      }

      const { categories, services } = await retrieveServiceCategory();

      setCategory(categories);
      setService(services);
    })();

  }, []);

  useEffect(() => {
    if (localStorage.getItem("access_token"))
      window.location.href = pathname + searchPath;

    localStorage.setItem("destination", pathname);

    errorChecker();
  }, [formData])



  return (
    <Guest auth={user} errors={props.errors} header={header}>
      <main>
        <WorkPath
          workPathInfo={{
            base: ["Home", home],
            medium: ["Servizi", home + "servizi/"],
            category: "",
            serviceName: ["Richiesta assistenza", "/servizio/richiesta-assistenza/"],
            end: "Servizio digitale",
          }}
        />
        {
          loading &&
          <div style={{ position: "fixed", zIndex: "100", top: "50%", left: "50%", transform: "translate(-50%,-50%)" }}>
            <Loader loading={loading} />
          </div>
        }

        {
          activeStep === 1 &&
          <div className="container" id="main-container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-10">
                <CompactInfo
                  title={"Richiesta assistenza"}
                  description={`I campi contraddistinti dal simbolo asterisco sono obbligatori.`}
                />
              </div>
            </div>
          </div>
        }

        {
          activeStep === 2 ?
            <div id="second-step">
              <SuccessfullBox emailToSend={formData?.richiedente?.email} service="SRA" successData={successData} />
            </div> :
            <div id="first-step">
              <div className="container container-assistenza">
                <div className="row mt-lg-50">
                  <SideList
                    infoList={[
                      { element: "Richiedente", id: "#applicant" },
                      { element: "Richiesta", id: "#request" },
                    ]}
                  />

                  <div className="col-12 col-lg-8 offset-lg-1">
                    <Form
                      className="steppers-content"
                      aria-live="polite"
                      id="justValidateForm"
                      noValidate="novalidate"
                    >
                      {
                        <ErrorAlert errors={[{ name: "Richiedente", id: "#isee-info" }]} err={error.applicant} />
                      }

                      {
                        <ErrorAlert errors={[{ name: "Richiesta", id: "#isee-info" }]} err={error.application} />
                      }

                      <div className="it-page-sections-container">
                        <SummaryCard mod={false} type="Richiedente">
                          <PopupTextField
                            defaultValue={formData.richiedente.nome}
                            onChange={(e) => { onDataChange2(e, setFormData) }}
                            fieldType="text"
                            id="nome"
                            fieldTitle="Nome*"
                            description="Inserisci il nome" />

                          <PopupTextField
                            // disabled={true}
                            defaultValue={formData.richiedente.cognome}
                            onChange={(e) => { onDataChange2(e, setFormData) }}
                            fieldType="text"
                            id="cognome"
                            fieldTitle="Cognome*"
                            description="Inserisci il cognome" />

                          <PopupTextField
                            // disabled={true}
                            defaultValue={formData.richiedente.codice_fiscale}
                            onChange={onChange}
                            fieldType="text"
                            id="codice_fiscale"
                            fieldTitle="Codice Fiscale*"
                            description="Inserisci il codice fiscale"
                            straightErr={singleError.codice_fiscale} />

                          <PopupTextField
                            // disabled={true}
                            defaultValue={formData.richiedente.telefono}
                            onChange={(e) => { onDataChange2(e, setFormData) }}
                            fieldType="text"
                            id="telefono"
                            fieldTitle="Telefono*"
                            description="Inserisci il telefono"
                            straightErr={singleError.telefono} />

                          <PopupTextField
                            defaultValue={formData.richiedente.email}
                            onChange={emailCheck}
                            fieldType="text"
                            id="email"
                            fieldTitle="Email*"
                            description="Inserisci la mail"
                            straightErr={singleError.email} />
                        </SummaryCard>

                        <SummaryCard mod={false} type="Richiesta">
                          <PopupSelectField
                            defaultValue={formData.richiesta.categoria_di_servizio ? formData.richiesta.categoria_di_servizio : ""}
                            onChange={(e) => { onSelectData2(e, "richiesta", setFormData, setSelection) }}
                            id="categoria_di_servizio"
                            fieldTitle={"Categoria di servizio"}
                            options={category}
                            description="Seleziona la categoria del servizio per cui vuoi richiedere assistenza" />

                          <PopupSelectField defaultValue={formData.richiesta?.servizio ? formData.richiesta.servizio : ""}
                            onChange={(e) => { onSelectData2(e, "richiesta", setFormData, setSelection) }}
                            id="servizio"
                            fieldTitle={"Servizio"}
                            options={service?.get(formData.richiesta.categoria_di_servizio) ? service?.get(formData.richiesta.categoria_di_servizio) : []}
                            description="Seleziona il servizio per cui vuoi richiedere assistenza" />

                          <PopupTextField onChange={(e) => { onDataChange2(e, setFormData) }}
                            fieldType="text" fieldTitle="Dettagli*" id="dettaglio"
                            description="Inserire massimo 600 caratteri" />
                        </SummaryCard>

                        <Privacy
                          service={"assistenza"}
                          info={{
                            linkPrivacy: "",
                            description: `Il ${JSON.parse(localStorage.getItem("configuration")).data.current.description}
                            esercita compiti e funzioni e gestisce servizi come indicato negli artt. 13/14 del Testo Unico Enti Locali (D.lgs. 267/2000 e ss integrazioni).
                            In questo contesto procede al trattamento dei dati personali dell’interessato necessari per fornire ogni tipo 
                            di servizio nel rispetto della normativa vigente (codice protezione dati personali e Regolamento europeo 16/679).
                            Per una completa informazione si invita a prendere conoscenza del contenuto della informativa resa ex art. 13
                            Regolamento europeo.`}} />

                        <div>
                          {
                            saved &&
                            <SuccessfullPopup />
                          }

                          {
                            fail &&
                            <SuccessfullPopup isError={true} />
                          }
                        </div>
                      </div>
                      <button
                        type="button"
                        style={{ float: "right", marginBottom: "10px" }}
                        className={`btn btn-primary ${error.applicant || error.application || !ctx ? "disabled" : ""}`}
                        data-bs-toggle="modal"
                        data-bs-target="#modal-terms"
                        onClick={send}>
                        <span className="text-button-sm">Invia</span>

                      </button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
        }
      </main>
    </Guest>
  );
}