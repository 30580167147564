import React from "react";

function CompactInfo(props) {
    //test
    const innerHtml = props.description;

    //  TODO: UFFICIO SELEZIONATO SELEZIONE SE GIA DENTRO UN SERVIZIO
    const current = JSON.parse(localStorage.getItem("configuration")) ? JSON.parse(localStorage.getItem("configuration")).data.current : {};

    const newUrl = JSON.parse(localStorage.getItem("configuration")).data.newUrl;

    return (
        <div className="cmp-heading pb-3 pb-lg-4">
            <h1 className="title-xxxlarge">{props.title}</h1>
            <p className="subtitle-small" dangerouslySetInnerHTML={{ __html: innerHtml }} />
            <p className="subtitle-small mt-lg-2 mb-4">
                {
                    localStorage.getItem("access_token") ?

                        <>
                            Hai bisogno di assistenza?&nbsp;
                            {current?.linkContactUs || newUrl ? (
                                <a className="title-small-semi-bold t-primary" href={current?.linkContactUs || newUrl}>Contattaci</a>
                            ) : (
                                <a className="title-small-semi-bold t-primary" href="prenota-appuntamento">Contattaci</a>
                            )}
                        </>

                        :

                        <>
                            Hai un’identità digitale SPID?&nbsp;
                            <a className="title-small-semi-bold t-primary" href={`/login-spid`}>Accedi</a>
                        </>
                }

            </p>
        </div>
    )
}

export default CompactInfo