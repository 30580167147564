import { api } from "./Configs/AxiosConfigs"

export const DashboardApi = {

    getData: async function () {
        const response = await api.request({
          url: "/dashboard/",
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
          }
        })  
        return response.data;
      }
 
}
