

function FormBoxField(props) {
    return (
        <div className="single-line-info border-light">
            <div className="text-paragraph-small">{props.title}</div>
            <div className="border-light">
                <p className="data-text">{props.value}</p>
            </div>
        </div>
    )
}

export default FormBoxField;