import { useEffect, useState } from "react";

import AuthenticatedTenant from "../../Layouts/AuthenticatedLayoutTenant";
import Privacy from "../_Partials/Privacy";
import ServiziPagamentoDatiPersonali from "../_Partials/ServiziPagamentoDatiPersonali";
import ServiziPagamentoRiepilogo from "../_Partials/ServiziPagamentoRiepilogo";
import WorkPath from "../../UI-component/WorkPath";
import DynamicStepContent from "../../UI-component/DynamicStepContent";
import { ServiceDetailsAPI } from "../../Apis/ServiceDetailsAPI";

import { useDispatch } from "react-redux";
import stepSlice from "../../context/StepsContext";
import { useLocation } from "react-router-dom";
import SuoloServiziSpecifici from "../_Partials/SuoloServiziSpecifici";


export default function MensaScolastica(props) {
  const dispatcher = useDispatch();
  const [header, setHeader] = useState("");
  const [user, setUser] = useState("");
  const [data, setData] = useState({});
  const [testData, setTestData] = useState({});
  const [bread, setBread] = useState({});

  const location = useLocation();


  useEffect(() => {
    //inizioalize first view first step
    if (localStorage.getItem("configuration")) {
      const config = JSON.parse(localStorage.getItem("configuration"));
      setHeader(config.data.current);
      clearStore();
    }
    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      setUser(user);
      clearStore();
    }
    (async () => {
      try {
        const bread = await ServiceDetailsAPI.getBreadcrumbs("RMS");
        setBread(bread);
        await getData();
      } catch (err) {
        console.log(err);
      }
    })()
    if (location.state) {
      dispatcher(stepSlice.actions.onDraftData({ data: location.state.data, saved: false }));
    }

    if (!location.state) {
      (async () => {
        try {
          const saved = await ServiceDetailsAPI.getSaved("rms");

          if (!saved.success) {
            return;
          }
          setTestData(saved?.data);
          dispatcher(stepSlice.actions.onDraftData({ data: saved?.data, saved: false }));

        } catch (err) {
          console.log(err);
        }
      })()
    }
  }, []);


  //Clears the context session
  const clearStore = () => {
    dispatcher(stepSlice.actions.clear())
  }

  //Retrieves the data from the backend for the creation of the page
  const getData = async () => {
    try {
      const res = await ServiceDetailsAPI.getServiceDetails(props.code);

      if (!res.success) return;

      const data = res.data?.data;
      setData(data);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <AuthenticatedTenant auth={user} errors={props.errors} header={header}>

      <WorkPath workPathInfo={{
        base: ["Home", header?.siteUrl],
        medium: ["Servizi", header?.siteUrl + "servizi/"],
        category: [bread.category_name, bread.category_url],
        serviceName: [bread.service_name, bread.service_url],
        end: "Servizio digitale",
      }} />


      <div className="container" id="main-container">
        <div className="row justify-content-center">
          {
            data && Array.isArray(data) &&
            <DynamicStepContent
              service={props.code}
              successTitle={"Richiesta iscrizione alla mensa scolastica"}
              successDesc={`
                  Inserisci le informazioni necessarie per richiedere la mensa scolastica.<br>
                  Potrai ricontrollare tutti i dati nel riepilogo, prima di inviare la richiesta.`}
              stepList={["INFORMATIVA SULLA PRIVACY", "DATI GENERALI", "DATI SPECIFICI", "RIEPILOGO"]}
              steps={[
                {
                  component: Privacy, info: {
                    description: `Il ${JSON.parse(localStorage.getItem("configuration")).data.current.description}
                    esercita compiti e funzioni e gestisce servizi come indicato negli artt. 13/14 del Testo Unico Enti Locali (D.lgs. 267/2000 e ss integrazioni).
                    In questo contesto procede al trattamento dei dati personali dell’interessato necessari per fornire ogni tipo 
                    di servizio nel rispetto della normativa vigente (codice protezione dati personali e Regolamento europeo 16/679).
                    Per una completa informazione si invita a prendere conoscenza del contenuto della informativa resa ex art. 13
                    Regolamento europeo.`,
                    linkPrivacy: "",
                    service: "RMS"
                  }
                },
                { component: ServiziPagamentoDatiPersonali, info: data[1] ? { testData: testData, data: data[1], code: props.code } : {} },
                { component: SuoloServiziSpecifici, info: data[2] ? { service_name: "mensa_scolastica", data: data[2], code: props.code } : {} },
                { component: ServiziPagamentoRiepilogo, info: data[2] ? { service_name: "mensa_scolastica", data: data[2], optData: data[1], code: props.code } : {} },
              ]} />
          }
        </div>
      </div>
    </AuthenticatedTenant>
  );
}

