import React from "react";
import "../global.css"

function Loader(props) {
    const {fixedPosition, loading} = props;
    return (
        <>
            {loading && !fixedPosition && <div className="lds-dual-ring"></div>}
            {loading && fixedPosition &&
                <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-55%,-50%)", zIndex: "10000" }}>
                    <div className="lds-dual-ring"></div>
                </div>
            }
        </>
    )
}

export default Loader;