import { ServiceRequestAPI } from "../../../Apis/ServiceRequestAPI";

const formatDate = (date) => {
    if (("" + date).split("-")?.length === 3) {
        const [year, month, day] = date.split("-")
        return `${day}/${month}/${year}`;
    }
    return null
}

export const getCurrentDate = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('it-IT', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
    return formattedDate;
}

export const getServiceDays = async (serviceId) => {
    try {
        const service = await ServiceRequestAPI.getServiceById(serviceId);

        if (!service.success) {
            //TODO ERROR MESSAGE
        }

        // console.log("/////////// service", service?.data);

        if (service?.data) {
            const serviceData = service?.data[0];
            return { days: serviceData?.days, date: formatDate(serviceData?.date) }
        }
        return { days: null, date: null };
    } catch (err) {
        //TODO ERROR MESSAGE
        console.log(err);
    }
}

export const addDaysToDate = (date, days, isString) => {
    // console.log("formattedDate", date, days, isString)
    if (days !== "") {
        let formattedDate;
        if (!date) {
            const currentDate = new Date();
            formattedDate = currentDate.toLocaleDateString('it-IT', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            });
        }
        else
            formattedDate = date;
        const [day, month, year] = formattedDate.split('/');
        const initialDate = new Date(`${year}-${month}-${day}`);
        const daysToAdd = parseInt(days, 10);
        const newDate = new Date(initialDate);
        newDate.setDate(initialDate.getDate() + daysToAdd);

        if (isString)
            return `${newDate.getDate()}/${newDate.getMonth() + 1}/${newDate.getFullYear()}`;
        else
            return {
                year: newDate.getFullYear(),
                month: newDate.toLocaleString('it-IT', { month: 'short' }),
                day: newDate.getDate()
            }
    }
    return null;
}

export const getServiceExpireDate = async (serviceId, requestDate, isString) => {

    const { days, date } = await getServiceDays(serviceId);
    //se la data e precedente a oggi 
    // e ci sono anche i giorni aggiungi i giorni

    const options = {
        year: "numeric",
        month: "2-digit",
        day: "numeric"
    };


    if (date && days) {
        const splittedDate = date.split("/");
        const formattedDate = `${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`;
        const actualDate = new Date(formattedDate);

        const newDate = new Date(actualDate).toLocaleString("it-IT", options);

        if (actualDate > Date.now()) {
            return addDaysToDate(newDate, 0);
        }
        else {
            const updatedDate = addDaysToDate(requestDate, days);
            return updatedDate;
        }
    }
    else if (date) {
        const updatedDate = addDaysToDate(date, 0);
        return updatedDate;
    } else if (days) {
        const updatedDate = addDaysToDate(requestDate, days);
        return updatedDate;
    }

    return null;
}


