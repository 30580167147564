import React, { useState } from "react";
import PopupBox from "../PopupBox";
import PopupTextField from "../ui-el/PopupTextField";
import { useDispatch } from "react-redux";
import stepSlice from "../../context/StepsContext";
import { checkValidity, onDirectDataChange, onFileSet2, onSelectData2 } from "../../util/Dynamics";
import PopupSelectField from "../ui-el/PopupSelectField";


function CreditEditPopup(props) {
  const [_, setSelection] = useState(false);
  const [error, setError] = useState(false);

  const [creditData, setCreditData] = useState(props.registryData)

  const save = () => {
    if (checkValidity(creditData)) {
      // dispatcher(stepSlice.actions.dynamicEdit({id: "Coobbligato",data: cobData}));
      props.update({
        id: "credito",
        data: creditData
      });
      props.close();
    }
    else
      setError(true);
  }

  return (
    <PopupBox
      close={props.close}
      save={save}
      title="Dati per l'accredito"
      cobData={props.registryData}>
      <PopupTextField
        defaultValue={creditData?.intestatario ?? ""}
        id="intestatario"
        onChange={(e) => { onDirectDataChange(e, setCreditData) }}
        fieldType="text"
        fieldTitle="Intestatario*"
        cdescription="" />
      <PopupTextField
        defaultValue={creditData?.banca ?? ""}
        id="banca"
        onChange={(e) => { onDirectDataChange(e, setCreditData) }}
        fieldType="text"
        fieldTitle="Banca*"
        cdescription="" />
      <PopupTextField
        defaultValue={creditData?.filiale ?? ""}
        id="filiale"
        onChange={(e) => { onDirectDataChange(e, setCreditData) }}
        fieldType="text"
        fieldTitle="Filiale*"
        cdescription="" />
      <PopupTextField
        defaultValue={creditData?.indirizzo ?? ""}
        id="indirizzo"
        onChange={(e) => { onDirectDataChange(e, setCreditData) }}
        fieldType="text"
        fieldTitle="Indirizzo*"
        cdescription="" />
      <PopupTextField
        defaultValue={creditData?.codice_iban ?? ""}
        id="codice_iban"
        onChange={(e) => { onDirectDataChange(e, setCreditData) }}
        fieldType="text"
        fieldTitle="Codice IBAN*"
        cdescription="" />

      <PopupTextField
        onChange={(e) => { onFileSet2(e, setCreditData) }}
        fieldType="file"
        id={"iban"}
        defaultValue={creditData?.iban}
        fieldTitle={"Documento IBAN*"}
        error={props.error}
      />
      {/* <PopupTextField
        defaultValue={creditData?.iban ?? ""}
        id="iban"
        onChange={(e) => { onDirectDataChange(e, setCreditData) }}
        fieldType="text"
        fieldTitle="Documento IBAN*"
        cdescription="" /> */}

    </PopupBox>
  )
}

export default CreditEditPopup;