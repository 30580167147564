import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import stepSlice from "../../context/StepsContext";
import "../../global.css";

export default function Privacy(props) {
  const dispatcher = useDispatch();
  const [pvt, setPvt] = useState(false);
  const [apply, setApply] = useState(false);
  const [privacy, setPrivacy] = useState(false);


  //Updates the context pricavy 
  const privacyAgreement = (e) => {
    let id = "";
    if (e.target.id === "privacy") {
      setPrivacy(e.target.checked);
      id = 'privacy';
    } else if (e.target.id === "apply") {
      setApply(e.target.checked);
      id = "apply";
    }

    if (props.info?.service === "RMS" || props.info?.service === "RTS" || props.info?.service === "PPI") {
      dispatcher(stepSlice.actions.togglePvt(id === "privacy" ? e.target.checked && apply : e.target.checked && privacy));
      setPvt(id === "privacy" ? e.target.checked && apply : e.target.checked && privacy);
      return;
    } else {
      if (id === "privacy") {
        dispatcher(stepSlice.actions.togglePvt(e.target.checked));
        setPvt(e.target.checked);
      }
    }
  }

  //Updates the context whenever the (privacy) button is ticked
  useEffect(() => {
    dispatcher(stepSlice.actions.togglePvt(false));
  }, [dispatcher])

  useEffect(() => {
    dispatcher(stepSlice.actions.switchError({ error: false }))
  }, [])

  return (
    <div id="step1" className="container">
      <div className="row justify-content-center">
        <div className={`col-12 col-lg-8 pb-40 pb-lg-80 ${props.service === "assistenza" ? "mediaq" : ""}`}>
          <p className="text-paragraph mb-lg-4">{props.info?.description ? props.info?.description : ""}</p>
          {
            props.info?.linkPrivacy &&
            <p className={`text-paragraph mb-0`}>
              Per i dettagli sul trattamento dei dati personali consulta&nbsp;
              <a href={props.info?.linkPrivacy && props.info?.linkPrivacy}
                className="t-primary">{props.info?.title?.toLowerCase() ? props.info?.title?.toLowerCase() : ""}
                l'informativa sulla privacy
              </a>
            </p>

          }
          <div className="form-check mt-4 mb-3 mt-md-40 mb-lg-40">
            <div
            >
              <input
                type="checkbox"
                id="privacy"
                name="privacy-field"
                onClick={privacyAgreement}
              />
              <label
                className="title-small-semi-bold pt-1"
                htmlFor="privacy"
              >
                Ho letto e compreso l’informativa sulla privacy
              </label>
            </div>
            {
              (props.info?.service === "RMS" || props.info?.service === "RTS" || props.info?.service === "PPI") &&
              <>
                < input
                  type="checkbox"
                  id="apply"
                  name="apply-field"
                  onClick={privacyAgreement}
                />
                <label
                  className="title-small-semi-bold pt-1"
                  htmlFor="apply"
                >
                  Presto il consenso al trattamento dei dati di categoria particolare
                </label>
              </>
            }
          </div>
        </div>
      </div>
      {
        props.service !== "assistenza" &&
        <button
          id="step1Next"
          type="submit"
          className={`${pvt ? "" : "disabled"} ${`btn-right btn btn-primary btn-sm steppers-btn-confirm btn-next-step frw-btn frw-btn-non-summ`} `}
          data-bs-validate="validate"
          onClick={props.next}
        >

          <span className="text-button-sm">Avanti</span>
          <svg className="icon icon-white icon-sm" aria-hidden="true">
            <use href="#it-chevron-right"></use>
          </svg>
        </button>
      }
    </div>
  );
}
