import React, { useState, useEffect } from "react";
import { uniqueIdBuilder } from "../../util/Dynamics";

function PopupSelectField(props) {
    const [selectedValue, setSelectedValue] = useState(props.defaultValue || "");

    useEffect(() => {
        // Imposta il valore iniziale del select basato su defaultValue
        if (props.defaultValue) {
            setSelectedValue(props.defaultValue);
        }
    }, [props.defaultValue, props.options]);

    const handleChange = (e) => {
        // console.log("handle change enter here", e?.target?.value)
        setSelectedValue(e.target.value);
        if (props.onChange) {
            props.onChange(e);
        }

        if(props.onBlur) props.onBlur(e, e.target.value);
    }

    // console.log("///////// popup select field", props, selectedValue);

    const renderOptions = () => props?.options?.map((option, idx) => <option value={option} key={`options-${idx}-${option}`}>{option}</option>)
    return (
        <div className="select-wrapper mt-30 mb-40 bg-transparent p-0">
            <label htmlFor={props.id} className="" mb-3="">{props.fieldTitle}*</label>
            {
                props.multi ?
                    <select
                        value={selectedValue}
                        onBlur={props.onBlur}
                        onChange={handleChange}
                        disabled={props.disabled ? true : false}
                        multiple
                        id={props.id}
                        className={`bg-transparent long ${props.disabled ? "disabled" : ""}`}
                        required=""
                        style={{ cursor: "pointer" }}
                    >
                        <option value="" disabled>Seleziona opzione</option>
                        {
                            props.options.map((option) => (
                                <option value={option} key={uniqueIdBuilder()}>
                                    {option}
                                </option>
                            ))
                        }
                    </select> :
                    <select
                        value={selectedValue}
                        onBlur={props.onBlur}
                        onChange={handleChange}
                        id={props.id}
                        className={`bg-transparent ${props.disabled ? "disabled" : ""}`}
                        disabled={props.disabled ? true : false}
                        required=""
                        style={{ cursor: "pointer" }}
                    >
                        <option value="" disabled>{props.disabled ? (props?.disabledText || "Non richiesto per questa categoria e tipologia di immobile") : "Seleziona opzione"}</option>
                        {renderOptions()}
                    </select>
            }

            <div className="d-flex mb-30">
                <span className="form-text cmp-input__text">
                    {props.description ? props.description : "Inserire l’informazione per proseguire con la richiesta"}</span>
            </div>
        </div>
    )
}

export default PopupSelectField;
