import React from "react";
import PreferenceHeader from "./preferencesForms/PreferenceHeader";
import PreferenceToggle from "./preferencesForms/PreferenceToggle";
import PreferenceField from "./preferencesForms/PreferenceField";


function Balance(props) {
    return (    
        <div className="cmp-info-checkbox mt-4">
            <div className="card pt-25 pb-25 pl-25 pr-25 pt-lg-40 pb-lg-40 pl-lg-40 pr-lg-40">
            <PreferenceHeader title="Saldo"/>
            <div className="card-body">
                {/* TODO: needs to be dynamic*/}
                <p className="subtitle-smallmb-0">Data di scadenza: {props.expiryDate}</p>
                <br/>
                {/* <PreferenceToggle title="Ravvedimento"/> */}
                <PreferenceField title="Giorni dalla scadenza" value={props.timeLeft}/>
                {/* <PreferenceField title="Sanzione" value={props.total}/> */}
            </div>
            </div>
        </div> 
    )
}

export default Balance;