import React from 'react'
import '../../UI-component/SpidButton/index.css'
import Guest from '../../Layouts/GuestLayout'
import { SpidAPI } from '../../Apis/SpidAPI'
import { FastIdAPI } from '../../Apis/FastIdAPI'
import { useNavigate } from 'react-router-dom'

//For execute test SpidValidator
//  src="../assets/images/AgID.svg"
/**
 *
 */
const extraProviderList = {
  Validator: 'Validator'
  //ValidatorLocal: "ValidatorLocal"
}

export default function AccessoServizi(props) {
  //const navigate = useNavigate()

  //DEVELOPMENT ONLY DONT OPTIMIZE
  const getToken = async () => {
    try {
      FastIdAPI.getToken(false).then(res => {
        localStorage.getItem('destination') ?? localStorage.setItem('destination', '/');
        //let redirect = localStorage.getItem('destination') ?? "/";

        const data = res.data
        //const token = data.access_token
        const spidToken = data.token

        window.location.href = `${process.env?.REACT_APP_DOMAIN_FASTID}${spidToken}`;

        //localStorage.setItem('access_token', token);
        //sessionStorage.setItem("isLoggedIn", true);

        //navigate(redirect);

      })
    } catch (err) {
      console.log('error getToken', err)
    }
  }

  const autoLogin = async () => {
    localStorage.getItem('destination') ?? localStorage.setItem('destination', '/');

    let redirect = localStorage.getItem('destination') ?? "/";

    try {
      SpidAPI.autoLogin(false).then(res => {
        const data = res.data
        const token = data.access_token

        if (!token) {
          alert('Unable to login. Please try after some time.')
          return
        }



        localStorage.setItem('access_token', token);
        localStorage.setItem('user', JSON.stringify(data.user));
        localStorage.getItem('destination') ?? localStorage.setItem('destination', '/');
        sessionStorage.setItem("isLoggedIn", true);

        window.location.href = redirect;

      })
    } catch (err) {
      console.log('error autologin', err)
    }
  }

  //casuing equal key component error (just for testing purposes)
  const mappingProvider = () => {
    let ret = Object.entries(extraProviderList).map(function (_ref3) {
      const entityName = _ref3[0]
      const entityID = _ref3[1]
      const logo = '../assets/images/logo-agid-test.png'

      return {
        protocols: ['SAML'],
        entityName: entityName,
        entityID: entityID,
        logo: logo,
        active: true
      }
    })

    return ret
  }


  return (
    <Guest
      auth={props.auth}
      errors={props.errors}
      key={Math.random() * 31 * Math.random() * 11}
    >
      <div className='container' id='main-container'>
        <div className='row'>
          <div className='col-12 col-lg-10 offset-lg-1'>
            <div className='cmp-breadcrumbs' role='navigation'>
              <nav className='breadcrumb-container' aria-label='breadcrumb'>
                <ol className='breadcrumb p-0' data-element='breadcrumb'>
                  <li className='breadcrumb-item'>
                    <a href='/'>Home</a>
                    <span className='separator'>/</span>
                  </li>
                  <li className='breadcrumb-item active' aria-current='page'>
                    Accedi
                  </li>
                </ol>
              </nav>
            </div>{' '}
            <div className='cmp-heading pb-3 pb-lg-4'>
              <h1 className='title-xxxlarge'>Accedi</h1>

              <p className='subtitle-small'>
                Per accedere al sito e ai suoi servizi, utilizza una delle
                seguenti modalità.
              </p>
            </div>
          </div>
        </div>
        <hr className='d-none d-lg-block mt-0 mb-4' />
        <div className='row'>
          <div className='col-12 col-lg-8 offset-lg-2'>
            <div className='cmp-text-button mt-3'>
              <h2 className='title-xxlarge mb-0'>SPID</h2>
              <div className='text-wrapper'>
                <p style={{ padding: '10px' }} className='subtitle-small mb-3'>
                  SPID, il{' '}
                  <strong>Sistema Pubblico di Identità Digitale</strong>, è il
                  sistema di accesso che consente di utilizzare, con un'identità
                  digitale unica, i servizi online della Pubblica
                  Amministrazione e dei privati accreditati. Se sei già in
                  possesso di un'identità digitale, accedi con le credenziali
                  del tuo gestore. Se non hai ancora un'identità digitale,
                  richiedila ad uno dei gestori.
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  padding: '10px',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <div>
                  <a className='simple-link spid-link' href='https://www.spid.gov.it/'>
                    Maggiori informazioni su SPID{' '}
                    <span className='visually-hidden'>
                      Maggiori informazioni su SPID
                    </span>
                  </a>
                  <br></br>
                  <a
                    className='simple-link spid-link'
                    href='https://www.spid.gov.it/cos-e-spid/come-attivare-spid/'
                  >
                    Come attivare SPID{' '}
                    <span className='visually-hidden'>Come attivare SPID</span>
                  </a>
                  <br></br>
                  <a
                    className='simple-link spid-link'
                    href='https://test-www.spid.gov.it/ottieni-assistenza-dagli-identity-provider/'
                  >
                    Serve aiuto?{' '}
                    <span className='visually-hidden'>Serve aiuto?</span>
                  </a>
                </div>

                <div className='button-wrapper mb-2 spid-btn-div'>
                  <button onClick={getToken} className="spid-login-button ">
                    <img
                      src='../assets/images/spid-button-link-hover.png'
                      alt="Logo SPID"
                      className="spid-button-link-hover spid-btn"
                    />
                  </button>
                </div>

              </div>
              <div style={{ textAlign: 'center' }}>
                <img
                  src='../assets/images/spid-agid-logo-lb.png'
                  className='icon-error'
                  // onerror="this.src='../assets/images/loading.png'; this.onerror=null;"
                  alt='SPID Logo'
                  style={{
                    padding: '10px',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    width: '30vw',
                  }}
                />
              </div>
            </div>
            {
              process.env?.REACT_APP_AUTOLOGIN === "true" &&
              <div className='button-wrapper mb-2' align='right' >
                <button
                  id='step1Next'
                  type='submit'
                  className='btn btn-warning btn-sm steppers-btn-confirm btn-next-step'
                  onClick={autoLogin}
                >
                  <span className='text-button-sm'>TEST: Auto Login</span>
                  <svg className='icon icon-white icon-sm' aria-hidden='true'>
                    <use href='../assets/bootstrap-italia/dist/svg/sprites.svg#it-refresh'></use>
                  </svg>
                </button>
              </div>

            }
            {/* <div class='cmp-text-button'>
              <h2 class='title-xxlarge mb-0'>Altre utenze</h2>
              <div class='text-wrapper'>
                <p class='subtitle-small mb-3'>
                  In alternativa puoi utilizzare le seguenti modalità.
                </p>
              </div>
              <div class='button-wrapper d-md-flex'>
                <a
                  href='https://backoffice.stellanello.nemea.cloud/'
                  className='btn btn-outline-primary btn-re bg-white'
                >
                  <span>Accedi come dipendente</span>
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/** <HelpBox
        helpBoxInfo={{
          muniQuestionLink:
            'https://stellanello.nemea.cloud/domande-frequenti/',
          muniHelpLink: 'https://stellanello.nemea.cloud/servizi/assistenza/',
          greenNumber: 'tel:+39 0182 668000',
          bookLink: 'https://stellanello.nemea.cloud/servizi/prenotazioni/',
          rDisservice: ''
        }}
      /> 
     */}
    </Guest>
  )
}
