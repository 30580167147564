
import React from "react";
import PopupTextField from "../ui-el/PopupTextField";
import { onDataChange2, onFileSet2 } from "../../util/Dynamics";
import PopupBox from "../PopupBox";

function SoilUiForm(props) {
    let user = localStorage.getItem("user") ? JSON.parse(JSON.parse(localStorage.getItem("user"))?.data) : {};

    const prefData = props.prefData;

    // console.log(props.defaultValue?.documenti?.carta_di_identita, prefData?.documenti?.carta_di_identita);
    // console.log(user);

    const filter = () => {
        if (props.typeData === "Persona giuridica") {
            return (
                <>
                    <PopupTextField
                        defaultValue={props?.defaultValue?.anagrafica?.demoninazione || prefData?.anagrafica?.demoninazione}
                        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                        fieldType="text"
                        id="demoninazione"
                        fieldTitle="Denominazione o ragione sociale*"
                        description={"Inserire l’informazione per proseguire con la richiesta"}
                        error={props.error}
                    />
                    <PopupTextField
                        defaultValue={props?.defaultValue?.anagrafica?.iva || prefData?.anagrafica?.iva}
                        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                        fieldType="text"
                        id="iva"
                        fieldTitle="Partita IVA*"
                        description={"Inserire l’informazione per proseguire con la richiesta"}
                        error={props.error}
                    />
                    <PopupTextField
                        defaultValue={props?.defaultValue?.anagrafica?.nome_legale || prefData?.anagrafica?.nome_legale}
                        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                        fieldType="text"
                        id="nome_legale"
                        fieldTitle="Nome del legale rappresentante*"
                        description={"Inserire l’informazione per proseguire con la richiesta"}
                        error={props.error}
                    />
                    <PopupTextField
                        defaultValue={props?.defaultValue?.anagrafica?.cognome_legale || prefData?.anagrafica?.cognome_legale}
                        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                        fieldType="text"
                        id="cognome_legale"
                        fieldTitle="Cognome del legale rappresentante*"
                        description={"Inserire l’informazione per proseguire con la richiesta"}
                        error={props.error}
                    />
                    <PopupTextField
                        defaultValue={props?.defaultValue?.anagrafica?.codice_fiscale || prefData?.anagrafica?.codice_fiscale}
                        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                        fieldType="text"
                        id="codice_fiscale"
                        fieldTitle="Codice fiscale del legale rappresentante*"
                        description={"Inserire l’informazione per proseguire con la richiesta"}
                        regex="^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]{1}$"
                        error={props.error}
                    />
                    <PopupTextField
                        defaultValue={props?.defaultValue?.anagrafica?.data_nascita_legale || prefData?.anagrafica?.data_nascita_legale}
                        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                        fieldType="date"
                        id="data_nascita_legale"
                        fieldTitle="Data di nascita del legale rappresentante*"
                        description={"Inserire l’informazione per proseguire con la richiesta"}
                        error={props.error}
                    />
                    <PopupTextField
                        defaultValue={props?.defaultValue?.anagrafica?.luogo_nascita_legale || prefData?.anagrafica?.luogo_nascita_legale}
                        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                        fieldType="text"
                        id="luogo_nascita_legale"
                        fieldTitle="Luogo di nascita del legale rappresentante*"
                        description={"Inserire l’informazione per proseguire con la richiesta"}
                        error={props.error}
                    />
                    <PopupTextField
                        defaultValue={props?.defaultValue?.indirizzi?.addr_sede_legale || prefData?.indirizzi?.addr_sede_legale}
                        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                        fieldType="text"
                        id="addr_sede_legale"
                        fieldTitle="Indirizzo sede legale*"
                        error={props.error}
                    />
                    <PopupTextField
                        defaultValue={props?.defaultValue?.indirizzi?.mun_sede_legale || prefData?.indirizzi?.mun_sede_legale}
                        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                        fieldType="text"
                        id="mun_sede_legale"
                        fieldTitle="Comune sede legale*"
                        error={props.error}
                    />
                    <PopupTextField
                        defaultValue={props?.defaultValue?.indirizzi?.addr_res_legale || prefData?.indirizzi?.addr_res_legale}
                        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                        fieldType="text"
                        id="addr_res_legale"
                        fieldTitle="Indirizzo residenza del legale rappresentante*"
                        error={props.error}
                    />
                    <PopupTextField
                        defaultValue={props?.defaultValue?.indirizzi?.mun_res_legale || prefData?.indirizzi?.mun_res_legale}
                        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                        fieldType="text"
                        id="mun_res_legale"
                        fieldTitle="Comune residenza del legale rappresentante*"
                        error={props.error}
                    />
                </>

            )
        } else {
            return (
                <>
                    <PopupTextField
                        defaultValue={props?.defaultValue?.anagrafica?.nome || prefData?.anagrafica?.nome || user.name}
                        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                        fieldType="text"
                        id="nome"
                        fieldTitle="Nome*"
                        description={" "}
                        disabled
                        error={props.error}
                    />
                    <PopupTextField
                        defaultValue={props?.defaultValue?.anagrafica?.cognome || prefData?.anagrafica?.cognome || user.familyName}
                        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                        fieldType="text"
                        id="cognome"
                        fieldTitle="Cognome*"
                        description={" "}
                        disabled
                        error={props.error}
                    />
                    <PopupTextField
                        defaultValue={props?.defaultValue?.anagrafica?.codice_fiscale || prefData?.anagrafica?.codice_fiscale || user.fiscalNumber}
                        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                        fieldType="text"
                        id="codice_fiscale"
                        fieldTitle="Codice Fiscale*"
                        description={" "}
                        disabled
                        error={props.error}
                    />
                    <PopupTextField
                        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                        fieldType="date"
                        id="data_di_nascita"
                        fieldTitle="Data di nascita*"
                        defaultValue={props?.defaultValue?.anagrafica?.data_di_nascita || prefData?.anagrafica?.data_di_nascita || user.dateOfBirth}
                        error={props.error}
                        disabled={true}
                    />
                    <PopupTextField
                        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                        fieldType="text"
                        id="luogo_di_nascita"
                        fieldTitle="Luogo di nascita*"
                        defaultValue={props?.defaultValue?.anagrafica?.luogo_di_nascita || prefData?.anagrafica?.luogo_di_nascita || user.placeOfBirth}
                        error={props.error}
                        disabled={true}
                    />
                    <PopupTextField
                        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                        fieldType="text"
                        id="residenza"
                        fieldTitle="Indirizzo residenza*"
                        defaultValue={props?.defaultValue?.indirizzi?.residenza || prefData?.indirizzi?.residenza}
                        error={props.error}
                    />
                    <PopupTextField
                        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                        fieldType="text"
                        id="domicilio"
                        fieldTitle="Comune residenza*"
                        defaultValue={props?.defaultValue?.indirizzi?.domicilio || prefData?.indirizzi?.domicilio}
                        error={props.error}
                    />
                </>
            )
        }
    }
    // console.log(props);

    return (
        <PopupBox close={props.close} save={props.save} title={props?.id}>
            {
                props.error &&
                <p style={{ color: "red", fontWeight: "bolder" }}>Controlla i campi email o codice fiscale/Completa tutti i campi</p>
            }

            {filter()}

            <PopupTextField
                onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                fieldType="text"
                id="telefono"
                fieldTitle="Telefono*"
                defaultValue={props?.defaultValue?.contatti?.telefono || prefData?.contatti?.telefono}
                regex="[0-9]{8}"
                error={props.error}
            />
            <PopupTextField
                onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                fieldType="email"
                id="email"
                fieldTitle="Email*"
                defaultValue={props?.defaultValue?.contatti?.email || prefData?.contatti?.email}
                regex={`[\\w\\D]+.[\\w\\D]+@[\\w\\D]{3,20}[.][a-zA-Z\\D]{2,10}`}
                error={props.error}
            />

            <PopupTextField
                onChange={(e) => { onFileSet2(e, props.setCustomerData) }}
                fieldType="file"
                defaultValue={props.defaultValue?.documenti?.carta_di_identita || ""}
                id={"carta_di_identita"}
                fieldTitle={"Carta di identità*"}
                error={props.error}
            />

            <PopupTextField
                onChange={(e) => { onFileSet2(e, props.setCustomerData) }}
                fieldType="file"
                defaultValue={props.defaultValue?.documenti?.schema_planimetrico || ""}
                id={"schema_planimetrico"}
                fieldTitle={"Schema planimetrico"}
                description=" "
            />

            <PopupTextField
                onChange={(e) => { onFileSet2(e, props.setCustomerData) }}
                fieldType="file"
                defaultValue={props.defaultValue?.documenti?.pratica_edilizia || ""}
                id={"pratica_edilizia"}
                fieldTitle={"Pratica edilizia"}
                description=" "
            />


        </PopupBox>
    )
}

export default SoilUiForm;
