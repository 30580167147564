
import { useEffect, useState } from "react";

import AuthenticatedTenant from "../../Layouts/AuthenticatedLayoutTenant";
import Privacy from "../_Partials/Privacy";
import WorkPath from "../../UI-component/WorkPath";
import DynamicStepContent from "../../UI-component/DynamicStepContent";
import { ServiceDetailsAPI } from "../../Apis/ServiceDetailsAPI";
import { useDispatch } from "react-redux";
import stepSlice from "../../context/StepsContext";
import { useLocation } from "react-router-dom";
import General from "../_Partials/parking/General";
import Specific from "../_Partials/parking/Specific";
import Summary from "../_Partials/parking/Summary";

function PermessoParcheggio(props) {
  const dispatcher = useDispatch();
  const [header, setHeader] = useState("");
  const [user, setUser] = useState("");
  const [data, setData] = useState({});
  const [bread, setBread] = useState({});

  const [testData, setTestData] = useState({});

  const location = useLocation();

  useEffect(() => {
    //inizioalize first view first step
    if (localStorage.getItem("configuration")) {
      const config = JSON.parse(localStorage.getItem("configuration"));
      setHeader(config.data.current);
      clearStore();
    }
    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      setUser(user);
      clearStore();
    }


    (async () => {
      try {
        const bread = await ServiceDetailsAPI.getBreadcrumbs("PPI");
        setBread(bread);
        await getData();
      } catch (err) {
        console.log(err);
      }
    })()


    if (location.state) {
      dispatcher(stepSlice.actions.onDraftData({ data: location.state.data, saved: false }));
    }

    if (!location.state) {
      (async () => {
        try {
          // dispatcher(stepSlice.actions.onDraftData({data: JSON.parse(localStorage.getItem("user")).preference,saved: true}));

          const saved = await ServiceDetailsAPI.getSaved("ppi");

          if (!saved.success) {
            return;
          }

          setTestData(saved.data);
          dispatcher(stepSlice.actions.onDraftData({ data: saved, saved: true }));
        } catch (err) {
          console.log(err);
        }
      })()
    }
  }, []);

  //Clears the context session
  const clearStore = () => {
    dispatcher(stepSlice.actions.clear())
  }

  //Retrieves the data from the backend for the creation of the page
  const getData = async () => {
    try {
      const res = await ServiceDetailsAPI.getServiceDetails(props.code);

      if (!res.success) return;

      const data = res.data?.data;

      setData(data);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <AuthenticatedTenant auth={user} errors={props.errors} header={header}>
      <WorkPath workPathInfo={{
        base: ["Home", header?.siteUrl],
        medium: ["Servizi", header?.siteUrl + "servizi/"],
        category: [bread.category_name, bread.category_url],
        serviceName: [bread.service_name, bread.service_url],
        end: "Servizio digitale",
      }} />

      <div className="container" id="main-container">
        <div className="row justify-content-center">
          {
            <div className="container" id="main-container">
              <div className="row justify-content-center" >
                <DynamicStepContent service={props.code}
                  successTitle={"Richiesta permesso di parcheggio invalidi"}
                  successDesc={`
                  Inserisci le informazioni necessarie per richiedere il permesso di parcheggio invalidi.<br>
                  Potrai ricontrollare tutti i dati nel riepilogo, prima di inviare la richiesta.
                 `}
                  stepList={["INFORMATIVA SULLA PRIVACY", "DATI GENERALI", "DATI SPECIFICI", "RIEPILOGO"]} steps={[
                    {
                      component: Privacy, info: {
                        description: `Il ${JSON.parse(localStorage.getItem("configuration")).data.current.description}
                        esercita compiti e funzioni e gestisce servizi come indicato negli artt. 13/14 del Testo Unico Enti Locali (D.lgs. 267/2000 e ss integrazioni).
                        In questo contesto procede al trattamento dei dati personali dell’interessato necessari per fornire ogni tipo 
                        di servizio nel rispetto della normativa vigente (codice protezione dati personali e Regolamento europeo 16/679).
                        Per una completa informazione si invita a prendere conoscenza del contenuto della informativa resa ex art. 13
                        Regolamento europeo.`,
                        linkPrivacy: "",
                        service: "PPI"
                      }
                    },
                    { component: General, info: { data: testData, code: props.code } },
                    { component: Specific, info: { data: testData, code: props.code } },
                    { component: Summary, info: { data: testData, code: props.code } },
                  ]} />

              </div>
            </div>

          }
        </div>
      </div>
    </AuthenticatedTenant>
  );
}


export default PermessoParcheggio;