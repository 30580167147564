import React from "react";


function ReminderBox(props) {
    return (
        <>
            {
                props.isDoc ?
                    <div className="cmp-icon-card mb-3">
                    <div className="card pt-20 pb-4 ps-4 pr-30 drop-shadow">
                        <div className="cmp-card-title d-flex">
                        <svg
                            className="icon icon-sm me-2"
                            aria-hidden="true"
                        >
                            <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-files" />
                        </svg>
                        <h3 className="t-primary mb-2 title-small-semi-bold">
                            <a href={props.link}>
                            {props.serviceName}
                            </a>
                        </h3>
                        </div>
                        <div className="cmp-icon-card__description">
                        <p className="date-xsmall ml-30">
                            {props.date}
                        </p>
                        </div>
                    </div>
                    </div> :
                    <div className="cmp-card-latest-messages mb-3">
                        <div className="card shadow-sm px-4 pt-4 pb-4">
                            <span className="visually-hidden">
                                Categoria:
                            </span>
                            <div className="card-header border-0 p-0 m-0">
                                <p className="date-xsmall">{props.date}</p>
                            </div>
                            <div className="card-body p-0 my-2">
                                <h3 className="title-small-semi-bold t-primary m-0 mb-1">
                                <a href={props.link} className="text-decoration-none">{props.serviceName}</a>
                                </h3>
                                <p className="text-paragraph text-truncate">{props.description}</p>
                            </div>
                        </div>
                    </div>           
            }
        </>
       
    )
}


export default ReminderBox;