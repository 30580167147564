// progressive list that we see on the side of the service page
// creates a dynamic list with the given properties and ids
// to move to the specific element

function SideList(props) {
  return (
    <div className="col-12 col-lg-3 mb-4 d-none d-lg-block">
      <div
        className="cmp-navscroll sticky-top"
        aria-labelledby="accordion-title-one"
      >
        <nav
          className="navbar it-navscroll-wrapper navbar-expand-lg"
          aria-label={props.intitle || "INFORMAZIONI RICHIESTE"}
          data-bs-navscroll
        >
          <div className="navbar-custom" id="navbarNavProgress">
            <div className="menu-wrapper">
              <div className="link-list-wrapper">
                <div className="accordion">
                  <div className="accordion-item">
                    <span className="accordion-header" id="accordion-title-one">
                      <button
                        className="accordion-button pb-10 px-3"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse-one"
                        aria-expanded="true"
                        aria-controls="collapse-one"
                      >
                        {props.intitle || "INFORMAZIONI RICHIESTE"}
                        <svg className="icon icon-xs right">
                          <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-expand" />
                        </svg>
                      </button>
                    </span>
                    <div className="progress">
                      <div
                        className="progress-bar it-navscroll-progressbar"
                        role="progressbar"
                        aria-valuenow={0}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ width: "0%" }}
                      />
                    </div>

                    <div
                      id="collapse-one"
                      className="accordion-collapse collapse show"
                      role="region"
                      aria-labelledby="accordion-title-one"
                    >
                      <div className="accordion-body">
                        <ul className="link-list" data-element="page-index">
                          {
                            props.infoList.map((itm) => {
                              return (
                                <li className="nav-item" key={Math.random() * 31 * Math.random() * 11}>
                                  <p style={{color: "var(--primary)"}} href={itm.id}>
                                    <span className="title-medium">
                                      {itm.element}
                                    </span>
                                  </p>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default SideList;
