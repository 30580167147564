import React from "react";
import SideList from "../../UI-component/SideList";
import CustomerFormBox from "../../UI-component/registryForms/CustomerFormBox";
import IseeFormBox from "../../UI-component/registryForms/IseeFormBox";
import ErrorAlert from "../../UI-component/ErrorAlert";

export default function PersonaFisica(props) {
  return (
    <div className="row">
      <SideList
        infoList={[
          { element: "Richiedente", id: "#applicant-info" },
          { element: "Beneficiario", id: "#beneficiary-info" },
          { element: "Altro Genitore", id: "#other-parent-info" },
          { element: "ISEE", id: "#isee-info" },
        ]}
      />

      <div className="col-12 col-lg-8 offset-lg-1 mt-0">
        {/* error alert */}
        <ErrorAlert
          errors={[
            { name: "Altro genitore", id: "#other-parent-info" },
            { name: "ISEE", id: "#isee-info" },
          ]}
        />

        <div className="it-page-sections-container">
          {/* TODO: customer card created dynamially using api data */}
          <CustomerFormBox id="#applicant-info"
            cards={[{ name: "giulia rossi", taxID: "123" }]}
            description="Informazioni su di te"
            type={false}
            title="Richiedente"
          />
          <CustomerFormBox id="#beneficiary-info"
            cards={[
              { name: "giulia rossi", taxID: "123" },
              { name: "test", taxID: "123" },
            ]}
            description="Seleziona o aggiungi il minore per cui vuoi richiedere l’iscrizione"
            type={false}
            title="Beneficiario"
          />
          <CustomerFormBox id="other-parent-info"
            cards={[
              { name: "giulia rossi", taxID: "123" },
              { name: "test", taxID: "123" },
            ]}
            description="Informazioni sull'altro genitore"
            type={false}
            title="Altro Genitore"
          />
          <IseeFormBox id="isee-info"
            cards={[{ name: "ISEE: 18.000,00€", date: "01/10/2019" }]}
          />
        </div>

        {/* <SavingForm /> */}
      </div>
    </div>
  );
}
