import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import AuthenticatedTenant from "../../Layouts/AuthenticatedLayoutTenant";
import WorkPath from "../../UI-component/WorkPath";
import CompactInfo from "../../UI-component/CompactInfo";
import SideList from "../../UI-component/SideList";
import { ServiceRequestAPI } from "../../Apis/ServiceRequestAPI";
import Privacy from "../_Partials/Privacy";
import SummaryCard from "../../UI-component/summary/SummaryCard";
import PopupTextField from "../../UI-component/ui-el/PopupTextField";
import PopupSelectField from "../../UI-component/ui-el/PopupSelectField";
import SuccessfullBox from "../../UI-component/SuccessfullBox";
import { useDispatch, useSelector } from "react-redux";
import stepSlice from "../../context/StepsContext";
import { onDataChange2, onSelectData2 } from "../../util/Dynamics";
import ErrorAlert from "../../UI-component/ErrorAlert";
import SuccessfullPopup from "../../UI-component/SuccessfullPopup";
import { CategoryAPI } from "../../Apis/CategoryAPI";
import Loader from "../../UI-component/Loader";
import { useLocation } from "react-router-dom";
import { ServiceDetailsAPI } from "../../Apis/ServiceDetailsAPI";

export default function RichiestaAssistenza(props) {
  const dispatcher = useDispatch();

  const ctx = useSelector(state => state.stepSlice.privacy);

  const [header, setHeader] = useState("");
  const [user, setUser] = useState("");
  const [category, setCategory] = useState([]);
  const [service, setService] = useState(null);
  const [activeStep, setActiveStep] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [_, setSelection] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [on, setOn] = useState(false);
 


  let users = JSON.parse(JSON.parse(localStorage.getItem("user")).data);
  const home = JSON.parse(localStorage.getItem("configuration")).data.current.siteUrl;

  const [applicantError, setApplicantError] = useState({
    email: false,
    codice_fiscale: false,
    telefono: false
  })


  const [formData, setFormData] = useState({
    richiedente: {
      nome: location.state ? location.state.data.richiedente.nome : users.name,
      cognome: location.state ? location.state.data.richiedente.cognome : users.familyName,
      codice_fiscale: location.state ? location.state.data.richiedente.codice_fiscale : users.fiscalNumber,
      email: "",
      telefono: ""
    },
    richiesta: {
      servizio: location.state?.data?.richiesta?.servizio || "",
      categoria_di_servizio: location.state?.data?.richiesta?.categoria_di_servizio || "",
      dettaglio: location.state?.data?.richiesta?.dettaglio || ""
    }
  });
  const [bread, setBread] = useState({});

  const [error, setError] = useState({
    applicant: false,
    application: false
  });

  const [fail, setFail] = useState(false);
  const [saved, setSaved] = useState(false);

  const [successData, setSuccessData] = useState({
    title: "",
    message: ""
  });


  //Send the request
  const send = async () => {

    dispatcher(stepSlice.actions.dynamicSave({ id: "Richiesta", data: formData }));

    try {
      setLoading(true);
      const res = await ServiceRequestAPI.sendServiceRequest(
        {
          service_data: {
            service_id: "SRA",
            status: "Inviato",
            data: { data: formData }
          }
        },
        false
      );

      if (!res.success) {
        setFail(true);
        reset();
        return;
      }

      setActiveStep(prev => {
        if (prev > 0 && prev <= 2)
          return prev + 1;
      })

      setSuccessData(prev => {
        return { ...prev, title: res.data.title, message: res.data.message };
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setFail(true);
      reset();
    }
  }

  const reset = () => {
    setTimeout(() => {
      setFail(false);
      setSaved(false);
      setOn(false);
    }, 5000);
  }


  //Retrieves categories from api and it sets them up
  const retrieveServiceCategory = async () => {
    // const data = dataForCtegoryEservices.data;

    const data = await CategoryAPI.getCategory();

    const categories = [];
    const services = new Map();

    data.data.forEach(itm => {
      categories.push(itm.title);
      const tmp = [];
      itm.services.forEach(el => {
        tmp.push(el.title);
      })
      services.set(itm.title, tmp);
    })

    return {
      categories,
      services
    }
  }

  const errorChecker = () => {


    const reg1 = new RegExp(`[\\w\\D]+.[\\w\\D]+@[\\w\\D]{3,20}[.][a-zA-Z\\D]{2,10}`);

    if (reg1.test(formData.richiedente.email)) {
      setApplicantError(prev => {
        return { ...prev, email: false }
      });
    }
    else {
      setApplicantError(prev => {
        return { ...prev, email: formData.richiedente.email === "" ? false : true }
      });
    }

    const regCF = new RegExp("^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]{1}$");

    if (regCF.test(formData.richiedente.codice_fiscale)) {
      setApplicantError(prev => {
        return { ...prev, codice_fiscale: false }
      });
    }
    else {
      setApplicantError(prev => {
        return { ...prev, codice_fiscale: formData.richiedente.codice_fiscale === "" ? false : true }
      });
    }

    const regTel = new RegExp("[0-9]{8}");

    if (regTel.test(formData.richiedente.telefono)) {
      setApplicantError(prev => {
        return { ...prev, telefono: false }
      });
    }
    else {
      setApplicantError(prev => {
        return { ...prev, telefono: formData.richiedente.telefono === "" ? false : true }
      });
    }


    ////////////////////////
    const isError = {
      applicant: false,
      application: false
    };

    const reg = new RegExp(`[\\w\\D]+.[\\w\\D]+@[\\w\\D]{3,20}[.][a-zA-Z\\D]{2,10}`);

    if (!reg.test(formData.richiedente.email))
      isError.applicant = true;

    if (!formData.richiesta.servizio === ""
      || formData.richiesta.categoria_di_servizio === ""
      || formData.richiesta.dettaglio === "")
      isError.application = true;

    setError(isError);

  }

  const emailCheck = (e) => {
    if (e.target.id === "email") {
      setFormData(prev => {
        return { ...prev, richiedente: { ...prev.richiedente, email: e.target.value } }
      });
    } else if (e.target.id === "codice_fiscale") {
      setFormData(prev => {
        return { ...prev, richiedente: { ...prev.richiedente, codice_fiscale: e.target.value } }
      });
    } else if (e.target.id === "telefono") {
      setFormData(prev => {
        return { ...prev, richiedente: { ...prev.richiedente, telefono: e.target.value } }
      });
    }
  }

  useEffect(() => {

    (async () => {
      try {
        const bread = await ServiceDetailsAPI.getBreadcrumbs("SRA");
        setBread(bread);
      } catch (err) {
        console.log(err);
      }
    })()

    if (location.state) {
      dispatcher(stepSlice.actions.onDraftData({ data: location.state.data, saved: false }));
    }

    (async () => {

      (async () => {
        try {
          const saved = await ServiceDetailsAPI.getSaved("sra");


          const pref = Array.isArray(saved?.data?.richiedente) ? saved?.data?.richiedente[0] : false;


          setFormData(prev => {
            return {
              ...prev, richiedente: {
                nome: location.state ? location.state.data.richiedente.nome : users.name,
                cognome: location.state ? location.state.data.richiedente.cognome : users.familyName,
                codice_fiscale: location.state ? location.state.data.richiedente.codice_fiscale : users.fiscalNumber,
                email: location.state ? location.state.data.richiedente?.email : pref?.contatti?.email || users.email,
                telefono: location.state ? location.state.data?.richiedente?.telefono : pref?.contatti?.telefono || users.mobilePhone
              }
            }
          })
        } catch (err) {
          console.log(err);

          setFormData(prev => {
            return {
              ...prev, richiedente: {
                nome: location.state ? location.state.data.richiedente.nome : users.name,
                cognome: location.state ? location.state.data.richiedente.cognome : users.familyName,
                codice_fiscale: location.state ? location.state.data.richiedente.codice_fiscale : users.fiscalNumber,
                email: location.state ? location.state.data.richiedente?.email : users.email,
                telefono: location.state ? location.state.data?.richiedente?.telefono : users.mobilePhone
              }
            }
          })
        }
      })()

      //inizioalize first view first step
      if (localStorage.getItem("configuration")) {
        const config = JSON.parse(localStorage.getItem("configuration"));
        setHeader(config.data.current);
      }
      if (localStorage.getItem("user")) {
        const user = JSON.parse(localStorage.getItem("user"));
        setUser(user);
        // setApplicant(JSON.parse(user.data)); 
      }

      const { categories, services } = await retrieveServiceCategory();

      setCategory(categories);
      setService(services);
    })();

  }, [dispatcher, location.state, users.email, users.familyName, users.fiscalNumber, users.mobilePhone, users.name]);

  useEffect(() => {
    errorChecker();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData])


  const save = async () => {
    dispatcher(stepSlice.actions.dynamicSave({ id: "Richiesta", data: formData }));

    try {
      setLoading(true);
      const res = await ServiceRequestAPI.sendServiceRequest(
        {
          service_data: {
            service_id: "SRA",
            status: "Bozza",
            data: { data: formData }
          }
        },
        false
      );

      if (!res.success) {
        setFail(true);
        reset();
        return;
      }

      setLoading(false);
      setOn(true);
      reset();
    } catch (err) {
      setLoading(false);
      setFail(true);
      reset();
    }
  }



  return (
    <AuthenticatedTenant auth={user} errors={props.errors} header={header}>
      <main>
        <WorkPath
          workPathInfo={{
            base: ["Home", home],
            medium: ["Servizi", header?.siteUrl + "servizi/"],
            category: "",
            serviceName: [bread.service_name, bread.service_url],
            end: "Servizio digitale",
          }}
        />
        {
          loading &&
          <div style={{ position: "fixed", zIndex: "100", top: "50%", left: "50%", transform: "translate(-50%,-50%)" }}>
            <Loader loading={loading} />
          </div>
        }

        {
          activeStep === 1 &&
          <div className="container" id="main-container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-10">
                <CompactInfo
                  title={"Richiesta assistenza"}
                  description={`I campi contraddistinti dal simbolo asterisco sono obbligatori.`}
                />
              </div>
            </div>
          </div>
        }

        {
          activeStep === 2 ?
            <div id="second-step">
              <SuccessfullBox emailToSend={formData?.richiedente?.email} service="SRA" successData={successData} />
            </div> :
            <div id="first-step">
              <div className="container container-assistenza">
                <div className="row mt-lg-50">
                  <SideList
                    infoList={[
                      { element: "Richiedente", id: "#applicant" },
                      { element: "Richiesta", id: "#request" },
                    ]}
                  />

                  <div className="col-12 col-lg-8 offset-lg-1">
                    <Form
                      className="steppers-content"
                      aria-live="polite"
                      id="justValidateForm"
                      noValidate="novalidate"
                    >
                      {
                        <ErrorAlert errors={[{ name: "Richiedente", id: "#isee-info" }]} err={error.applicant} />
                      }

                      {
                        <ErrorAlert errors={[{ name: "Richiesta", id: "#isee-info" }]} err={error.application} />
                      }

                      <div className="it-page-sections-container">
                        <SummaryCard mod={false} type="Richiedente">
                          <PopupTextField
                            defaultValue={users.name}
                            onChange={(e) => { onDataChange2(e, setFormData) }}
                            fieldType="text"
                            id="nome"
                            fieldTitle="Nome*"
                            description="Inserisci il nome"
                            disabled={true} />

                          <PopupTextField
                            defaultValue={users.familyName}
                            onChange={(e) => { onDataChange2(e, setFormData) }}
                            fieldType="text"
                            id="cognome"
                            fieldTitle="Cognome*"
                            description="Inserisci il cognome"
                            disabled={true} />

                          <PopupTextField
                            defaultValue={formData.richiedente?.codice_fiscale}
                            onChange={emailCheck}
                            fieldType="text"
                            id="codice_fiscale"
                            fieldTitle="Codice Fiscale*"
                            description="Inserisci il codice fiscale"
                            straightErr={applicantError.codice_fiscale}
                            disabled={true} />

                          <PopupTextField
                            defaultValue={formData.richiedente?.telefono}
                            onChange={emailCheck}
                            fieldType="text"
                            id="telefono"
                            fieldTitle="Telefono*"
                            description="Inserisci il numero di telefono"
                            straightErr={applicantError.telefono} />

                          <PopupTextField
                            defaultValue={formData.richiedente?.email}
                            onChange={emailCheck}
                            fieldType="text"
                            id="email"
                            fieldTitle="Email*"
                            description="Inserisci la mail"
                            straightErr={applicantError.email} />

                        </SummaryCard>

                        <SummaryCard mod={false} type="Richiesta">
                        <PopupSelectField
                            defaultValue={formData.richiesta.categoria_di_servizio ? formData.richiesta.categoria_di_servizio : ""}
                            onChange={(e) => { onSelectData2(e, "richiesta", setFormData, setSelection) }}
                            id="categoria_di_servizio"
                            fieldTitle={"Categoria di servizio"}
                            options={category}
                            description="Seleziona la categoria del servizio per cui vuoi richiedere assistenza"
                          />

                          <PopupSelectField 
                            defaultValue={formData.richiesta?.servizio ? formData.richiesta.servizio : ""}
                            onChange={(e) => { onSelectData2(e, "richiesta", setFormData, setSelection) }}
                            id="servizio"
                            fieldTitle={"Servizio"}
                            options={service?.get(formData.richiesta.categoria_di_servizio) ? service?.get(formData.richiesta.categoria_di_servizio) : []}
                            description="Seleziona il servizio per cui vuoi richiedere assistenza" />

                          <PopupTextField onChange={(e) => { onDataChange2(e, setFormData) }}
                            fieldType="text" fieldTitle="Dettagli*" id="dettaglio"
                            defaultValue={formData.richiesta.dettaglio || ""}
                            description="Inserire massimo 600 caratteri" />
                        </SummaryCard>

                        <Privacy
                          service={"assistenza"}
                          info={{
                            linkPrivacy: "",
                            description: `Il ${JSON.parse(localStorage.getItem("configuration")).data.current.description}
                            esercita compiti e funzioni e gestisce servizi come indicato negli artt. 13/14 del Testo Unico Enti Locali (D.lgs. 267/2000 e ss integrazioni).
                            In questo contesto procede al trattamento dei dati personali dell’interessato necessari per fornire ogni tipo 
                            di servizio nel rispetto della normativa vigente (codice protezione dati personali e Regolamento europeo 16/679).
                            Per una completa informazione si invita a prendere conoscenza del contenuto della informativa resa ex art. 13
                            Regolamento europeo.`}} />

                        <div>
                          {
                            saved &&
                            <SuccessfullPopup />
                          }

                          {
                            fail &&
                            <SuccessfullPopup isError={true} />
                          }
                        </div>
                      </div>
                      <button
                        type="button"
                        style={{ float: "right", marginBottom: "10px" }}
                        className={`btn btn-primary ${error.applicant || error.application || !ctx ? "disabled" : ""}`}
                        data-bs-toggle="modal"
                        data-bs-target="#modal-terms"
                        onClick={send}>
                        <span className="text-button-sm">Invia</span>

                      </button>
                    </Form>
                    <button
                      type="button"
                      className="center-forcer"
                      style={{ marginBottom: "10px", border: "2px solid var(--primary)", padding: "7px 25px 7px 25px", color: "var(--primary)", borderRadius: "3px", fontWeight: "bold" }}
                      data-bs-toggle="modal"
                      data-bs-target="#modal-terms"
                      onClick={save}>
                      <span className="text-button-sm">Salva Richiesta</span>

                    </button>

                    {
                      fail &&
                      <SuccessfullPopup isError={true} />
                    }


                    {
                      !fail && on &&
                      <SuccessfullPopup />
                    }
                  </div>
                </div>
              </div>
            </div>
        }
      </main>
    </AuthenticatedTenant >
  );
}