import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import SideList from "../../../../UI-component/SideList";
import SelectionFormBox from "../../../../UI-component/SelectionFormBox";
import SummaryCard from "../../../../UI-component/summary/SummaryCard";
import FormBoxField from "../../../../UI-component/ui-el/FormBoxField";
import { PublicOfficeAPI } from "../../../../Apis/PublicOfficeAPI";
import ErrorAlert from "../../../../UI-component/ErrorAlert";
import stepSlice from "../../../../context/StepsContext";
import NextBtnForm from "../../../../UI-component/NexBtnForm";
import Loader from "../../../../UI-component/Loader";
import SuccessfullPopup from "../../../../UI-component/SuccessfullPopup";

function DateTime(props) {
  const dispatcher = useDispatch();
  const ctx = useSelector(state => state.stepSlice.data.ufficio);

  const [officeDetails, setOfficeDetails] = useState(ctx.ufficio);
  const [appointmentDetails, setAppointmentDetails] = useState(ctx?.appuntamenti_disponibili);
  const [appointmentChoice, setAppointmentChoice] = useState(ctx?.disponibilita);
  const [error, setError] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingDates, setLoadingDates] = useState(false);
  const [nonAppDates, setNonAppDates] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);
  const [availableAppointments, setAvailableAppointments] = useState({});

  const update = useCallback((data) => {
    if (data.id === "appuntamenti_disponibili") {
      setAppointmentDetails({
        id: data.selectionID,
        value: data.data
      });
    } else if (data.id === "disponibilita") {
      setAppointmentChoice({
        id: data.selectionID,
        value: data.data
      });
    }
  }, []);

  const onNext = useCallback(() => {
    dispatcher(stepSlice.actions.dynamicSave({ id: "Ufficio", internalId: "appuntamenti_disponibili", data: appointmentDetails }));
    dispatcher(stepSlice.actions.dynamicSave({ id: "Ufficio", internalId: "disponibilita", data: appointmentChoice }));
    props.next();
  }, [dispatcher, appointmentDetails, appointmentChoice, props]);

  const getOfficeDetails = useCallback(async () => {
    try {
      setLoading(true);
      const res = await PublicOfficeAPI.getOfficesById(ctx.ufficio.id);
      if (res.success) {
        setOfficeDetails(res.data);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [ctx.ufficio.id]);

  const getAppointmentDetails = useCallback(async (officeId, daysOfYear) => {
    try {
      setLoading(true);
      const res = await PublicOfficeAPI.getAppointments(officeId, daysOfYear.join('-'));
      if (res.success) {
        return res.data;
      }
      return {};
    } catch (err) {
      console.error(err);
      return {};
    } finally {
      setLoading(false);
    }
  }, []);

  const getNonAppDates = useCallback(async () => {
    try {
      const res = await PublicOfficeAPI.extDateNotAvailableChecker();
      setNonAppDates(res.data);
    } catch (err) {
      console.error(err);
    }
  }, []);

  const calculateDateWithAvailability = useCallback(async () => {
    setLoadingDates(true);
    const dates = [];
    const daysOfYear = [];
    const municipality = JSON.parse(localStorage.getItem("configuration"))
      ?.data?.current?.description.toLowerCase()
      .includes("nasino") || JSON.parse(localStorage.getItem("configuration"))
      ?.data?.current?.description.toLowerCase()
      .includes("brugnato");

    for (let i = 0; i < 14; i++) {
      const date = new Date();
      date.setDate(date.getDate() + (municipality ? 7 : 1) + i);
      const itm = date.toLocaleDateString("IT-it", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      if (itm.includes("domenica")) continue;

      const start = new Date(date.getFullYear(), 0, 0);
      const diff = date - start + (start.getTimezoneOffset() - date.getTimezoneOffset()) * 60 * 1000;
      const oneDay = 1000 * 60 * 60 * 24;
      const day = Math.floor(diff / oneDay);

      if (Array.isArray(nonAppDates) && !nonAppDates.includes(day)) {
        dates.push({ value: itm, id: day });
        daysOfYear.push(day);
      }
    }

    const appointments = await getAppointmentDetails(officeDetails.id, daysOfYear);
    setAvailableAppointments(appointments);
    
    setAvailableDates(dates.filter(date => 
      appointments[date.id] && 
      appointments[date.id].some(slot => slot.startDate !== "")
    ));

    setLoadingDates(false);
  }, [officeDetails.id, getAppointmentDetails, nonAppDates]);

  useEffect(() => {
    getOfficeDetails();
    getNonAppDates();
  }, [getOfficeDetails, getNonAppDates]);

  useEffect(() => {
    if (nonAppDates.length > 0 && officeDetails.id) {
      calculateDateWithAvailability();
    }
  }, [nonAppDates, officeDetails.id, calculateDateWithAvailability]);

  useEffect(() => {
    setError(!appointmentChoice?.id);
  }, [appointmentChoice]);

  const getAvailableSlots = useCallback((dayId) => {
    if (!availableAppointments[dayId]) return [];
    return availableAppointments[dayId]
      .filter(slot => slot.startDate !== "")
      .map((slot, index) => ({
        id: index,
        value: slot.startDate,
      }))
      .sort((a, b) => {
        // Convert time strings to comparable values (e.g., minutes since midnight)
        const timeToMinutes = (timeStr) => {
          const [hours, minutes] = timeStr.split(':').map(Number);
          return hours * 60 + minutes;
        };
        return timeToMinutes(a.value) - timeToMinutes(b.value);
      });
  }, [availableAppointments]);

  return (
    <React.Fragment>
      <Loader loading={loading} />
      <SideList
        infoList={[
          { element: "Appuntamenti disponibili", id: "#appuntamenti-disponibili" },
          { element: "Ufficio", id: "#office" },
        ]}
      />
      <div className="col-12 col-lg-8 offset-lg-1 section-wrapper">
        <section>
          <ErrorAlert
            errors={[{ name: "Appuntamenti disponibili", id: "#isee-info" }]}
            err={error}
          />

          <SelectionFormBox
            firstSelection={"Seleziona un giorno"}
            showTitle={"Appuntamenti disponibili*"}
            title="Appuntamenti Disponibili"
            description="Seleziona una data per vedere gli orari disponibili"
            selectTitle="Seleziona l'ufficio"
            choices={loadingDates ? [{ value: 'Caricamento date...', id: 'loading' }] : availableDates}
            defaultValue={appointmentDetails?.value ?? "Seleziona un'opzione"}
            oKey={"Ufficio"}
            objectify={true}
            update={update}
          >
            {appointmentDetails && getAvailableSlots(appointmentDetails.id).length > 0 ? (
              <SelectionFormBox
                disp={props.info?.disp}
                firstSelection={"Seleziona un orario"}
                showTitle={""}
                title="disponibilita"
                description=""
                selectTitle="Seleziona l'ufficio"
                choices={getAvailableSlots(appointmentDetails.id)}
                defaultValue={appointmentChoice?.value ?? "Seleziona un'opzione"}
                oKey={"Ufficio"}
                objectify={true}
                update={update}
              />
            ) : (
              <p style={{ fontSize: "16px" }}>
                {appointmentDetails ? "Nessun orario disponibile per la data selezionata" : "Seleziona prima una data"}
              </p>
            )}
          </SelectionFormBox>

          <SummaryCard mod={false} cardTitle={officeDetails.title} type="Ufficio">
            <FormBoxField
              value={officeDetails.address ?? "Non Compilato"}
              title={"Indirizzo"}
            />
            <FormBoxField
              value={officeDetails.timetables ?? "Non Compilato"}
              title={"Apertura"}
            />
          </SummaryCard>
        </section>

        <NextBtnForm
          saveRequest={props.saveRequest}
          next={onNext}
          back={props.back}
          noSave={true}
          disabled={error}
        />
        {props.saved && <SuccessfullPopup />}
        {props.fail && <SuccessfullPopup isError={true} />}
      </div>
    </React.Fragment>
  );
}

export default DateTime;