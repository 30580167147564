import React, { useState } from "react";
import FormBoxField from "../ui-el/FormBoxField";
import PropertyEditPopup from "../editForms/PropertyEditPopup";
import { titleExtractor, uniqueIdBuilder } from "../../util/Dynamics";


function PropertyRegistry(props) {
    const [show, setShow] = useState(false);
    const [edit, setEdit] = useState(false);

    const showRegistry = () => {
        setShow(prev => !prev);
    }

    const editData = () => {
        setEdit(prev => !prev);
    }

    const close = () => {
        setEdit(false);
    }

    return (
        <div className="accordion-item">
            <div className="accordion-header" id="heading-collapse-benef-1">
                <button className="collapsed accordion-button" type="button" onClick={showRegistry}>
                    <span className="d-flex align-items-center">Mostra tutto
                        <svg className="icon icon-primary icon-sm">
                            <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-expand" />
                        </svg>
                    </span>
                </button>
                {
                    show &&
                    <>
                        <div className="accordion-collapse">
                            <div className="accordion-body p-0">
                                <div className="cmp-info-summary bg-white has-border">
                                    <div className="card">
                                        <div className="card-header border-bottom border-light p-0 mb-0 d-flex justify-content-between d-flex justify-content-end">
                                            <h4 className="title-large-semi-bold mb-3">Immobili</h4>
                                            <p onClick={editData} className="text-decoration-none" style={{ cursor: "pointer" }}>
                                                <span className="text-button-sm-semi t-primary">Modifica</span></p>
                                        </div>
                                        <div className="card-body p-0">
                                            {
                                                Object.keys(props.card).map(key => {
                                                    if (key !== "id") {
                                                        return Object.keys(props.card[key]).map((iKey, idx) => {
                                                            // if (iKey.includes("anno"))
                                                            //     return <></>;
                                                            if (!key.includes("pertinenza") && props.card[key][iKey]) {
                                                                const diffTitles = [
                                                                    ["tipologia_immobile", 'categoria_tipologia_immobile'],
                                                                    ["rendita_catastale", 'categoria_catastale'],
                                                                    ["numero_conviventi", 'altri_proprietari_conviventi'],
                                                                    ["mese_inizio", 'mese_di_inizio'],
                                                                    ["mese_fine", 'mese_di_fine'],
                                                                    ["immobile_atorico_inagibile", 'immobile_storico_o_inagibile'],
                                                                    ["numero_conviventi", 'altri_proprietari_conviventi'],
                                                                    ["percentuale_di_possesso", 'percentuale_possesso']
                                                                ];
                                                                let title = iKey;

                                                                for (let i of diffTitles) {
                                                                    if (title === i[0]) {
                                                                        title = i[1];
                                                                        break;
                                                                    }
                                                                }

                                                                return <FormBoxField
                                                                    title={titleExtractor(title)}
                                                                    value={props.card[key][iKey] ? props.card[key][iKey] : "Non Applicato"}
                                                                    key={uniqueIdBuilder()} />
                                                            } else if (key.includes("pertinenza") && props.card[key][iKey] !== "") {
                                                                const imId = props.card["dati_immobile"]["tipologia_immobile"].split("-")[0].trim();
                                                                if (imId === "0" || imId === "1" || imId === "24" || imId === "25") {

                                                                    return <div key={uniqueIdBuilder()}>
                                                                        {
                                                                            idx === 0 &&
                                                                            <div key={uniqueIdBuilder()}>
                                                                                <h6 style={{ color: "black", padding: "20px", fontSize: "25px", borderBottom: "solid 1px lightgrey", textAlign: "left" }}>{titleExtractor(key)}</h6>
                                                                            </div>
                                                                        }
                                                                        <FormBoxField
                                                                            title={titleExtractor(iKey)}
                                                                            value={props.card[key][iKey] ? props.card[key][iKey] : "Non Applicato"}
                                                                            key={uniqueIdBuilder()}
                                                                        />
                                                                    </div>
                                                                }
                                                            }
                                                        })
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }

                {
                    edit &&
                    <PropertyEditPopup
                        year={props.year}
                        close={close}
                        registryData={props.card}
                        update={props.update} />
                }

            </div>
        </div>

    )
}

export default PropertyRegistry;