import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideList from "../../../UI-component/SideList";
import ErrorAlert from "../../../UI-component/ErrorAlert";
import CustomerFormBox from "../../../UI-component/registryForms/CustomerFormBox";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import stepSlice from "../../../context/StepsContext";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import Loader from "../../../UI-component/Loader";


function General(props) {
  const dispatcher = useDispatch();
  const ctxApplicant = useSelector(state => state.stepSlice.data.richiedente);
  const isStopped = useSelector(state => state.stepSlice.data.stopped);
  const ctxApplicant2 = useSelector(state => state.stepSlice.data.richiedente_2);


  const [applicant, setApplicant] = useState(/*props.info.data?.richiedente ??*/ ctxApplicant);
  const [applicant_2, setApplicant2] = useState(/*props.info.data?.richiedente ??*/ ctxApplicant2);
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState({
    applicant1: false,
    applicant2: false
  });

  const errorChecker = () => {
    if (!applicant || applicant?.length <= 0) {
      setError(prev => {
        return { ...prev, applicant1: true }
      });
    } else {
      setError(prev => {
        return { ...prev, applicant1: false }
      });
    }

    if (!applicant_2 || applicant_2?.length <= 0) {
      setError(prev => {
        return { ...prev, applicant2: true }
      });
    } else {
      setError(prev => {
        return { ...prev, applicant2: false }
      });
    }
  }

  useEffect(() => {
    errorChecker();
  }, [applicant, applicant_2])

  const update = (data) => {
    // console.log("update", data, applicant_2);
    if (data.id.toLowerCase() === "richiedente 2") {
      if (typeof data === "object") {
        if (data.removing) {
          setApplicant2(undefined);
          return;
        }

        setApplicant2(data.data);
      }

      if (Array.isArray(data)) {
        setApplicant2([]);
      }
    } else {
      if (typeof data === "object") {
        if (data.removing) {
          setApplicant(undefined);
          return;
        }

        setApplicant(data.data);
      }

      if (Array.isArray(data)) {
        setApplicant([]);
      }

    }

  }

  const onNext = () => {
    dispatcher(stepSlice.actions
      .dynamicSave({ id: "Richiedente", data: { ...applicant[0], selected: true } }));
    dispatcher(stepSlice.actions
      .dynamicSave({ id: "Richiedente_2", data: { ...applicant_2[0], selected: true } }));


    props.next();
  }

  const save = async () => {
    // console.log("enter hereee");
    const data = {
      richiedente: applicant?.length > 0 ? [{ ...applicant[0], selected: true }] : [],
      richiedente_2: applicant_2?.length > 0 ? [{ ...applicant_2[0], selected: true }] : []
    }

    setLoading(true);

    console.log(data);

    await props.save(data);

    dispatcher(stepSlice.actions
      .dynamicSave({ id: "Richiedente", data: { ...applicant[0], selected: true } }));
    dispatcher(stepSlice.actions
      .dynamicSave({ id: "Richiedente_2", data: { ...applicant_2[0], selected: true } }));

    setLoading(false);
  }

  return (
    <div className="row">
      <SideList
        infoList={[
          { element: "Richiedente 1", id: "#report-author" },
          { element: "Richiedente 2", id: "#report-author" },

        ]} />

      <div className="col-12 col-lg-8 offset-lg-1">
        <div className="steppers-content" aria-live="polite">
          <div className="it-page-sections-container">

            {
              <ErrorAlert errors={[{ name: "Richiedente 1", id: "#isee-info" }]} err={error.applicant1 || isStopped} />
            }
            {
              <ErrorAlert errors={[{ name: "Richiedente 2", id: "#isee-info" }]} err={error.applicant2 || isStopped} />
            }

            <CustomerFormBox id="richiedente"
              cards={applicant?.length > 0 ? [applicant] : []}
              type={false}
              description="Informazione su di te"
              title="Richiedente 1"
              service={props.info.code}
              update={update}
              prefData={props.info.data?.richiedente}
            />


            <CustomerFormBox id="richiedente 2"
              cards={applicant_2?.length > 0 ? [applicant_2] : []}
              type={false}
              description="Informazione sulla persona con cui ti sposerai"
              title="Richiedente 2"
              service={props.info.code}
              update={update}
              prefData={props.info.data?.richiedente_2}
            />
          </div>
        </div>
        <div style={{ transform: "translateY(10%)" }}>
          <Loader loading={loading} />
        </div>
        <NextBtnForm
          next={onNext}
          back={props.back}
          noSave={false}
          saveRequest={save}
          disabled={error.applicant1 || error.applicant2 || isStopped} />
        {
          props.saved &&
          <SuccessfullPopup />
        }

        {
          props.fail &&
          <SuccessfullPopup isError={true} />
        }
      </div>
    </div>
  )
}

export default General;