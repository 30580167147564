import React from "react";
import PopupTextField from "../ui-el/PopupTextField";
import { onDataChange2, onFileSet2 } from "../../util/Dynamics";
import PopupBox from "../PopupBox";


function CafeteriaUiForm(props) {
    const isBeneficiary = props.id === "Beneficiario";
    let user = localStorage.getItem("user") ? JSON.parse(JSON.parse(localStorage.getItem("user"))?.data) : {};

    const prefData = props.pref;

    return (
        <PopupBox close={props.close} save={props.save} title={props?.id}>
            {
                props.error &&
                <p style={{ color: "red", fontWeight: "bolder" }}>Controlla i campi email o codice fiscale/Completa tutti i campi</p>
            }

            <PopupTextField
                disabled={!isBeneficiary}
                defaultValue={(!isBeneficiary || props?.isEdit) ? props?.defaultValue?.anagrafica?.nome || prefData?.anagrafica?.nome : prefData?.anagrafica?.nome || ""}
                onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                fieldType="text"
                id="nome"
                fieldTitle="Nome*"
                description={isBeneficiary ? "" : " "}
                error={props.error}
            />
            <PopupTextField
                defaultValue={(!isBeneficiary || props?.isEdit) ? props?.defaultValue?.anagrafica?.cognome || prefData?.anagrafica?.cognome : prefData?.anagrafica?.cognome || ""}
                onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                fieldType="text"
                id="cognome"
                fieldTitle="Cognome*"
                disabled={isBeneficiary ? false : true}
                description={isBeneficiary ? "" : " "}
                error={props.error}
            />
            <PopupTextField
                description={isBeneficiary ? "" : " "}
                defaultValue={(!isBeneficiary || props?.isEdit) ? props?.defaultValue?.anagrafica?.codice_fiscale || prefData?.anagrafica?.codice_fiscale : prefData?.anagrafica?.codice_fiscale || ""}
                onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                fieldType="text"
                id="codice_fiscale"
                fieldTitle="Codice Fiscale*"
                disabled={isBeneficiary ? false : true}
                regex="^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]{1}$"
                error={props.error}
            />
            <PopupTextField
                onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                fieldType="date"
                id="data_di_nascita"
                fieldTitle="Data di nascita*"
                defaultValue={(!isBeneficiary || props?.isEdit) ? props?.defaultValue?.anagrafica?.data_di_nascita || prefData?.anagrafica?.data_di_nascita || user.dateOfBirth : prefData?.anagrafica?.data_di_nascita || ""}
                error={props.error}
                disabled={isBeneficiary ? false : true}
            />
            <PopupTextField
                onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                fieldType="text"
                id="luogo_di_nascita"
                fieldTitle="Luogo di nascita*"
                defaultValue={(!isBeneficiary || props?.isEdit) ? props?.defaultValue?.anagrafica?.luogo_di_nascita || prefData?.anagrafica?.luogo_di_nascita || user.placeOfBirth : prefData?.anagrafica?.luogo_di_nascita || ""}
                error={props.error}
                disabled={isBeneficiary ? false : true}
            />
            <PopupTextField
                onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                fieldType="text"
                id="residenza"
                fieldTitle="Indirizzo residenza*"
                defaultValue={(!isBeneficiary || props?.isEdit) ? props?.defaultValue?.indirizzi?.residenza || prefData?.indirizzi?.residenza || "" : prefData?.indirizzi?.residenza || ""}
                error={props.error}
            />
            <PopupTextField
                onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                fieldType="text"
                id="domicilio"
                fieldTitle="Comune residenza*"
                defaultValue={(!isBeneficiary || props?.isEdit) ? props?.defaultValue?.indirizzi?.domicilio || prefData?.indirizzi?.domicilio || "" : prefData?.indirizzi?.domicilio || ""}
                error={props.error}
            />
            {
                props?.id !== "Beneficiario" &&
                <>
                    <PopupTextField
                        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                        fieldType="text"
                        id="telefono"
                        fieldTitle="Telefono*"
                        defaultValue={props?.defaultValue?.contatti?.telefono}
                        regex="[0-9]{8}"
                        error={props.error}
                    />
                    <PopupTextField
                        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                        fieldType="email"
                        id="email"
                        fieldTitle="Email*"
                        defaultValue={props?.defaultValue?.contatti?.email}
                        regex={`[\\w\\D]+.[\\w\\D]+@[\\w\\D]{3,20}[.][a-zA-Z\\D]{2,10}`}
                        error={props.error}
                    />
                </>
            }

            {
                isBeneficiary &&
                <PopupTextField
                    onChange={(e) => { onFileSet2(e, props.setCustomerData) }}
                    defaultValue={props.defaultValue?.documenti?.certificato_dieta_patologica || prefData?.documenti?.certificato_dieta_patologica || ""}
                    fieldType="file"
                    id="certificato_dieta_patologica"
                    fieldTitle={"Certificato dieta patologica"}
                    description=" " />
            }
            <PopupTextField onChange={(e) => { onFileSet2(e, props.setCustomerData) }}
                fieldType="file"
                id={isBeneficiary ? "certificato_disabilita" : "carta_di_identita"}
                defaultValue={isBeneficiary ? props.defaultValue?.documenti?.certificato_disabilita || prefData?.documenti?.certificato_disabilita || "" : props.defaultValue?.documenti?.carta_di_identita || prefData?.documenti?.carta_di_identita || ""}
                fieldTitle={isBeneficiary ? "Certificato disabilità" : "Carta di identità*"}
                description={isBeneficiary ? " " : ""}
                error={!isBeneficiary ? props.error : false}
            />
        </PopupBox>
    )
}

export default CafeteriaUiForm;


//done
//percheggio
//mensa
//trasporto
//accessi

// rimanenti
// cimitero
//suolo
//lampade
//contravvenzioni
//segnala disservizio
//P