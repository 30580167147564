import React, { useEffect, useState } from "react";
import SideList from "../../../UI-component/SideList";
import ErrorAlert from "../../../UI-component/ErrorAlert";
import SummaryCard from "../../../UI-component/summary/SummaryCard";
import PopupTextField from "../../../UI-component/ui-el/PopupTextField";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import { useDispatch, useSelector } from "react-redux";
import stepSlice from "../../../context/StepsContext";
import { onDataChange2, onDirectDataChange } from "../../../util/Dynamics";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import Loader from "../../../UI-component/Loader";
import SelectionFormBox from "../../../UI-component/SelectionFormBox";

function Specific(props) {
  const dispatcher = useDispatch();

  const ctxApplicant = useSelector(state => state.stepSlice.data.richiedente);
  const ctxPrefs = useSelector(state => state.stepSlice.data.preferenze);

  const [loading, setLoading] = useState(false);
  const [specific, setSpecific] = useState(ctxPrefs.passo_carrabile);
  const [error, setError] = useState({
    tipo_di_invalidita: false,
    chiede: false
  });

  const onNext = () => {
    dispatcher(stepSlice.actions.dynamicSave({ id: "Preferenze", internalId: "passo_carrabile", data: specific }));

    props.next();
  }

  const errorChecker = () => {
    // const err = {
    //   tipo_di_invalidita: specific.tipo_di_invalidita.length > 0 ? false : true,
    //   chiede: specific.chiede.length > 0 ? false : true
    // }

    // setError(err);
  }

  useEffect(() => {
    errorChecker();
  }, [specific])

  const update = (data) => {
    switch (data.id) {
      case "tipologia":
        setSpecific(prev => {
          return { ...prev, tipologia: data.data }
        });
        break;
      case "chiede":
        setSpecific(prev => {
          return { ...prev, chiede: data.data }
        });
        break;
      default:
        break;
    }
  }

  const save = async () => {
    const newPref = { ...specific };

    newPref.passo_carrabile = specific;

    const data = {
      richiedente: ctxApplicant?.length > 0 ? [{ ...ctxApplicant[0], selected: true }] : [],
      preferenze: newPref
    }

    setLoading(true);

    await props.save(data);

    dispatcher(stepSlice.actions
      .dynamicSave({ id: "Preferenze", internalId: "passo_carrabile", data: specific }));

    setLoading(false);
  }

  return (
    <div className="row">
      <SideList
        infoList={[
          { element: "Motivo", id: "#report-author" },
          { element: "Chiede", id: "#report-author" },
          { element: "Provvedimento", id: "#report-author" },
          { element: "Luogo", id: "#report-author" },
          { element: "Dimensioni", id: "#report-author" },
          { element: "Immobile", id: "#report-author" },
        ]} />

      <div className="col-12 col-lg-8 offset-lg-1">
        <div className="steppers-content" aria-live="polite">
          <div className="it-page-sections-container">
            <ErrorAlert errors={[{ name: "Motivo", id: "#isee-info" }]} err={error.tipo_di_invalidita} />
            <ErrorAlert errors={[{ name: "Chiede", id: "#isee-info" }]} err={error.chiede} />
            <ErrorAlert errors={[{ name: "Luogo", id: "#isee-info" }]} err={error.chiede} />
            <ErrorAlert errors={[{ name: "Tipo di invalidità", id: "#isee-info" }]} err={error.tipo_di_invalidita} />
            <ErrorAlert errors={[{ name: "Dimensioni", id: "#isee-info" }]} err={error.chiede} />
            <ErrorAlert errors={[{ name: "Immobile", id: "#isee-info" }]} err={error.tipo_di_invalidita} />

            <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-55%,-50%)", zIndex: "10000" }}>
              <Loader loading={loading} />
            </div>

            <SelectionFormBox
              showTitle={`Tipologia*`}
              title={"tipologia"}
              description={" "}
              choices={[{
                id: '1',
                value: 'Proprietario'
              },
              {
                id: '2',
                value: 'Locatorio'
              },
              {
                id: '3',
                value: 'Altro'
              },
              ]}
              defaultValue={specific.tipologia}
              classDefaultValue={""}
              oKey={""}
              update={update}
              disabled={"  " === "" ? "disabled" : ""} />

            <SummaryCard
              type="Motivo"
              description=" "
            >
              <PopupTextField
                defaultValue={specific.motivo}
                fieldType="text"
                id="motivo"
                fieldTitle="Motivo"
                description="Aggiungi un motivo alla tua richiesta"
                onChange={(e) => { onDirectDataChange(e, setSpecific) }}
              />
            </SummaryCard>

            <SelectionFormBox
              showTitle={`Chiede*`}
              title={"chiede"}
              description={" "}
              choices={[{
                id: '1',
                value: 'Apertura di un nuovo passo carrabile'
              },
              {
                id: '2',
                value: 'Regolarizzazione di un passo carrabile esistente'
              },
              {
                id: '3',
                value: 'Chiusura di un passo carragile già autorizzato'
              },
              {
                id: '4',
                value: 'Voltura della titolarità di passo carrabile già autorizzato'
              },
              {
                id: '5',
                value: 'Modifica delle titolarità di passo carrabile già autorizzato'
              },
              {
                id: '6',
                value: 'Modifica della metratura di passo carrabile già autorizzato'
              },
              {
                id: '7',
                value: 'Modifica della situazione contributiva del soggetto titolare della concessione'
              },
              {
                id: '8',
                value: 'Altro'
              },
              ]}
              defaultValue={specific.chiede}
              classDefaultValue={""}
              oKey={""}
              update={update}
              disabled={"  " === "" ? "disabled" : ""} />

            <SummaryCard
              type="Provvedimento"
              description="Da indicare in caso di richieste per passo carrabile già autorizzato"
            >
              <PopupTextField
                defaultValue={specific.provvedimento?.numero}
                fieldType="text"
                id="numero"
                fieldTitle="Numero"
                description="Aggiungi un numero alla tua richiesta"
                onChange={(e) => { onDataChange2(e, setSpecific) }}
              />
              <PopupTextField
                defaultValue={specific.provvedimento?.data}
                fieldType="text"
                id="data"
                fieldTitle="Data"
                description="Aggiungi una numero alla tua richiesta"
                onChange={(e) => { onDataChange2(e, setSpecific) }}
              />
            </SummaryCard>

            <SummaryCard
              type="Luogo"
              description=" "
            >
              <PopupTextField
                defaultValue={specific.luogo?.indirizzo}
                fieldType="text"
                id="indirizzo"
                fieldTitle="Indirizzo"
                description="Aggiungi un indirizzo alla tua richiesta"
                onChange={(e) => { onDataChange2(e, setSpecific) }}
              />
            </SummaryCard>

            <SummaryCard
              type="Dimensioni"
              description=""
            >
              <PopupTextField
                defaultValue={specific.dimensioni?.lunghezza}
                fieldType="text"
                id="lunghezza"
                fieldTitle="Lunghezza"
                description="Aggiungi una lunghezza alla tua richiesta"
                onChange={(e) => { onDataChange2(e, setSpecific) }}
              />
              <PopupTextField
                defaultValue={specific.dimensioni?.profondita}
                fieldType="text"
                id="profondita"
                fieldTitle="Profondità"
                description="Aggiungi la profondità alla tua richiesta"
                onChange={(e) => { onDataChange2(e, setSpecific) }}
              />
              <PopupTextField
                defaultValue={specific.dimensioni?.superficie}
                fieldType="text"
                id="superficie"
                fieldTitle="Superficie"
                description="Aggiungi la superficie alla tua richiesta"
                onChange={(e) => { onDataChange2(e, setSpecific) }}
              />
            </SummaryCard>


            <SummaryCard
              type="Immobile"
              description=""
            >
              <PopupTextField
                defaultValue={specific.immobile?.foglio}
                fieldType="text"
                id="foglio"
                fieldTitle="Foglio"
                description="Aggiungi un foglio alla tua richiesta"
                onChange={(e) => { onDataChange2(e, setSpecific) }}
              />
              <PopupTextField
                defaultValue={specific.immobile?.particella}
                fieldType="text"
                id="particella"
                fieldTitle="Particella"
                description="Aggiungi la particella alla tua richiesta"
                onChange={(e) => { onDataChange2(e, setSpecific) }}
              />
              <PopupTextField
                defaultValue={specific.immobile?.subalterno}
                fieldType="text"
                id="subalterno"
                fieldTitle="Cubalterno"
                description="Aggiungi un subalterno alla tua richiesta"
                onChange={(e) => { onDataChange2(e, setSpecific) }}
              />
              <PopupTextField
                defaultValue={specific.immobile?.categoria}
                fieldType="text"
                id="categoria"
                fieldTitle="Categoria"
                description="Aggiungi una categoria alla tua richiesta"
                onChange={(e) => { onDataChange2(e, setSpecific) }}
              />
            </SummaryCard>
          </div>
        </div>
        <NextBtnForm
          next={onNext}
          saveRequest={save}
          back={props.back}
          disabled={error.tipo_di_invalidita || error.chiede} />
        {
          props.saved &&
          <SuccessfullPopup />
        }

        {
          props.fail &&
          <SuccessfullPopup isError={true} />
        }
      </div>
    </div>
  )
}

export default Specific;