import { useEffect, useState } from "react";

import AuthenticatedTenant from "../../Layouts/AuthenticatedLayoutTenant";
import WorkPath from "../../UI-component/WorkPath";
import DynamicStepContent from "../../UI-component/DynamicStepContent";
import Privacy from "../_Partials/Privacy";
import DatiSegnalazione from "../_Partials/DatiSegnalazione";
import SegnalazioneRiepilogo from "../_Partials/SegnalazioneRiepilogo";
import { ServiceDetailsAPI } from "../../Apis/ServiceDetailsAPI";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import stepSlice from "../../context/StepsContext";

export default function SegnalazioneDisservizio(props) {
  const dispatcher = useDispatch();

  const [header, setHeader] = useState("");
  const [user, setUser] = useState("");
  const [testData, setTestData] = useState({});
  const [bread, setBread] = useState({});

  const location = useLocation();

  //initialize localStorage conf once when component mounts ?
  useEffect(() => {
    (async () => {
      try {
        const bread = await ServiceDetailsAPI.getBreadcrumbs("SSD");
        setBread(bread);
      } catch (err) {
        console.log(err);
      }
    })()
    //inizioalize first view first step
    if (localStorage.getItem("configuration")) {
      const config = JSON.parse(localStorage.getItem("configuration"));
      setHeader(config.data.current);
    }

    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      setUser(user);
    }

    if (location.state) {
      dispatcher(stepSlice.actions.onDraftData({ data: location.state.data, saved: false }));
    } else {

      (async () => {
        try {
          const saved = await ServiceDetailsAPI.getSaved("ssd");

          if (!saved.success) {
            return;
          }

          // setTestData(saved.data);
          dispatcher(stepSlice.actions.onDraftData({ data: saved?.data, saved: false }));

        } catch (err) {
          console.log(err);
        }
      })()
    }

  }, []);

  return (
    <AuthenticatedTenant auth={user} errors={props.errors} header={header}>
      <WorkPath workPathInfo={{
        base: ["Home", header?.siteUrl],
        medium: ["Servizi", header?.siteUrl + "servizi/"],
        category: "",
        serviceName: [bread.service_name, bread.service_url],
        end: "Servizio digitale",
      }} />

      <div className="container" id="main-container">
        <div className="row justify-content-center" >
          <DynamicStepContent service={props.code}
            successTitle={"Segnalazione disservizio"}
            successDesc={`
              I campi contraddistinti dal simbolo asterisco sono obbligatori.
              `}
            stepList={["INFORMATIVA SULLA PRIVACY", "DATI DI SEGNALAZIONE", "RIEPILOGO"]} steps={[
              {
                component: Privacy, info: {
                  description: `Il ${JSON.parse(localStorage.getItem("configuration")).data.current.description}
                  esercita compiti e funzioni e gestisce servizi come indicato negli artt. 13/14 del Testo Unico Enti Locali (D.lgs. 267/2000 e ss integrazioni).
                  In questo contesto procede al trattamento dei dati personali dell’interessato necessari per fornire ogni tipo 
                  di servizio nel rispetto della normativa vigente (codice protezione dati personali e Regolamento europeo 16/679).
                  Per una completa informazione si invita a prendere conoscenza del contenuto della informativa resa ex art. 13
                  Regolamento europeo.`,
                  linkPrivacy: "",
                }
              },
              { component: DatiSegnalazione, info: { data: testData, code: props.code } },
              { component: SegnalazioneRiepilogo, info: { code: props.code } },
            ]} />

        </div>
      </div>

    </AuthenticatedTenant>
  );
}


