import React, { useEffect, useState } from "react";
import SideList from "../../../../UI-component/SideList";
import SelectionFormBox from "../../../../UI-component/SelectionFormBox";
import AreaFormBox from "../../../../UI-component/AreaFormBox";
import { PublicOfficeAPI } from "../../../../Apis/PublicOfficeAPI";
import { useDispatch, useSelector } from "react-redux";
import stepSlice from "../../../../context/StepsContext";
import ErrorAlert from "../../../../UI-component/ErrorAlert";
import NextBtnForm from "../../../../UI-component/NexBtnForm";
import SuccessfullPopup from "../../../../UI-component/SuccessfullPopup";


//OFFICE SELECTOR
function AppointmentDetails(props) {
  const dispatcher = useDispatch();

  const ctx = useSelector(state => state.stepSlice.data.ufficio);
  const [reasons, setReasons] = useState(null);
  const [error, setError] = useState({
    motive: false,
    details: false,
  });

  const [motive, setMotive] = useState(ctx?.motivo);
  const [details, setDetails] = useState(ctx?.dettagli);


  useEffect(() => {
    getReasons();
    errorChecker();
  }, [])

  const getReasons = async () => {
    try {
      const res = await PublicOfficeAPI.getReasons(ctx.ufficio.id);

      if (!res.success) {
        return;
      }

      setReasons(res.data.map(itm => { return { id: itm.id, value: itm.title } }));
      setMotive(motive || directMotive(res));

    } catch (err) {
      console.log(err);
    }
  }

  const errorChecker = () => {
    let isError = {
      motive: false,
      details: false,
    };

    if (!motive)
      isError.motive = true;

    if (!details)
      isError.details = true;

    setError(isError);
  }

  const update = (data) => {
    if (data.id === "motivo")
      setMotive(data.data);
    else if (data.id === "dettagli")
      setDetails(data.data);
  }

  const onNext = () => {
    dispatcher(stepSlice.actions.dynamicSave({ id: "Ufficio", internalId: "motivo", data: motive }));
    dispatcher(stepSlice.actions.dynamicSave({ id: "Ufficio", internalId: "dettagli", data: details }));

    props.next();
  }

  

  const directMotive = (reas) => {
    const urlParams = new URLSearchParams(window.location.search);
    const motiveID = urlParams.get('motivo');

    let val = "";
    for(let i of reas.data) {
        if(i.id.toString() === motiveID){
            val = i.title;
            break;
        }
    }
    

    return val;
}

  useEffect(() => {
    errorChecker();
  }, [motive, details])

  return (
    <React.Fragment>
      <SideList infoList={[{ element: "Motivo", id: "#motive" }, { element: "Dettagli", id: "#details" }]} />
      <section className="col-12 col-lg-8 offset-lg-1 section-wrapper">
        <div className="cmp-card mb-40" id="reason">

          {
            <ErrorAlert errors={[{ name: "Motivo", id: "#isee-info" }]} err={error.motive} K={"MOTIVE"} />
          }
          {
            <ErrorAlert errors={[{ name: "Dettagli", id: "#isee-info" }]} err={error.details} K="DETAILS" />
          }

          <SelectionFormBox
            showTitle="Motivo*"
            firstSelection={"Seleziona una motivazione"}
            title="Motivo"
            description="Scegli il motivo dell'appuntamento"
            selectTitle="Seleziona il motivo"
            choices={reasons}
            defaultValue={motive ?? "Non Compilato"}
            oKey={"Ufficio"}
            update={update}
          />

          <AreaFormBox
            defaultValue={details ?? ""}
            oKey={"Ufficio"}
            title="Dettagli"
            description="Aggiungi ulteriori dettagli"
            update={update}
          />
        </div>
        <NextBtnForm
          saveRequest={props.saveRequest}
          next={onNext}
          back={props.back}
          noSave={true}
          disabled={error.motive || error.details} />

        {
          props.saved &&
          <SuccessfullPopup />
        }

        {
          props.fail &&
          <SuccessfullPopup isError={true} />
        }
      </section>
    </React.Fragment>
  )
}

export default AppointmentDetails;