import React from "react";


function PreferenceField(props) {
    return (
        <div className="info-wrapper-payment d-flex flex-lg-column">
            <p className="info-wrapper__label me-2 me-md-0" style={{ marginRight: "2px !important" }}>{props.title}</p>
            <p className="fw-semibold">{" " + props.value}</p>
        </div>
    )
}


export default PreferenceField;