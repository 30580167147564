import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

import store from "./context/store/ContextStore";
import { Provider } from "react-redux";

import Router from "./Router";

ReactDOM.render(
  <React.Fragment>
    <Provider store={store}>
      <BrowserRouter basename={"/"}>
        <Router/>
      </BrowserRouter>
    </Provider>
  </React.Fragment>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(null);
