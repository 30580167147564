import { combineReducers, configureStore } from "@reduxjs/toolkit";
import stepSlice from "../StepsContext";
import mainSlice from "./slices/mainSlice";


const rootReducer = combineReducers({
    "stepSlice": stepSlice.reducer,
    "main": mainSlice
})

const store = configureStore({
    reducer: rootReducer
});


export default store;