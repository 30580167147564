
import React from "react";
import PopupTextField from "../ui-el/PopupTextField";
import { onDataChange2 } from "../../util/Dynamics";
import PopupBox from "../PopupBox";


function DisserviceUiForm(props) {
    const isExt = window.location.href.includes("ext");

    return (
        <PopupBox
            close={props.close}
            save={props.save}
            title={props?.id}>
            {
                props.error &&
                <p style={{ color: "red", fontWeight: "bolder" }}>Controlla i campi email o codice fiscale/Completa tutti i campi</p>
            }

            <PopupTextField
                disabled={isExt ? false : true}
                defaultValue={props.user.name || props.defaultValue.anagrafica?.nome}
                onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                fieldType="text"
                id="nome"
                fieldTitle="Nome*"
                description={" "}
                error={props.error}
            />
            <PopupTextField
                defaultValue={props.user.familyName || props.defaultValue?.anagrafica?.cognome}
                onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                fieldType="text"
                id="cognome"
                fieldTitle="Cognome*"
                disabled={isExt ? false : true}
                description={" "}
                error={props.error}
            />
            <PopupTextField
                defaultValue={props.user.fiscalNumber || props.defaultValue?.anagrafica?.codice_fiscale}
                onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                fieldType="text"
                id="codice_fiscale"
                fieldTitle="Codice Fiscale*"
                disabled={isExt ? false : true}
                regex="^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]{1}$"
                error={props.error}
            />
            <PopupTextField
                onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                fieldType="email"
                id="email"
                fieldTitle="Email*"
                defaultValue={props?.defaultValue?.contatti?.email}
                regex={`[\\w\\D]+.[\\w\\D]+@[\\w\\D]{3,20}[.][a-zA-Z\\D]{2,10}`}
                error={props.error}
            />
            <PopupTextField
                onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                fieldType="text"
                id="telefono"
                fieldTitle="Telefono*"
                defaultValue={props?.defaultValue?.contatti?.telefono}
                regex="[0-9]{8}"
                error={props.error}
            />
        </PopupBox>
    )
}

export default DisserviceUiForm;
