import { api } from "./Configs/AxiosConfigs"
import { defineCancelApiObject } from "./Configs/AxiosUtils"

export const OfficeAPI = {

  getOffice: async function (cancel = false) {
    const response = await api.request({
      url: "/office/",
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
      signal: cancel ? cancelApiObject[this?.getOffice?.name]?.handleRequestCancellation()?.signal : undefined,
    })
    return response.data;
  },

  getOffices: async function () {
    const response = await api.request({
      url: "/offices/",
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
      // signal: cancel ? cancelApiObject[this?.getOffices?.name]?.handleRequestCancellation()?.signal : undefined,
    })
    return response.data;
  },

  getOfficesById: async function (id) {
    const response = await api.request({
      url: `/offices/${id}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
    })
    return response.data;
  },

  getAppointments: async function (officeID, monthID) {
    const response = await api.request({
      url: `/get-appointment?office_id=${officeID}&day_of_year=${monthID}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
    })
    return response.data;
  },

  getReasons: async function (officeID) {
    const response = await api.request({
      url: `/reason-by-office/${officeID}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
    })
    return response.data;
  },

  dateNotAvailableChecker: async function () {
    const response = await api.request({
      url: `/get-data-not-available`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
    })
    return response.data;
  }



}

// defining the cancel API object for OfficeAPI
const cancelApiObject = defineCancelApiObject(OfficeAPI)