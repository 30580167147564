import React, { useState } from "react";
import styled from "styled-components";

const TabOpenerController = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const InternalBtn = styled.input`
    background: var(--primary);
    border: none;
    border-radius: 5px;
    padding: 0 10px 0 10px;
    color: white;
    text-align:center;

    &:hover {
        opacity: .9;
    }
`


function TabOpener (props) {
    const [isOpen,setIsOpen] = useState(false);

    const open = () => {
        setIsOpen(prev => !prev);
    }

    return (
        <React.Fragment>
            <br></br>

            {
                isOpen ?
                    <>
                    <TabOpenerController>
                        <h6 style={{color: "var(--primary)", fontWeight: "bolder"}}><strong>{props.title}</strong></h6>
                        <InternalBtn type="button" value="-" onClick={open}/>
                    </TabOpenerController>
                        {props.children} 
                    </>:
                <TabOpenerController>
                    <h6 style={{color: "var(--primary)", fontWeight: "bolder"}}><strong>{props.title}</strong></h6>
                    <InternalBtn type="button" value="+" onClick={open}/>
                </TabOpenerController>
            }
            <br></br>
            <div style={{borderBottom: "solid 2px var(--primary)"}}></div>
            <br></br>
        </React.Fragment>
    )
}

export default TabOpener;