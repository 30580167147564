import React, { useEffect, useState } from "react";
import SideList from "../../../UI-component/SideList";
import ErrorAlert from "../../../UI-component/ErrorAlert";
import SummaryCard from "../../../UI-component/summary/SummaryCard";
import PopupTextField from "../../../UI-component/ui-el/PopupTextField";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import { useDispatch, useSelector } from "react-redux";
import stepSlice from "../../../context/StepsContext";
import { checkValidity, onDataChange2, uniqueIdBuilder } from "../../../util/Dynamics";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import FormBoxField from "../../../UI-component/ui-el/FormBoxField";
import { PaymentApi } from "../../../Apis/PaymentApi";
import PopupSelectField from "../../../UI-component/ui-el/PopupSelectField";
import Loader from "../../../UI-component/Loader";

function Specific(props) {
    const dispatcher = useDispatch();
    const ctxCode = useSelector(state => state.stepSlice.data.avviso);
    const ctxInvoice = useSelector(state => state.stepSlice.data?.fatture);
    const ctxPayment = useSelector(state => state.stepSlice.data.pagamento);
    const applicant = useSelector(state => state.stepSlice.data.richiedente);
    const ctxFine = useSelector(state => state.stepSlice.data.multa);
    const ctxPractice = useSelector(state => state.stepSlice.data.practiceNumber);

    const savedInvoiceData = ctxInvoice?.[0]
    const [invoiceData, setInvoiceData] = useState(savedInvoiceData?.iuv ? savedInvoiceData : {});
    const [show, setShow] = useState(savedInvoiceData?.iuv ? true : false);
    const [code, setCode] = useState(savedInvoiceData?.iuv ? savedInvoiceData?.iuv : (ctxCode ?? ""));
    const [error, setError] = useState(false);
    const [blockErr, setBlockErr] = useState((ctxPayment || savedInvoiceData) ? false : true);
    const [loading, setLoading] = useState(false);
    const [payments, setPayments] = useState([]);
    const [payment, setPayment] = useState(ctxPayment);

    // console.log("ctxInvoice", ctxFine)

    const onNext = () => {
        let data = null;
        if (invoiceData?.invoice)
            data = invoiceData.invoice;
        else {
            if (invoiceData)
                data = invoiceData;
        }

        dispatcher(stepSlice.actions.dynamicSave({ id: "code", data: code }));
        dispatcher(stepSlice.actions.dynamicSave({ id: "fattura", data: [invoiceData] }));
        dispatcher(stepSlice.actions.dynamicSave({ id: "pagamento", data: payment }));

        props.next();
    }

    const errorChecker = () => {
        if (checkValidity(invoiceData))
            setError(false);
        else
            setError(true);
    }

    const onIuvChange = (e) => {
        setCode(e.target.value);
    }

    const getInvoiceInformation = async (cd = false) => {
        try {
            setLoading(true);
            const res = await PaymentApi.getFinesInfo(!cd ? code : cd);

            if (!res?.iuv) {
                setError(true);
                setLoading(false);
                setInvoiceData({});
                dispatcher(stepSlice.actions.dynamicSave({ id: "fattura", data: [] }));
                setShow(false);
                setBlockErr(true);
                return;
            }

            setInvoiceData(res);
            dispatcher(stepSlice.actions.dynamicSave({ id: "fattura", data: [res] }));
            setShow(true);
            setLoading(false);
            setError(false);
            setBlockErr(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
            setInvoiceData({});
            setShow(false);
            setError(true);
            setBlockErr(true);
        }
    }

    const paymentListBuilder = async () => {

        const pays = await PaymentApi.getPayments();

        if (!pays.success) {
            return [];
        }

        const paymentList = [];

        if (pays?.data.length > 0) {
            pays?.data.map((itm, idx) => {
                if (itm?.paymentCode.split("-")[0] === "PCO") {
                    // if (itm.pay && (!itm.request || itm?.request && itm?.request?.practice_number === ctxPractice))
                    if (itm.pay)
                        paymentList.push(itm);
                }
            })
        }

        setPayments(paymentList);
    }

    const findPayment = (iuv) => {
        const paymentFound = payments.filter(itm => {
            return itm.iuv === iuv;
        })

        return paymentFound;
    }

    const getPayment = () => {
        const paymentFound = findPayment(code);
        if (paymentFound.length > 0) {
            setPayment(prev => {
                const pay = paymentFound[0];
                return {
                    ...prev,
                    fattura: pay.paymentCode,
                    data_emissione: pay?.date,
                    metodo: pay?.paymentMethod || "Non trovato",
                    importo: pay.amount || "Non trovato",
                    iuv: pay.iuv || "Non trovato",
                    link: pay.link
                }
            })
            setCode(paymentFound[0].iuv);
            setBlockErr(false);
        }
    }

    const onChangePayment = async (e) => {
        const paymentFound = findPayment(e?.target?.options[e.target.selectedIndex].text);
        // console.log("payment found", payments, paymentFound, e?.target?.options[e.target.selectedIndex].text)

        if (paymentFound.length > 0) {
            // console.log(paymentFound[0].iuv);
            if(paymentFound[0].iuv !== invoiceData?.iuv)
                await getInvoiceInformation(paymentFound[0].iuv);

            setPayment(prev => {
                const pay = paymentFound[0];
                return {
                    ...prev,
                    fattura: pay.paymentCode,
                    data_emissione: pay?.date,
                    metodo: pay?.paymentMethod || "Non trovato",
                    importo: pay.amount || "Non trovato",
                    iuv: pay.iuv || "Non trovato",
                    link: pay.link
                }
            })

            setCode(paymentFound[0].iuv);

        }
    }

    const save = async () => {
        const data = {
            richiedente: applicant,
            ...(invoiceData && {fatture: [invoiceData]}),
            multa: ctxFine,
            practice_number: ctxPractice

        //   richiedente: applicant?.length > 0 ? [{ ...applicant[0], selected: true }] : []
        }
        setLoading(true);
        console.log("data", data);
    
        await props.save(data);
    
        dispatcher(stepSlice.actions.dynamicSave({ id: "code", data: code }));
        dispatcher(stepSlice.actions.dynamicSave({ id: "fattura", data: [invoiceData] }));
        dispatcher(stepSlice.actions.dynamicSave({ id: "pagamento", data: payment }));

        setLoading(false);
    }

    useEffect(() => {
        dispatcher(stepSlice.actions.dynamicSave({ id: "pagamento", data: payment }));
    }, [payment])

    useEffect(() => {
        dispatcher(stepSlice.actions.dynamicSave({ id: "code", data: code }));
    }, [code])

    useEffect(() => {
        // console.log("on change payment", payments, payment, code);
        // if((payments && !payment)) onChangePayment(null);
        if(payments && !payment && code) getPayment();
        if(payments && payment?.iuv && !invoiceData?.iuv){
            getInvoiceInformation(payment?.iuv);
        }
    }, [payments])

    useEffect(() => {
        // console.log("init specific paymentListBuilder");
        paymentListBuilder();
    }, [])

    return (
        <div className="row">
            <SideList
                infoList={[
                    { element: "Codice avviso (IUV)", id: "#report-author" },
                ]} />

            <div className="col-12 col-lg-8 offset-lg-1">
                <div className="steppers-content" aria-live="polite">
                    <div className="it-page-sections-container">

                        <ErrorAlert errors={[{ name: "Codice avviso (IUV)", id: "#isee-info" }]} err={blockErr} />

                        <SummaryCard
                            type="Avviso di pagamento*"
                            description={"Seleziona o inserisci il codice avviso"}>

                            <SummaryCard
                                type=""
                                description={""}>
                                {

                                    <PopupSelectField
                                        defaultValue={payment?.iuv}
                                        onChange={onChangePayment}
                                        id={"code_id"}
                                        fieldTitle={"Codice avviso (IUV) esistente"}
                                        options={payments.map(itm => itm.iuv)}
                                        description="Seleziona un codice avviso esistente"
                                        key={uniqueIdBuilder()} />
                                }
                            </SummaryCard>
                            <PopupTextField
                                defaultValue={code || payment?.iuv}
                                fieldType="text"
                                id="nome"
                                fieldTitle="Codice avviso (IUV)*"
                                description="Inserisci il codice avviso"
                                max="18"
                                straightErr={error}
                                onChange={onIuvChange}
                            />
                            <button
                                disabled={code === invoiceData?.iuv}
                                className={`internal-btn ${code === invoiceData?.iuv ? "disabled" : ""}`}
                                onClick={() => { getInvoiceInformation() }}
                            >Ricerca multa</button>
                        </SummaryCard>
                        {
                            show &&
                            <>
                                <SummaryCard type="Multa">
                                    <FormBoxField title="Codice IUV" value={invoiceData?.iuv || payment?.iuv} />
                                    <FormBoxField title="Causale" value={invoiceData?.causale} />
                                    <FormBoxField title="Ente" value={invoiceData?.ente} />
                                    <FormBoxField title="Codice Fiscale ente" value={invoiceData?.partita_iva_ente} />
                                    <FormBoxField title="Intestatario" value={invoiceData?.intestatario} />
                                    <FormBoxField title="Codice Fiscale intestatario" value={invoiceData?.codice_fiscale} />
                                    <FormBoxField title="Data scadenza" value={invoiceData?.data_scadenza} />
                                    <FormBoxField title="Importo dovuto" value={invoiceData?.importo} />
                                    <FormBoxField title="Targa" value={invoiceData?.targa} />
                                </SummaryCard>
                            </>

                        }
                    </div>
                </div>

                <Loader loading={loading} fixedPosition/>

                <NextBtnForm
                    next={onNext}
                    back={props.back}
                    noSave={false}
                    saveRequest={save}

                    disabled={blockErr || loading} />
                {
                    props.saved &&
                    <SuccessfullPopup />
                }

                {
                    props.fail &&
                    <SuccessfullPopup isError={true} />
                }
            </div>
        </div>
    )
}

export default Specific;