import { api } from "./Configs/AxiosConfigs";

export const YpnosApi = {
  getCemetery: async function () {
    const response = await api.request({
      url: "/get-cemetery-list",
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      }
    })
    return response.data;
  },

  getCemeteryWithArr: async function (id) {
    const response = await api.request({
      url: `/get-cemetery-list?arrivo=${id}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      }
    })
    return response.data;
  },

  getRegister: async function (cemeteryID) {
    const response = await api.request({
      url: `/get-casellario-list/${cemeteryID}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      }
    })
    return response.data;
  },
  getTombs: async function (data) {
    const response = await api.request({
      url: `/get-loculo-list`,
      method: "GET",
      // data: { ...data },
      params: { ...data },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json"
      }
    })
    return response.data;
  },

  getDuration: async function (data) {
    const response = await api.request({
      url: `/get-durate-contrattuali`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }
    })
    return response.data;
  },


}; 
