import React, { useEffect, useState } from "react";
import PopupTextField from "./PopupTextField";
import { onDataChange2, onSelectData2 } from "../../util/Dynamics";

import { useSelector } from "react-redux";

import PopupSelectField from "./PopupSelectField";

function FormSlider(props) {
    const coobligatoCtx = useSelector(state => state.stepSlice.data.imu.coobbligato);

    const [isOpen, setIsOpen] = useState(coobligatoCtx?.codice_fiscale ? true : false);
    const [selection, setSelection] = useState(false);

    const [taxID, setTaxID] = useState({
        coobbligato: {
            codice_fiscale: coobligatoCtx?.codice_fiscale,
            identificativo_coobbligato: coobligatoCtx?.identificativo_coobbligato
        }
    });


    useEffect(() => {
        props.update({
            id: "coobbligato",
            data: { ...taxID }
        });
    }, [taxID])

    const onCheck = (e) => {
        setIsOpen(e.target.checked);
        props.setIsCoobIn(e.target.checked);
    }

    return (
        <section className="it-page-section" id={props.id}>
            <div className="cmp-card mb-40">
                <div className="card has-bkg-grey shadow-sm">
                    <div className="card-header border-0 p-0 mb-lg-30">
                        <div className="d-flex">
                            <h2 className="title-xxlarge mb-1">Coobbligato</h2>
                        </div>
                        <p className="subtitle-small mb-0">Seleziona o aggiungi il coobbligato per il quale pagherai l’imposta</p>
                    </div>
                    <div className="card-body p-0">
                        <div className="cmp-toggle">
                            <div className="toggles">
                                <label className="label mb-0" htmlFor="toggle-toggle">
                                    C’è un coobbligato
                                    <input
                                        type="checkbox"
                                        id="toggle-toggle"
                                        onChange={onCheck}
                                        checked={isOpen} />
                                    <span className="lever me-0 bg-white" />
                                </label>
                            </div>
                        </div>
                    </div>

                    {
                        isOpen &&
                        <>
                            <PopupSelectField
                                defaultValue={taxID.coobbligato.identificativo_coobbligato}
                                onChange={(e) => { onSelectData2(e, "coobbligato", setTaxID, setSelection) }}
                                id="identificativo_coobbligato"
                                fieldTitle="Identificativo coobbligato"
                                description=""
                                options={[
                                    "02 - Genitore / Tutore / Amministratore di sostegno",
                                    "03 - Curatore fallimentare",
                                    "07 - Erede",
                                    "08 - Defunto",
                                    "09 - Committente (Ris. 109/E/2019 AdE)",
                                    "50 - Obbligato solidale",
                                    "51 - Intervento sostitutivo",
                                    "60 - Garante/terzo datore",
                                    "61 - Soggetto aderente al consolidato",
                                    "62 - Soggetto diverso dal fruitore del credito",
                                    "63 - Controparte",
                                    "64 - Gestore del deposito fiscale o del deposito del destinatario registrato",
                                    "70 - Impresa assicuratrice estera fiscalmente rappresentata",
                                    "71 - Soggetto che ha proposto l'atto introduttivo del giudizio",
                                    "72 - Rappresentante fiscale",
                                    "73 - Contribuente",
                                    "74 - Soggetto risultante dall'operazione straordinaria"
                                ]} />

                            <PopupTextField
                                onChange={(e) => onDataChange2(e, setTaxID)}
                                defaultValue={taxID.coobbligato.codice_fiscale}
                                id="codice_fiscale"
                                fieldType="text"
                                fieldTitle="Codice Fiscale"
                                description="Inserire codice fiscale" />

                        </>
                    }
                </div>
            </div>

        </section>
    )
}

export default FormSlider;