
import { useEffect, useState } from "react";

import WorkPath from "../../UI-component/WorkPath";
import DynamicStepContent from "../../UI-component/DynamicStepContent";
import { useDispatch } from "react-redux";
import stepSlice from "../../context/StepsContext";
import Guest from "../../Layouts/GuestLayout";
import Location from "../_Partials/publics/appointments/Location";
import DateTime from "../_Partials/publics/appointments/DateTime";
import AppointmentDetails from "../_Partials/publics/appointments/AppointmentDetails";
import Applicant from "../_Partials/publics/appointments/Applicant";
import Summary from "../_Partials/publics/appointments/Summary";
import Privacy from "../_Partials/Privacy";

export default function PublicPrenotaAppuntamento(props) {
  const dispatcher = useDispatch();

  const [header, setHeader] = useState("");
  const CONF = localStorage.getItem("configuration");
  const pathname = window.location.pathname.replace("ext-", "");
  const searchPath = window.location.search;
  const home = JSON.parse(localStorage.getItem("configuration")).data.current.siteUrl;


  //initialize localStorage conf once when component mounts ?
  useEffect(() => {
    //inizioalize first view first step
    // if (localStorage.getItem("configuration")) {
    //   const config = JSON.parse(localStorage.getItem("configuration"));
    //   setHeader(config.data.current);
    // }
    // if (localStorage.getItem("user")) {
    //   const user = JSON.parse(localStorage.getItem("user"));
    //   setUser(user);
    // }



  }, []);

  useEffect(() => {
    if (localStorage.getItem("access_token"))
      window.location.href = pathname + searchPath;

    localStorage.setItem("destination", pathname + searchPath);
    clearStore();
  }, []);

  const clearStore = () => {
    dispatcher(stepSlice.actions.clear())
  }

  return (
    <>
      <Guest
        auth={props.auth}
        errors={props.errors}
      >
        {
          CONF &&
          <main>
            <WorkPath workPathInfo={{
              base: ["Home", home],
              medium: ["Servizi", home + "servizi/"],
              category: "",
              serviceName: ["Prenotazione appuntamento", "/servizio/prenota-appuntamento/"],
              end: "Servizio digitale",
            }} />

            <div className="container" id="main-container">
              <div className="row justify-content-center" >
                <DynamicStepContent noPriv={true} service={props.code}
                  successTitle={"Prenotazione appuntamento"}
                  successDesc={`
              I campi contrassegnati da asterisco sono obbligatori`}
                  stepList={["Privacy", "Luogo", "Data e orario", "Dettagli appuntamento", "Richiedente", "Riepilogo"]}
                  steps={[
                    {
                      component: Privacy, info: {
                        description: `Il ${JSON.parse(localStorage.getItem("configuration")).data.current.description}
                        esercita compiti e funzioni e gestisce servizi come indicato negli artt. 13/14 del Testo Unico Enti Locali (D.lgs. 267/2000 e ss integrazioni).
                        In questo contesto procede al trattamento dei dati personali dell’interessato necessari per fornire ogni tipo 
                        di servizio nel rispetto della normativa vigente (codice protezione dati personali e Regolamento europeo 16/679).
                        Per una completa informazione si invita a prendere conoscenza del contenuto della informativa resa ex art. 13
                        Regolamento europeo.`,
                        linkPrivacy: "",
                      }
                    },
                    { component: Location, info: {} },
                    { component: DateTime, info: { disp: "disp" } },
                    { component: AppointmentDetails, info: {} },
                    { component: Applicant, info: {} },
                    { component: Summary, info: {} },
                  ]} />
              </div>
            </div>
          </main>
        }



      </Guest>
    </>
  );
}
