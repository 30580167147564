import { api } from "./Configs/AxiosConfigs"
import { defineCancelApiObject } from "./Configs/AxiosUtils"

export const PublicOfficeAPI = {

  getOffice: async function (cancel = false) {
    const response = await api.request({
      url: "ext/office/",
      method: "GET",
      signal: cancel ? cancelApiObject[this?.getOffice?.name]?.handleRequestCancellation()?.signal : undefined,
    })
    return response.data;
  },

  getOffices: async function () {
    const response = await api.request({
      url: "ext/offices/",
      method: "GET",
      // signal: cancel ? cancelApiObject[this?.getOffices?.name]?.handleRequestCancellation()?.signal : undefined,
    })
    return response.data;
  },

  getOfficesById: async function (id) {
    const response = await api.request({
      url: `ext/offices/${id}`,
      method: "GET",
    })
    return response.data;
  },

  getAppointments: async function (officeID, monthID) {
    const response = await api.request({
      url: `ext/get-appointment?office_id=${officeID}&day_of_year=${monthID}`,
      method: "GET",
    })
    return response.data;
  },

  getReasons: async function (officeID) {
    const response = await api.request({
      url: `ext/reason-by-office/${officeID}`,
      method: "GET",
    })
    return response.data;
  },

  extDateNotAvailableChecker: async function () {
    const response = await api.request({
      url: `ext/get-data-not-available`,
      method: "GET",
    })
    return response.data;
  }


}

// defining the cancel API object for OfficeAPI
const cancelApiObject = defineCancelApiObject(PublicOfficeAPI)