import React, { useEffect, useState } from "react";
import SideList from "../../../UI-component/SideList";
import ErrorAlert from "../../../UI-component/ErrorAlert";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import { useDispatch, useSelector } from "react-redux";
import stepSlice from "../../../context/StepsContext";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import SelectionFormBox from "../../../UI-component/SelectionFormBox";
import { YpnosApi } from "../../../Apis/YpnosApi";
import Loader from "../../../UI-component/Loader";
import Disabler from "../../../UI-component/Disabler";

function Specific(props) {
  const dispatcher = useDispatch();
  const ctx = useSelector(state => state.stepSlice.data.preferenze?.cimitero);
  const preferences = useSelector(state => state.stepSlice.data.preferenze);
  const cemetery = useSelector(state => state.stepSlice.data.cimitero);

  const [cemeteries, setCemeteries] = useState([]);
  const [burials, setBurials] = useState([]);
  const [registers, setRegisters] = useState([]);
  const [tombs, setTombs] = useState([]);
  const [durations, setDurations] = useState([]);

  const municipalityCode = JSON.parse(localStorage.getItem("configuration")).data.current.id;

  const [choices, setChoices] = useState({
    cemetery: ctx?.cimitero,
    register: ctx?.casellario,
    type: ctx?.tipo_di_sepoltura,
    arrival: ctx?.arrivo,
    duration: ctx?.durata,
    tomb: ctx?.loculo,
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(true);

  const errorChecker = () => {
    let error = false;

    for (let i of Object.keys(choices)) {
      if ((i === "register" || i === "tomb")
        && choices.type?.value.includes("Tomba"))
        continue;

      if (choices[i]?.value === "") {
        if ((i === "register" || i === "tomb") && municipalityCode === "H275") {
          continue
        }

        error = true;
        break;
      }
    }

    setError(error);
  }

  const getCemetery = async () => {
    try {
      setLoading(true);
      const res = await YpnosApi.getCemetery();

      setCemeteries(res.data?.cimiteri);
      setBurials(res.data?.tipo_sepoltura);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }

  const getCemeteryWithArr = async (id) => {
    try {
      setLoading(true);
      const res = await YpnosApi.getCemeteryWithArr(id);

      setCemeteries(res.data?.cimiteri);
      setBurials(res.data?.tipo_sepoltura);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }

  const getRegisters = async (id) => {
    try {
      setLoading(true);
      const res = await YpnosApi.getRegister(id);

      setRegisters(res.data.casellari);
      setLoading(false);

    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }

  const getDuration = async () => {
    try {
      const res = await YpnosApi.getDuration();

      setDurations(res.data.durate_contrattuali);
    } catch (err) {
      console.log(err);
    }
  }

  const getTombs = async (data) => {
    try {
      setLoading(true);
      const res = await YpnosApi.getTombs(data);

      setTombs(res.data?.loculi);
      setLoading(false);

    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }

  //TODO:  disable register and tombs when type is 1
  const update = (data) => {
    let updatedData = {
      arrivo: { ...choices.arrival },
      tipo_di_sepoltura: { ...choices.type },
      cimitero: { ...choices.cemetery },
      casellario: { ...choices.register },
      loculo: { ...choices.tomb },
      durata: { ...choices.duration }
    };
    switch (data.id) {
      case "arrival":
        setChoices(prev => {
          return { ...prev, arrival: { id: data.selectionID, value: data.data } }
        });
        updatedData.arrivo = { id: data.selectionID, value: data.data };
        getCemeteryWithArr(data.selectionID);
        break;
      case "type":
        setChoices(prev => {
          return { ...prev, type: { id: data.selectionID, value: data.data } }
        });
        updatedData.tipo_di_sepoltura = { id: data.selectionID, value: data.data };

        break;
      case "cemeteries":
        setChoices(prev => {
          return { ...prev, cemetery: { id: data.selectionID, value: data.data } }
        });
        updatedData.cimitero = { id: data.selectionID, value: data.data };
        getRegisters(data.selectionID);
        break;
      case "register":
        setChoices(prev => {
          return { ...prev, register: { id: data.selectionID, value: data.data } }
        });
        updatedData.casellario = { id: data.selectionID, value: data.data };

        const body = {
          cimitero: choices.cemetery?.id,
          casellario: data.selectionID,
          metodo: choices.type?.id,
          arrivo: choices.arrival?.id
        }

        getTombs(body);
        break;
      case "duration":
        setChoices(prev => {
          return { ...prev, duration: { id: data.selectionID, value: data.data } }
        });
        updatedData.durata = { id: data.selectionID, value: data.data };

        break;
      case "tomb":
        setChoices(prev => {
          return { ...prev, tomb: { id: data.selectionID, value: data.data } }
        });
        updatedData.loculo = { id: data.selectionID, value: data.data };
        break;
      default:
        return;
    }

    dispatcher(stepSlice.actions.dynamicSave({ id: "Preferenze", internalId: "cimitero", data: { ...updatedData } }));

  }

  const onNext = () => {
    const data = {
      arrivo: { ...choices.arrival },
      tipo_di_sepoltura: { ...choices.type },
      cimitero: { ...choices.cemetery },
      casellario: { ...choices.register },
      loculo: { ...choices.tomb },
      durata: { ...choices.duration }
    };

    dispatcher(stepSlice.actions.dynamicSave({ id: "Preferenze", internalId: "cimitero", data: { ...data } }));
    props.next();
  }

  const save = async () => {
    const data = {
      arrivo: { ...choices.arrival },
      tipo_di_sepoltura: { ...choices.type },
      cimitero: { ...choices.cemetery },
      casellario: { ...choices.register },
      loculo: { ...choices.tomb },
      durata: { ...choices.duration }
    };

    const dataC = { ...cemetery }

    setLoading(true);

    let pref = structuredClone(preferences);
    pref.cimitero = { ...data };

    await props.save({ ...dataC, preferenze: { ...pref } });

    dispatcher(stepSlice.actions.dynamicSave({ id: "Preferenze", internalId: "cimitero", data: { ...data } }));

    setLoading(false);
  }

  useEffect(() => {
    getDuration();
    getCemetery();
    if (choices?.register?.id) {
      const body = {
        cimitero: choices.cemetery?.id,
        casellario: choices.register.id,
        metodo: choices.type?.id,
        arrivo: choices.arrival?.id
      }

      getRegisters(choices?.cemetery?.id);
      getTombs(body);

    }
  }, []);

  useEffect(() => {
    errorChecker();
  }, [choices])

  return (
    <div className="row">
      <SideList
        infoList={[
          { element: "Arrivo", id: "#report-author" },
          { element: "Tipo di sepoltura", id: "#report-author" },
          { element: "Cimiteri", id: "#report-author" },
          { element: "Casellario", id: "#report-author" },
          { element: "Loculi", id: "#report-author" },
          { element: "Durata", id: "#report-author" },
        ]} />

      <div className="col-12 col-lg-8 offset-lg-1">
        <div className="steppers-content" aria-live="polite">
          <div className="it-page-sections-container">
            {
              <ErrorAlert errors={[{ name: "Dati specifici", id: "#isee-info" }]} err={error} />
            }

            <div style={{ position: "fixed", top: "50%", left: "50%", zIndex: "100000000000000000000000000000" }}>
              <Loader loading={loading} />
            </div>

            {
              municipalityCode === "H275" ?
                <SelectionFormBox
                  showTitle={`Arrivo*`}
                  title={"arrival"}
                  description={"Seleziona un opzione per proseguire con la richiesta"}
                  choices={[{
                    "id": 0,
                    "value": "Proveniente residente"
                  },
                  {
                    "id": 2,
                    "value": "Locale residente"
                  },
                  {
                    "id": 3,
                    "value": "Locale non residente"
                  },
                  {
                    "id": 4,
                    "value": "Proveniente non residente"
                  },
                  ]}
                  defaultValue={choices.arrival?.value}
                  classDefaultValue={""}
                  oKey={""}
                  update={update} /> :
                <SelectionFormBox
                  showTitle={`Arrivo*`}
                  title={"arrival"}
                  description={"Seleziona un opzione per proseguire con la richiesta"}
                  choices={[{
                    "id": 0,
                    "value": "Proveniente"
                  },
                  {
                    "id": 2,
                    "value": "Locale"
                  }
                  ]}
                  defaultValue={choices.arrival?.value}
                  classDefaultValue={""}
                  oKey={""}
                  update={update} />
            }

            <Disabler class={choices?.arrival.value === "" ? "disabled" : ""}>
              <SelectionFormBox
                showTitle={`Tipo di sepoltura*`}
                title={"type"}
                description={"Seleziona l'arrivo per poter scegliere il tipo di sepoltura"}
                choices={burials ?? []}
                defaultValue={choices.type?.value}
                classDefaultValue={""}
                oKey={""}
                update={update}
                disabled={choices?.arrival.value === "" ? "disabled" : ""} />
            </Disabler>


            <SelectionFormBox
              showTitle={`Cimiteri*`}
              title={"cemeteries"}
              description={"Seleziona un opzione per proseguire con la richiesta"}
              choices={cemeteries ?? []}
              defaultValue={choices.cemetery?.value ?? ""}
              classDefaultValue={""}
              oKey={""}
              update={update} />

            <Disabler class={choices.type?.value.includes("Tomba") || municipalityCode === "H275" ? "disabled" : ""}>
              <SelectionFormBox
                showTitle={`Casellario*`}
                title={"register"}
                description={municipalityCode === "H275" ? "I loculi non sono selezionabili" : !choices.type?.value.includes("Tomba") ? "Seleziona un opzione per poter proseguire con la richiesta" : "Il casellario non e' selezionabile"}
                choices={registers}
                defaultValue={choices.register?.value ?? ""}
                classDefaultValue={""}
                oKey={""}
                update={update}
                disabled={municipalityCode === "H275" || choices.type?.value.includes("Tomba") ? true : false} />
            </Disabler>

            <Disabler class={choices.type?.value.includes("Tomba") || municipalityCode === "H275" ? "disabled" : ""}>
              <SelectionFormBox
                showTitle={`Loculi*`}
                title={"tomb"}
                description={municipalityCode === "H275" ? "I loculi non sono selezionabili" : !choices.type?.value.includes("Tomba") ? "Seleziona un opzione per poter proseguire con la richiesta" : "I loculi non sono selezionabili"}
                choices={tombs ?? []}
                defaultValue={choices.tomb?.value ?? ""}
                classDefaultValue={""}
                oKey={""}
                update={update}
                disabled={municipalityCode === "H275" || choices.type?.value.includes("Tomba") ? true : false} />
            </Disabler>

            <SelectionFormBox
              showTitle={`Durata*`}
              title={"duration"}
              description={"Seleziona un opzione per proseguire con la richiesta"}
              choices={durations ?? []}
              defaultValue={choices.duration?.value}
              classDefaultValue={""}
              oKey={""}
              update={update} />

          </div>
        </div>
        <NextBtnForm
          next={onNext}
          back={props.back}
          saveRequest={save}
          noSave={false}
          disabled={error} />
        {
          props.saved &&
          <SuccessfullPopup />
        }

        {
          props.fail &&
          <SuccessfullPopup isError={true} />
        }
      </div>
    </div>
  )
}

export default Specific;