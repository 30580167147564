import React, { useState } from "react";

import PropertyCard from "../registryForms/PropertyCard";
import PropertyCardHeader from "../registryForms/PropertyCardHeader";
import AddPropertyPopup from "../AddingForms/AddPropertyPopup";
import { uniqueIdBuilder } from "../../util/Dynamics";


function CustomerPropertyFormBox(props) {
    const [show, setShow] = useState(false);

    const addProperty = () => {
        setShow(true);
    }

    const close = () => {
        setShow(false);
    }

    const onRemove = (id) => {
        const index = props.cards.findIndex(itm => {
            return itm.id === id;
        });

        props.update({
            id: "immobili",
            removing: true,
            data: index
        });

    }

    return (
        <>
            <section className="it-page-section" id={props.id}>
                <div className="cmp-card mb-40">
                    {
                        show &&
                        <AddPropertyPopup
                            year={props.year}
                            close={close}
                            update={props.update} />
                    }
                    <div className="card has-bkg-grey shadow-sm p-big">
                        <PropertyCardHeader />

                        <div className="card-body p-0">
                            {
                                props.cards.map((card, idx) => {
                                    return <PropertyCard
                                        year={props.year}
                                        info={card}
                                        update={props.update}
                                        remove={onRemove}
                                        key={uniqueIdBuilder()} />
                                })
                            }

                            {/* adder button */}
                            <button
                                type="button"
                                className="btn plus-text mt-20 p-0"
                                onClick={addProperty}>
                                <span className="rounded-icon">
                                    <svg className="icon icon-primary icon-sm" aria-hidden="true">
                                        <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-plus-circle" />
                                    </svg>
                                </span>
                                <span>Aggiungi Immobile</span>
                            </button>
                        </div>
                    </div>


                </div>
            </section>
        </>
    )
}


export default CustomerPropertyFormBox;