import React, { useEffect, useState } from "react";
import PopupTextField from "../ui-el/PopupTextField";
import { checkValidity, onDataChange2, onDirectDataChange, onFileSet2, titleNormalizer } from "../../util/Dynamics";
import PopupBox from "../PopupBox";


function FamilyMemberForm(props) {
  const [data, setData] = useState(props.defaultValue || {})

  const onChange = (e) => {
    if(props?.setCustomerData) onDataChange2(e, props?.setCustomerData )
    else {
      setData(prev => {
        const id = titleNormalizer(e.target.id)
        return {
          ...prev,
          anagrafica: {
            ...(data?.anagrafica || {}),
            [id]: e?.target?.value
          }
        }
      })
    }
  }

  const save = () => {
    if (checkValidity(data)) {
      // console.log("data", data);
      // dispatcher(stepSlice.actions.dynamicEdit({id: "Coobbligato",data: cobData}));
      props.update({
        id: "nucleo_familiare",
        isEdit: true,
        data: [data]
      });
      props.close();
    }
  }

  // useEffect(() => {
  //   console.log("data changed", data)
  // }, [data] )

  return (
    <PopupBox close={props.close} save={props.save || save} title={props?.title || props?.id}>
      {
        props.error &&
        <p style={{ color: "red", fontWeight: "bolder" }}>Completa tutti i campi</p>
      }

      <PopupTextField
        defaultValue={props?.defaultValue?.anagrafica?.nome}
        onChange={onChange}
        fieldType="text"
        id="nome"
        fieldTitle="Nome*"
        description={" "}
        error={props.error}
      />
      <PopupTextField
        defaultValue={props?.defaultValue?.anagrafica?.cognome}
        onChange={onChange}
        fieldType="text"
        id="cognome"
        fieldTitle="Cognome*"
        description={" "}
        error={props.error}
      />
      <PopupTextField
        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
        fieldType="date"
        id="data_di_nascita"
        fieldTitle="Data di nascita*"
        defaultValue={props?.defaultValue?.anagrafica?.data_di_nascita}
        error={props.error}
      />
      <PopupTextField
        description={" "}
        defaultValue={props?.defaultValue?.anagrafica?.anni}
        onChange={onChange}
        fieldType="number"
        min={120}
        id="anni"
        fieldTitle="Anni compiuti alla data di presentazione della domanda*"
        error={props.error}
      />

      <PopupTextField
        description={" "}
        defaultValue={props?.defaultValue?.anagrafica?.grado_parentela}
        onChange={onChange}
        fieldType="text"
        id="grado_parentela"
        fieldTitle="Grado di parentela*"
        error={props.error}
      />
    </PopupBox>
  )
}

export default FamilyMemberForm;
