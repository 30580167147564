import React from "react";

function Logout(props) {
  //links need to be added
  const logout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user");
    sessionStorage.removeItem("isLoggedIn");

    window.location.replace(`${window.location.protocol}//${window.location.host}/login-spid`);
  };

  return (
    <div className="it-header-slim-right-zone" role="navigation">
      <div className="nav-item dropdown">
        <button
          type="button"
          className="nav-link dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          aria-controls="languages"
          aria-haspopup="true"
        >
          <span className="visually-hidden">Lingua attiva:</span>
          <span>ITA</span>
          <svg className="icon">
            <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-expand"></use>
          </svg>
        </button>
        <div className="dropdown-menu">
          <div className="row">
            <div className="col-12">
              <div className="link-list-wrapper">
                <ul className="link-list">
                  <li>
                    <a
                      className="dropdown-item list-item"
                      href="#"
                    >
                      <span>
                        ITA{" "}
                        <span className="visually-hidden">
                          selezionata
                        </span>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item list-item"
                      href="#"
                    >
                      <span>ENG</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="it-user-wrapper nav-item dropdown">
        <a
          className="btn btn-primary btn-icon btn-full"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          aria-haspopup="true"
          data-element="personal-area-login"
        >
          <span
            className="rounded-icon t-primary"
            aria-hidden="true"
          >
            {(props.name && props.familyName) && `${props?.name[0]}${props?.familyName[0]}`}
          </span>
          <span className="d-none d-lg-block">
            {props?.name} {props?.familyName}
          </span>
          <svg className="icon">
            <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-expand"></use>
          </svg>
        </a>
        <div className="dropdown-menu">
          <div className="row">
            <div className="col-12">
              <div className="link-list-wrapper">
                <ul className="link-list">
                  <li>
                    <a className="list-item" href="/">
                      <span>Area personale</span>
                    </a>
                  </li>
                  <li>
                    <span className="divider"></span>
                  </li>
                  <li>
                    <a className="list-item left-icon" href="#" onClick={logout}>
                      <svg className="icon icon-primary icon-sm left">
                        <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-external-link"></use>
                      </svg>
                      <span className="fw-bold">Esci</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Logout;