import React, { useEffect, useState } from 'react';
import PaymentPage from "./Pages/_Partials/PaymentPage";
import SuoloPubblico from "./Pages/Permessi-e-autorizzazioni/SuoloPubblico";
import LampadeVotive from "./Pages/Servizi-a-pagamento/LampadeVotive";
import Fines from "./Pages/Servizi-a-pagamento/Fines";
import Cemetery from "./Pages/Servizi-a-pagamento/Cemetery";
import Access from "./Pages/Servizi-a-pagamento/Access";
import PermessoParcheggio from "./Pages/Permessi-e-autorizzazioni/PermessoParcheggio";
import PublicPrenotaAppuntamento from "./Pages/Commons/PublicPrenotaAppuntamento";
import PublicRichiestaAssistenza from "./Pages/_Partials/publics/help/PublicRichiestaAssistenza";
import PublicSegnalazioneDisservizio from "./Pages/Commons/PublicSegnalazioneDisservizio";
import EconomicBonuses from "./Pages/Servizi-a-pagamento/EconomicBonuses";
import TowZone from "./Pages/Servizi-a-pagamento/TowZone";
import PagoPaEndingForm from "./UI-component/UIform/PagoPaEndingForm";
import AccessoServizi from "./Pages/_Auth/AccessoServizi";
import Loading from "./Pages/Loading";
import MensaScolastica from "./Pages/Servizi-a-pagamento/MensaScolastica";
import TrasportoScolastico from "./Pages/Servizi-a-pagamento/TrasportoScolastico";
import IscrizioneScuolaInfanzia from "./Pages/Iscrizione-a-graduatoria/IscrizioneScuolaInfanzia";
import AreaPersonale from "./Pages/_Utenti/AreaPersonale";
import PrenotaAppuntamento from "./Pages/Commons/PrenotaAppuntamento";
import SegnalazioneDisservizio from "./Pages/Commons/SegnalazioneDisservizio";
import RichiestaAssistenza from "./Pages/Commons/RichiestaAssistenza";
import PagamentoIMU from "./Pages/Pagamenti-dovuti-F24/PagamentoIMU";
/* import Services from "./Pages/Services"; */
import App from "./App";
import ProtectedRoute from "./util/ProtectedRoute";
import { Route, Routes, useLocation } from "react-router-dom";
import { ConfigurationAPI } from './Apis/ConfigurationAPI';
import PaymentPaPage from './Pages/_Partials/PaymentPaPage';
import Fee from './Pages/Servizi-a-pagamento/Fee';
import Marriage from './Pages/Servizi-a-pagamento/Marriage';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { configSelector, setConfiguration } from './context/store/slices/mainSlice';

let abortController;

export default function Router(props) {

    const [config, setConfig] = useState(JSON.parse(localStorage.getItem("configuration") || null));
    const location = useLocation();
    const dispatch = useDispatch();
    const configuration = useSelector(configSelector);
    /********************* methods go down here *********************/
    const getConfiguration = async () => {
        abortController = new AbortController();
        let signal = abortController.signal;
  
        try {
            const configResponse = await ConfigurationAPI.getConfiguration(signal);
            localStorage.setItem("configuration", JSON.stringify(configResponse));
            setConfig(configResponse);
            dispatch(setConfiguration(configResponse))
            // console.log("///////////////// check configuration /////////////////", configResponse?.data?.last_tenant_update, configuration?.data?.last_tenant_update)
            // if(configResponse?.data?.last_tenant_update !== configuration?.data?.last_tenant_update){
            //     console.log("///////////////// new configuration /////////////////")
            //     localStorage.setItem("configuration", JSON.stringify(configResponse));
            
            //     setConfig(configResponse);
            //     dispatch(setConfiguration(configResponse))
            // }
            // else{
            //     console.log("///////////////// using cached configuration /////////////////")
            // }
        } catch (err) {
          console.log("Configuration error", err);
        }
    }
    /********************* useEffects go down here *********************/
    useEffect(() => {
        // console.log("enter hereee")
        getConfiguration();
    }, [location])
    useEffect(() => {
        // getConfiguration();

        return () => {
            if(abortController) abortController.abort();
        }
    }, [])
    /********************* Render methods go down here *********************/

    const renderRoutes = () => {
        return (
            <Routes>
                <Route path="/login-spid" element={<AccessoServizi />} />
                <Route path="/SPIDlogin/:idp" element={<Loading />} />
                <Route path="/authentication/:token" element={<Loading />} />
                <Route path="/spid-token" element={<Loading />} />
                <Route path="/" element={<App />}>
                    <Route
                    path="/ext-prenota-appuntamento"
                    element={<PublicPrenotaAppuntamento code="SPA" />}
                    />
                    <Route
                    path="/ext-richiesta-assistenza"
                    element={<PublicRichiestaAssistenza code="SRA" />}
                    />
                    <Route
                    path="/ext-segnalazione-disservizio"
                    element={
                        <PublicSegnalazioneDisservizio code="SSD" />
                    }
                    />{" "}
                    <Route
                    path="/payment-page"
                    element={
                        <ProtectedRoute>
                        <PaymentPage />
                        </ProtectedRoute>
                    }
                    />
                    <Route
                    path=""
                    element={
                        <ProtectedRoute>
                        <AreaPersonale />
                        </ProtectedRoute>
                    }
                    />
                    <Route
                    path="payment"
                    element={
                        <ProtectedRoute>
                        <PaymentPage />
                        </ProtectedRoute>
                    }
                    />
                    <Route
                    path="payment-pa"
                    element={
                        <ProtectedRoute>
                        <PaymentPaPage />
                        </ProtectedRoute>
                    }
                    />
                    <Route
                    path="pagamento-canone-cosap"
                    element={
                        <ProtectedRoute>
                        <Fee code="PCCO" />
                        </ProtectedRoute>
                    }
                    />
                    <Route
                    path="pubblicazione-matrimonio"
                    element={
                        <ProtectedRoute>
                        <Marriage code="RPM" />
                        </ProtectedRoute>
                    }
                    />
                    <Route
                    path="pagopa"
                    element={
                        <ProtectedRoute>
                        <PagoPaEndingForm />
                        </ProtectedRoute>
                    }
                    />


                    <Route
                    path="/bonus-economici"
                    element={
                        <ProtectedRoute>
                        <EconomicBonuses code="DBE" />
                        </ProtectedRoute>
                    }
                    />
                    <Route
                    path="/permesso-passo-carrabile"
                    element={
                        <ProtectedRoute>
                        <TowZone code="PPC" />
                        </ProtectedRoute>
                    }
                    />
                    <Route
                    path="/sepoltura-defunto"
                    element={
                        <ProtectedRoute>
                        <Cemetery code="RSD" />
                        </ProtectedRoute>
                    }
                    />
                    <Route
                    path="/accesso-atti"
                    element={
                        <ProtectedRoute>
                        <Access code="RAAT" />
                        </ProtectedRoute>
                    }
                    />
                    {/* <Route
                    path="/servizi"
                    element={
                        <ProtectedRoute>
                        <Services />
                        </ProtectedRoute>
                    }
                    /> */}
                    <Route
                    path="/servizi"
                    element={
                        <Navigate to="/" replace={true} />
                    }
                    />
                    <Route
                    path="/prenota-appuntamento"
                    element={
                        <ProtectedRoute>
                        <PrenotaAppuntamento code="SPA" />
                        </ProtectedRoute>
                    }
                    />{" "}
                    <Route
                    path="/permesso-parcheggio-invalidi"
                    element={
                        <ProtectedRoute>
                        <PermessoParcheggio code="PPI" />
                        </ProtectedRoute>
                    }
                    />{" "}
                    <Route
                    path="/segnalazione-disservizio"
                    element={
                        <ProtectedRoute>
                        <SegnalazioneDisservizio code="SSD" />
                        </ProtectedRoute>
                    }
                    />{" "}
                    <Route
                    path="/pagamento-votive"
                    element={
                        <ProtectedRoute>
                        <LampadeVotive code="PCL" />
                        </ProtectedRoute>
                    }
                    />{" "}
                    <Route
                    path="/pagamento-contravvenzioni"
                    element={
                        <ProtectedRoute>
                        <Fines code="PCO" />
                        </ProtectedRoute>
                    }
                    />{" "}
                    <Route
                    path="/richiesta-assistenza"
                    element={
                        <ProtectedRoute>
                        <RichiestaAssistenza code="SRA" />
                        </ProtectedRoute>
                    }
                    />
                    <Route
                    path="/pagamento-imu"
                    element={
                        <ProtectedRoute>
                        <PagamentoIMU code={"PTI"} />
                        </ProtectedRoute>
                    }
                    />{" "}
                    <Route
                    path="/mensa-scolastica"
                    element={
                        <ProtectedRoute>
                        <MensaScolastica code="RMS" />
                        </ProtectedRoute>
                    }
                    />
                    <Route
                    path="/trasporto-scolastico"
                    element={
                        <ProtectedRoute>
                        <TrasportoScolastico code="RTS" />
                        </ProtectedRoute>
                    }
                    />
                    <Route
                    path="/iscrizione-scuola-infanzia"
                    element={
                        <ProtectedRoute>
                        <IscrizioneScuolaInfanzia />
                        </ProtectedRoute>
                    }
                    />
                    <Route
                    path="/occupazione-suolo-pubblico"
                    element={
                        <ProtectedRoute>
                        <SuoloPubblico code="PSP" />
                        </ProtectedRoute>
                    }
                    />
                </Route>
            </Routes>
        )
    }
    return (
        <>
            {config && renderRoutes()}
        </>
    );
}
