import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const checkUserToken = () => {
    const userToken = localStorage.getItem("access_token");
    if (!userToken || userToken === "undefined") {
      setIsLoggedIn(false);
    }
    setIsLoggedIn(true);
  };

  window.onload = () => {
    if (!sessionStorage.getItem("isLoggedIn")) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("user");
    }
  }

  window.onbeforeunload = () => {
    if (!sessionStorage.getItem("isLoggedIn")) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("user");
    }
  }

  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);

  useEffect(() => {
    document.title = JSON.parse(localStorage.getItem("configuration"))?.data?.current?.description || "";
  }, []);

  return (
    <React.Fragment>{" "}
      <Outlet />{" "}
    </React.Fragment>
  );
}
export default App;
