import { useEffect, useState } from "react";

import AuthenticatedTenant from "../../Layouts/AuthenticatedLayoutTenant";
import Privacy from "../_Partials/Privacy";
import PagamentiIMUDatiPersonali from "../_Partials/PagamentiIMUDatiPersonali";
import PagamentiIMURiepilogo from "../_Partials/PagamentiIMURiepilogo";
import PagamentiIMUAnteprimaF24 from "../_Partials/PagamentiIMUAnteprimaF24";
import PagamentiIMUPreferenze from "../_Partials/PagamentiIMUPreferenze";
import WorkPath from "../../UI-component/WorkPath";
import DynamicStepContent from "../../UI-component/DynamicStepContent";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import stepSlice from "../../context/StepsContext";
import { ServiceDetailsAPI } from "../../Apis/ServiceDetailsAPI";


export default function PagamentoIMU(props) {
  const [header, setHeader] = useState("");
  const [user, setUser] = useState("");
  const location = useLocation();
  const dispatcher = useDispatch();
  const [testData, setTestData] = useState({});
  const [bread, setBread] = useState({});

  //initialize localStorage conf once when component mounts ?
  useEffect(() => {
    //inizioalize first view first step
    if (localStorage.getItem("configuration")) {
      const config = JSON.parse(localStorage.getItem("configuration"));
      setHeader(config.data.current);
    }
    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      setUser(user);
    }

    (async () => {
      try {
        const bread = await ServiceDetailsAPI.getBreadcrumbs("PTI");
        setBread(bread);
      } catch (err) {
        console.log(err);
      }
    })()

    if (location.state) {
      dispatcher(stepSlice.actions.onDraftData({ data: location.state.data }));
    }

    if (!location.state) {
      (async () => {
        try {
          const saved = await ServiceDetailsAPI.getSaved("pti");

          if (!saved.success) {
            return;
          }

          // setTestData(saved);
          dispatcher(stepSlice.actions.onDraftData({ data: saved, saved: true }));
        } catch (err) {
          console.log(err);
        }
      })()

    }


  }, []);

  return (
    <AuthenticatedTenant auth={user} errors={props.errors} header={header}>
      {/* primry work path in the page ex: Home/ services/ payments */}
      <WorkPath workPathInfo={{
        base: ["Home", header?.siteUrl],
        medium: ["Servizi", header?.siteUrl + "servizi/"],
        category: [bread.category_name, bread.category_url],
        serviceName: [bread.service_name, bread.service_url],
        end: "Servizio digitale",
      }} />

      <div className="container" id="main-container">
        <div className="row justify-content-center">
          {/* dinamically creates the pages giving them step properties */}
          <DynamicStepContent service={props.code}
            successTitle={"Pagamento tributi IMU - F24"}
            successDesc={`
                Inserire le informazioni necessarie a scaricare l’F24 per il pagamento dell’IMU.<br />
                Potrai ricontrollare tutti i dati nel riepilogo, prima di
                inviare la richiesta.`}
            stepList={["INFORMATIVA SULLA PRIVACY", "DATI GENERALI", "RIEPILOGO"]} steps={[
              {
                component: Privacy, info: {
                  description: `Il ${JSON.parse(localStorage.getItem("configuration")).data.current.description}
                  esercita compiti e funzioni e gestisce servizi come indicato negli artt. 13/14 del Testo Unico Enti Locali (D.lgs. 267/2000 e ss integrazioni).
                  In questo contesto procede al trattamento dei dati personali dell’interessato necessari per fornire ogni tipo 
                  di servizio nel rispetto della normativa vigente (codice protezione dati personali e Regolamento europeo 16/679).
                  Per una completa informazione si invita a prendere conoscenza del contenuto della informativa resa ex art. 13
                  Regolamento europeo.`,
                  linkPrivacy: "",
                }
              },
              { component: PagamentiIMUDatiPersonali, info: { user, code: props.code, testData: testData } },
              { component: PagamentiIMURiepilogo, info: { user, code: props.code } },
              // { component: PagamentiIMUPreferenze, info: user },
              // { component: PagamentiIMUAnteprimaF24, info: user },
            ]} />
        </div>
      </div>
    </AuthenticatedTenant>
  );
}
