import React, { useState } from "react";
import { useSelector } from "react-redux";
import SummaryCard from "../../../UI-component/summary/SummaryCard";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import FormBoxField from "../../../UI-component/ui-el/FormBoxField";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import Loader from "../../../UI-component/Loader";

function Summary(props) {
  const ctxApplicant = useSelector(state => state.stepSlice.data.richiedente[0]);
  const ctxPref = useSelector(state => state.stepSlice.data.preferenze);
  const ctx = useSelector(state => state.stepSlice.data);

  const [applicant, setApplicant] = useState(ctxApplicant);
  const [prefs, setPrefs] = useState(ctxPref);
  const [loading, setLoading] = useState(false);

  const onNext = async () => {
    const data = {
      richiedente: [ctxApplicant],
      preferenze: prefs
    };

    props.send(data);
  }

  const save = async () => {
    const data = {
      richiedente: [{ ...ctxApplicant, selected: true }],
      preferenze: prefs
    }

    setLoading(true);

    await props.save(data);

    setLoading(false);
  }

  const update = (data) => {
    if (data.id === "Richiedente")
      setApplicant(data.data[0]);
    else if (data.id === "Preferenze") {
      const park = data.data.parcheggio;

      const obj = structuredClone(data.data)

      obj.parcheggio.tipo_di_invalidita = Array.isArray(park.tipo_di_invalidita) ? park.tipo_di_invalidita : [park.tipo_di_invalidita];
      obj.parcheggio.chiede = Array.isArray(park.chiede) ? park.chiede : [park.chiede];

      setPrefs(obj);
    }
  }

  return (

    <div className="row">
      <div className="col-12 col-lg-8 offset-lg-2">
        <div className="steppers-content" aria-live="polite">
          <div className="it-page-sections-container">
            <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-55%,-50%)", zIndex: "10000" }}>
              <Loader loading={loading} />
            </div>

            <SummaryCard
              type="richiedente"
              cardTitle={`${applicant?.anagrafica?.nome} ${applicant?.anagrafica?.cognome}`}
              mod={true}
              update={update}
              id={"Richiedente"}
              taxID={applicant?.anagrafica.codice_fiscale}
              service={props.info.code}
              registryData={applicant} >
              <FormBoxField title="Nome" value={applicant?.anagrafica.nome} />
              <FormBoxField title="Cognome" value={applicant?.anagrafica.cognome} />
              <FormBoxField title="Codice Fiscale" value={applicant?.anagrafica.codice_fiscale} />
              <FormBoxField title="Data di nascita" value={applicant?.anagrafica.data_di_nascita} />
              <FormBoxField title="Luogo di nascita" value={applicant?.anagrafica.luogo_di_nascita} />
              <FormBoxField title="Indirizzo residenza" value={applicant?.indirizzi.residenza} />
              <FormBoxField title="Comune residenza" value={applicant?.indirizzi.domicilio} />
              <FormBoxField title="Telefono" value={applicant?.contatti.telefono} />
              <FormBoxField title="Email" value={applicant?.contatti.email} />
              <FormBoxField title="Carta di identità " value={applicant?.documenti.carta_di_identita} />
            </SummaryCard>

            <SummaryCard
              type="Preferenze"
              cardTitle="Preferenze di servizio"
              mod={true}
              service="PPC"
              registryData={ctx}
              choiceData={[
                {
                  "options": [
                    {
                      id: '1',
                      value: 'Proprietario'
                    },
                    {
                      id: '2',
                      value: 'Locatorio'
                    },
                    {
                      id: '3',
                      value: 'Altro'
                    },
                  ],
                  "title": [
                    "PASSO CARRABILE",
                    "TIPOLOGIA"
                  ],
                  "type": "Select"
                },
                {
                  "options": [
                    {
                      id: '1',
                      value: 'Apertura di un nuovo passo carrabile'
                    },
                    {
                      id: '2',
                      value: 'Regolarizzazione di un passo carrabile esistente'
                    },
                    {
                      id: '3',
                      value: 'Chiusura di un passo carragile già autorizzato'
                    },
                    {
                      id: '4',
                      value: 'Voltura della titolarità di passo carrabile già autorizzato'
                    },
                    {
                      id: '5',
                      value: 'Modifica delle titolarità di passo carrabile già autorizzato'
                    },
                    {
                      id: '6',
                      value: 'Modifica della metratura di passo carrabile già autorizzato'
                    },
                    {
                      id: '7',
                      value: 'Modifica della situazione contributiva del soggetto titolare della concessione'
                    },
                    {
                      id: '8',
                      value: 'Altro'
                    },
                  ],
                  "title": [
                    "PASSO CARRABILE",
                    "CHIEDE"
                  ],
                  "type": "Select"
                },
                {
                  "options": [
                    {
                      "id": 1,
                      "title": "Motivo"
                    }
                  ],
                  "title": [
                    "PASSO CARRABILE",
                    "MOTIVO"
                  ],
                  "child_title": [],
                  "type": "MultiText"
                },

                {
                  "options": [
                    {
                      "id": 1,
                      "title": "Numero"
                    },
                    {
                      "id": 1,
                      "title": "Data"
                    }
                  ],
                  "title": [
                    "PASSOCARRABILE",
                    "PROVVEDIMENTO"
                  ],
                  "child_title": [
                    "Numero",
                    "Data"
                  ],
                  "type": "MultiText"
                },
                {
                  "options": [
                    {
                      "id": 1,
                      "title": "Indirizzo"
                    }
                  ],
                  "title": [
                    "PASSO CARRABILE",
                    "LUOGO"
                  ],
                  "child_title": [
                    "Indirizzo",
                  ],
                  "type": "MultiText"
                },
                {
                  "options": [
                    {
                      "id": 1,
                      "title": "Lunghezza"
                    },
                    {
                      "id": 1,
                      "title": "Profondità"
                    },
                    {
                      "id": 1,
                      "title": "Superficie"
                    }
                  ],
                  "title": [
                    "PASSO CARRABILE",
                    "PROVVEDIMENTO"
                  ],
                  "child_title": [
                    "Lunghezza",
                    "Profondita",
                    "Superficie"
                  ],
                  "type": "MultiText"
                },
                {
                  "options": [
                    {
                      "id": 1,
                      "title": "Foglio"
                    },
                    {
                      "id": 1,
                      "title": "Particella"
                    },
                    {
                      "id": 1,
                      "title": "Subalterno"
                    },
                    {
                      "id": 1,
                      "title": "Categoria"
                    }
                  ],
                  "title": [
                    "PASSO CARRABILE",
                    "Immobile"
                  ],
                  "child_title": [
                    "Foglio",
                    "Particella",
                    "Subalterno",
                    "Categoria",
                  ],
                  "type": "MultiText"
                },

              ]
              }
              update={update}>
              <FormBoxField title="Tipologia" value={prefs?.passo_carrabile?.tipologia || "Non compilato"} />
              <FormBoxField title="Motivo" value={prefs?.passo_carrabile?.motivo || "Non compilato"} />
              <FormBoxField title="Chiede" value={prefs?.passo_carrabile?.chiede || "Non compilato"} />
              <FormBoxField title="Numero" value={prefs?.passo_carrabile?.provvedimento.numero || "Non compilato"} />
              <FormBoxField title="Data" value={prefs?.passo_carrabile?.provvedimento.data || "Non compilato"} />
              <FormBoxField title="Indirizzo" value={prefs?.passo_carrabile?.luogo.indirizzo || "Non compilato"} />
              <FormBoxField title="Lunghezza" value={prefs?.passo_carrabile?.dimensioni.lunghezza || "Non compilato"} />
              <FormBoxField title="Profondità" value={prefs?.passo_carrabile?.dimensioni.profondita || "Non compilato"} />
              <FormBoxField title="Superficie" value={prefs?.passo_carrabile?.dimensioni.superficie || "Non compilato"} />
              <FormBoxField title="Foglio" value={prefs?.passo_carrabile?.immobile.foglio || "Non compilato"} />
              <FormBoxField title="Particella" value={prefs?.passo_carrabile?.immobile.particella || "Non compilato"} />
              <FormBoxField title="Subalterno" value={prefs?.passo_carrabile?.immobile.subalterno || "Non compilato"} />
              <FormBoxField title="Categoria" value={prefs?.passo_carrabile?.immobile.categoria || "Non compilato"} />
            </SummaryCard>

            <NextBtnForm
              send={onNext}
              back={props.back}
              saveRequest={save}
              last={true}
              noSave={false}
            />
            {
              props.saved &&
              <SuccessfullPopup />
            }

            {
              props.fail &&
              <SuccessfullPopup isError={true} />
            }
          </div>
        </div>
      </div>
    </div>
  )
}


export default Summary;