import { api, apiAuth } from "./Configs/AxiosConfigs"
import { defineCancelApiObject } from "./Configs/AxiosUtils"

export const PublicCategoryAPI = {

  getCategory: async function (cancel = false) {
    const response = await api.request({
      url: "/ext/categories/",
      method: "GET",
      signal: cancel ? cancelApiObject[this?.getCategory?.name]?.handleRequestCancellation()?.signal : undefined,
    })
    return response.data;
  }

}

// defining the cancel API object for PublicCategoryAPI
const cancelApiObject = defineCancelApiObject(PublicCategoryAPI)