import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideList from "../../../UI-component/SideList";
import ErrorAlert from "../../../UI-component/ErrorAlert";
import CustomerFormBox from "../../../UI-component/registryForms/CustomerFormBox";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import stepSlice from "../../../context/StepsContext";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import Loader from "../../../UI-component/Loader";

const formatSavedDis = (data) => {
  if (data?.length) {
    let newData = [...data]
    for (let key in data) {
      newData[key] = { ...data[key], id: `dis-${key}` }
    }

    return newData;
  }
  return []
}

function General(props) {
  const dispatcher = useDispatch();
  const ctxApplicant = useSelector(state => state.stepSlice.data.richiedente);
  const ctxAcustic = useSelector(state => state.stepSlice.data.disabile);
  const ctxPrefs = useSelector(state => state.stepSlice.data.preferenze);
  const dataCtx = useSelector(state => state.stepSlice.data);
  const isStopped = useSelector(state => state.stepSlice.data.stopped);

  const [applicant, setApplicant] = useState(ctxApplicant?.length ? ((ctxApplicant?.length && !ctxApplicant?.[0]?.["0"] || !ctxApplicant?.length) ? ctxApplicant : [ctxApplicant?.[0]?.["0"]]) : props.info.data?.richiedente) //&& ctxApplicant?.[0]?.["0"] ? [ctxApplicant?.[0]?.["0"]] : (ctxApplicant?.length ? ctxApplicant : [props.info.data?.richiedente]));
  // const [acustic, setAcustic] = useState(formatSavedDis(props.info.data?.disabile || ((ctxAcustic?.length && !ctxAcustic?.[0]?.["0"] || !ctxAcustic?.length) ? ctxAcustic : [ctxAcustic?.[0]?.["0"]]))) //&& ctxAcustic?.[0]?.["0"] ? [ctxAcustic?.[0]?.["0"]] : (ctxAcustic?.length ? ctxAcustic : [props.info.data?.disabile])));

  // console.log(props.info.data?.disabile || ctxAcustic?.length > 0 ? ctxAcustic[0] : []);


  const [acustic, setAcustic] = useState(props.info.data?.disabile || ctxAcustic?.length > 0 ? ctxAcustic : [])

  const [lilErr, setLilErr] = useState(false);
  const [savedDis, setSavedDis] = useState(formatSavedDis(dataCtx?.savedDis?.length > 0 ? dataCtx?.savedDis : props.info?.data?.savedDis || []));

  const [error, setError] = useState({
    acustic: false,
    applicant: false
  });
  const [loading, setLoading] = useState(false);

  const errorChecker = () => {
    let errors = {
      acustic: false,
      applicant: false
    }

    if (!applicant || applicant?.length <= 0)
      errors.applicant = true;
    else
      error.applicant = false;


    if (!acustic || acustic?.length <= 0)
      errors.acustic = true;
    else
      error.acustic = false;

    setError(errors);
  }

  useEffect(() => {
    errorChecker();
  }, [applicant, acustic])

  const update = (data) => {
    if (data.id.toLowerCase() === "disabile") {
      if (typeof data === "object") {
        if (data.removing) {
          setAcustic(undefined);
          return;
        }


        // if(data?.isEdit){
        // }
        if (!data?.change) {
          setSavedDis(prev => {
            const obj = structuredClone(prev);
            let alreadyIn = false;

            for (let itm of obj) {
              if (itm.anagrafica?.codice_fiscale === data.data[0]?.anagrafica?.codice_fiscale && !data.isEdit) {
                alreadyIn = true;
                break;
              }
              else
                alreadyIn = false;
            }

            if (!alreadyIn) {
              let disIndex = prev.findIndex(el => el?.id === data?.data[0]?.id);

              if (disIndex === -1) {
                obj.push({ ...data.data[0], id: `dis-${obj?.length}` });
              }
              else {
                obj[disIndex] = { ...obj[disIndex], ...data?.data[0] };
              }
              setLilErr(false);
            }
            else
              setLilErr(true)




            return [...obj];
          })
        }

        let temp = data.data;
        // console.log("general data data", temp, data);
        temp[0] = {
          ...temp[0],
          selected: true,
          id: data?.data[0]?.id || `dis-${savedDis?.length}`
        }
        // temp[0].selected = true;
        // temp[0].id = data?.data[0]?.id || `dis-${savedDis?.length}`;
        setAcustic(temp);
      }
    } else if (data.id.toLowerCase() === "richiedente") {
      if (typeof data === "object") {
        if (data.removing) {
          setApplicant(undefined);
          return;
        }

        setApplicant(data.data);
      }
    }
  }

  const onNext = () => {
    dispatcher(stepSlice.actions
      .dynamicSave({ id: "Richiedente", data: { ...applicant[0], selected: true } }));
    dispatcher(stepSlice.actions
      .dynamicSave({ id: "Disabile", data: { ...acustic[0], selected: true } }));

    dispatcher(stepSlice.actions.dynamicSave({ id: "SavedDis", data: savedDis }));


    props.next();
  }

  const save = async () => {
    const data = {
      richiedente: applicant?.length > 0 ? [{ ...applicant[0], selected: true }] : [],
      disabile: acustic?.length > 0 ? [{ ...acustic[0], selected: true }] : [],
      savedDis: savedDis,
      preferenze: ctxPrefs
    }

    // console.log("data", data, acustic, ctxAcustic);

    setLoading(true);

    await props.save(data);

    dispatcher(stepSlice.actions
      .dynamicSave({ id: "Richiedente", data: { ...applicant[0], selected: true } }));
    dispatcher(stepSlice.actions
      .dynamicSave({ id: "Disabile", data: { ...acustic?.[0], selected: true } }));
    dispatcher(stepSlice.actions.dynamicSave({ id: "SavedDis", data: savedDis }));

    dispatcher(stepSlice.actions
      .dynamicSave({ id: "Preferenze", internalId: "parcheggio", data: ctxPrefs.parcheggio }));


    setLoading(false);
  }

  // console.log(acustic?.length > 0 ? [acustic] : []);

  return (
    <div className="row">
      <SideList
        infoList={[
          { element: "Richiedente", id: "#report-author" },
          { element: "Disabile", id: "#report-author" },
        ]} />

      <div className="col-12 col-lg-8 offset-lg-1">
        <div className="steppers-content" aria-live="polite">
          <div className="it-page-sections-container">

            {
              <ErrorAlert errors={[{ name: "Richiedente", id: "#isee-info" }]} err={error.applicant || isStopped} />
            }
            {
              <ErrorAlert errors={[{ name: "Disabile", id: "#isee-info" }]} err={error.acustic} />
            }

            <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-55%,-50%)", zIndex: "10000" }}>
              <Loader loading={loading} />
            </div>

            <CustomerFormBox id="richiedente"
              cards={applicant?.length > 0 ? [applicant] : []}
              type={false}
              description="Informazione su di te"
              title="Richiedente"
              service={props.info.code}
              update={update}
              prefData={props.info.data?.richiedente}
            />

            <CustomerFormBox id="disabile"
              cards={acustic?.length > 0 ? [acustic] : []}
              type={false}
              description="Informazioni sulla persona disabile, da compilare anche nel caso in cui il richiedente coincida con la persona disabile"
              title="Disabile"
              service={props.info.code}
              update={update}
              prefData={props.info.data?.disabile}
              savedDis={savedDis}
              lilErr={lilErr}
            />
          </div>
        </div>
        <NextBtnForm
          next={onNext}
          saveRequest={save}
          back={props.back}
          disabled={(error.applicant || error.acustic) && isStopped}
        />
        {
          props.saved &&
          <SuccessfullPopup />
        }

        {
          props.fail &&
          <SuccessfullPopup isError={true} />
        }
      </div>
    </div>
  )
}


export default General;