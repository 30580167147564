import React, { useEffect, useState } from 'react';
import { ServiceRequestAPI } from '../../Apis/ServiceRequestAPI';
import "./css/service.css";
import { getServiceExpireDate } from './utils';
export default function ServiceExpireDate(props) {
    const [expireDate, setExpireDate] = useState(null);
    const [color, setColor] = useState("#ffffff");
    const { serviceId } = props;



    /********************* methods go down here *********************/
    const getService = async () => {
        const date = await getServiceExpireDate(serviceId, null, false);
        if (date) setExpireDate(date);
    }

    const getColor = () => {
        const configuration = JSON.parse(localStorage.getItem("configuration"));
        if (configuration) {
            const color = configuration?.data?.current?.theme?.primary;
            if (color) setColor(color);
        }
    }




    /********************* useEffects go down here *********************/
    useEffect(() => {
        if (serviceId) getService();
        getColor();
    }, [])




    /********************* Render methods go down here *********************/
    const renderLine = () =>
        <div className='line-container'>
            <div className='line' style={{ backgroundColor: color }}><div className='circle'></div></div>
        </div>
    const renderDate = (date, text) => {
        const { year, month, day } = date;
        return (
            <div className='calendar-date' style={{ color }}>
                <h3 className='calendar-date-day'>
                    <small className="calendar-date-day__year">{year}</small>
                    <span className="title-xxlarge-regular">{day}</span>
                    <small className="calendar-date-day__month">{month}</small>
                    {renderLine()}
                </h3>
                <div className='calendar-date-description rounded'>
                    <div className='calendar-date-description-content'>
                        <h4 className="h5 mb-0">{text}</h4>
                    </div>
                </div>
            </div>
        )
    }

    const renderContent = () => {
        return (
            <div className='service-expire-date mb-60'>
                <hr className="d-none d-lg-block mt-40 mb-40"></hr>
                <h2 className="title-xxlarge mb-3">Tempi e scadenze</h2>
                {expireDate && renderDate(expireDate, "Scadenza elaborazione della richiesta")}
            </div>
        )
    }

    return serviceId && expireDate && renderContent()
}
