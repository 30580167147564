import React, { useEffect, useState } from "react";
import AuthenticatedTenant from "../../Layouts/AuthenticatedLayoutTenant";
import WorkPath from "../../UI-component/WorkPath";
import DynamicStepContent from "../../UI-component/DynamicStepContent";
import Privacy from "../_Partials/Privacy";
import { ServiceDetailsAPI } from "../../Apis/ServiceDetailsAPI";
import General from "../_Partials/Tow/General";
import Specific from "../_Partials/Tow/Specific";
import Summary from "../_Partials/Tow/Summary";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import stepSlice from "../../context/StepsContext";

function TowZone(props) {
  const dispatcher = useDispatch();

  const [header, setHeader] = useState("");
  const [user, setUser] = useState("");
  const [testData, setTestData] = useState({});
  const location = useLocation();

  //initialize localStorage conf once when component mounts ?
  useEffect(() => {
    //inizioalize first view first step
    if (localStorage.getItem("configuration")) {
      const config = JSON.parse(localStorage.getItem("configuration"));
      setHeader(config.data.current);
    }
    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      setUser(user);
    }

    if (location.state) {
      dispatcher(stepSlice.actions.onDraftData({ data: location.state.data, saved: false }));
    }

    (async () => {
      try {
        const saved = await ServiceDetailsAPI.getSaved("ppc");

        if (!saved.success) {
          return;
        }

        setTestData(saved.data);
      } catch (err) {
        console.log(err);
      }
    })()
  }, []);

  return (
    <AuthenticatedTenant auth={user} errors={props.errors} header={header}>
      <WorkPath workPathInfo={{
        base: ["Home", header?.siteUrl],
        medium: ["Servizi", header?.siteUrl + "servizi/"],
        end: "Richiesta permesso di passo carrabile"
      }} />

      <div className="container" id="main-container">
        <div className="row justify-content-center" >
          <DynamicStepContent service={props.code}
            successTitle={"Richiesta permesso di passo carrabile"}
            successDesc={`
            Inserisci le informazioni necessarie per pagare il canone per le lampade votive.<br>
            Potrai ricontrollare tutti i dati nel riepilogo, prima di inviare la richiesta.
              `}
            stepList={["INFORMATIVA SULLA PRIVACY", "DATI GENERALI", "DATI SPECIFICI", "RIEPILOGO"]} steps={[
              {
                component: Privacy, info: {
                  description: `Il ${JSON.parse(localStorage.getItem("configuration")).data.current.description}
                  esercita compiti e funzioni e gestisce servizi come indicato negli artt. 13/14 del Testo Unico Enti Locali (D.lgs. 267/2000 e ss integrazioni).
                  In questo contesto procede al trattamento dei dati personali dell’interessato necessari per fornire ogni tipo 
                  di servizio nel rispetto della normativa vigente (codice protezione dati personali e Regolamento europeo 16/679).
                  Per una completa informazione si invita a prendere conoscenza del contenuto della informativa resa ex art. 13
                  Regolamento europeo.`,
                  linkPrivacy: "",
                }
              },
              { component: General, info: { data: testData, code: props.code } },
              { component: Specific, info: { data: testData, code: props.code } },
              { component: Summary, info: { data: testData, code: props.code } },

            ]} />

        </div>
      </div>

    </AuthenticatedTenant>
  );
}

export default TowZone;