import React, { useEffect, useState } from "react";
import { titleExtractor, titleNormalizer, uniqueIdBuilder } from "../util/Dynamics";

function ChoiceFormBox(props) {

  const [testData, setTestData] = useState(props.defaultValue);

  const onChange = (e) => {
    setTestData(e.target.value);
  }

  useEffect(() => {
    props.update({
      id: titleNormalizer(props.title[1]),
      data: testData
    });
  }, [testData])


  const renderer = () => {
    return props.choices.map((choice, idx) => {
      const id = parseInt(uniqueIdBuilder() + idx + 1);
      let normalizedTitle = titleNormalizer(props.title[1]);

      if (testData === choice.value) {
        return (
          <div className="radio-body border-bottom border-light" key={uniqueIdBuilder()}>
            <p style={{ display: "none" }}>{props.title[1]}</p>
            <input
              name={`${normalizedTitle}`}
              type={props.type} id={`${normalizedTitle}-${id}`}
              onChange={onChange}
              value={testData}
              checked />
            <label htmlFor={`${normalizedTitle}-${id}`}>{testData}</label>
          </div>
        )
      } else {
        return (
          <div className="radio-body border-bottom border-light" key={uniqueIdBuilder()}>
            <p style={{ display: "none" }}>{props.title[1]}</p>
            <input
              name={`${normalizedTitle}`}
              type={props.type}
              id={`${normalizedTitle}-${id}`}
              onChange={onChange}
              value={choice?.value} />
            <label htmlFor={`${normalizedTitle}-${id}`}>{choice?.value}</label>
          </div>
        )
      }
    })
  }

  return (
    <section className="it-page-section" id="rate-choice">
      <div className="cmp-card mb-40">
        <div className="card has-bkg-grey shadow-sm p-big">
          <div className="card-header border-0 p-0 mb-lg-30 m-0">
            <div className="d-flex">
              <h2 className="title-xxlarge mb-1">
                {titleExtractor(props.title[1])}
              </h2>
            </div>
            <p className="subtitle-small mb-0 mb-4">{props.description}</p>
          </div>

          <div className="card-body p-0">
            <div className="cmp-card-radio">
              <div className="card card-teaser">
                <div className="card-body">
                  <div className="form-check m-0">
                    {
                      renderer()
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  )
}


export default ChoiceFormBox;











