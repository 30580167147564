import { api } from "./Configs/AxiosConfigs"

export const ImuApi = {

  getImuConfig: async function (type, year) {
    const response = await api.request({
      url: `/category-imu/${type}?year=${year}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      }
    });

    return response.data;
  },

  sendRequest: async function (imuObj) {
    const response = await api.request({
      url: `/sendRequest`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
      data: imuObj
    });
    return response.data;
  },

  downloadPDF: async function (imuObj) {
    const response = await api.request({
      url: `/getDocument`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
      data: imuObj
    });
    return response.data;
  }

}