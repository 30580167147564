import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import stepSlice from "../context/StepsContext";
import { titleExtractor, titleNormalizer, uniqueIdBuilder } from "../util/Dynamics";
import PopupTextField from "./ui-el/PopupTextField";

function MultiTextBox(props) {
  const dispatcher = useDispatch();
  const ctx = useSelector(state => state.stepSlice.data.preferenze);
  const title = Array.isArray(props?.title) ? props.title[1] : props.title;

  const [data, setData] = useState(Array.isArray(props?.title) ?
    { ...ctx[titleNormalizer(props.title[0])][titleNormalizer(title)] } : { ...ctx[titleNormalizer(title)] });

  const dynamicChanges = () => {
    const parentId = titleNormalizer(title);

    if (!data)
      return;

    const dataObj = {
      id: "Preferenze",
      parentId: parentId,
      data: data,
      multi: true,
      multiIn: true
    };

    if (Array.isArray(props.title)) {
      dataObj.topId = titleNormalizer(props.title[0]);
    }

    dispatcher(stepSlice.actions.dynamicSave(dataObj));
  }

  const onBlur = (e, internalTitle) => {
    const internalTitleIn = titleNormalizer(internalTitle);
    setData(prev => {
      prev[internalTitleIn] = e.target.value;

      return prev;
    });

    props.update({
      id: internalTitleIn,
      parentId: titleNormalizer(props.title[1]),
      data: e.target.value
    })
  }

  return (
    <section className="it-page-section" id="rate-choice">
      <div className="cmp-card mb-40">
        <div className="card has-bkg-grey shadow-sm p-big">
          <div className="card-header border-0 p-0 mb-lg-30 m-0">
            <div className="d-flex">
              <h2 className="title-xxlarge mb-1">
                {titleExtractor(title)}
              </h2>
            </div>
            {/* use the first in case of dynamic description */}
            {/* <p className="subtitle-small mb-0 mb-4">{props.description}</p> */}
          </div>
          <div className="card-body p-0">
            <div className="cmp-card-radio">
              <div className="card card-teaser">
                <div className="card-body">
                  <div className="form-check m-0">
                    {
                      props.choices.map((choice, idx) => {
                        const id = parseInt(uniqueIdBuilder() + idx + 1);
                        const normalizedTitle = titleNormalizer(choice?.title).normalize('NFD').replace(/[\u0300-\u036f]/g, '');

                        return (
                          <PopupTextField
                            onBlur={(e) => { onBlur(e, choice?.title) }}
                            defaultValue={data[normalizedTitle]}
                            fieldType="text"
                            id={`${normalizedTitle}-${id}`}
                            fieldTitle={choice?.title}
                            description={props?.description}
                            key={uniqueIdBuilder()}
                          />
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MultiTextBox;











