import React, { useEffect, useState } from "react";


function TabHeader(props) {
  const [active, setIsActive] = useState("");

  const activate = (e) => {
    const tabID = e.target.getAttribute("data-tab-id");
    setIsActive(tabID);
    props.tabSetter(tabID);
  }

  useEffect(() => {
    document.getElementById("Scrivania").click();
  }, [])

  return (
    <div className="col-12 p-0">
      <div className="cmp-nav-tab mb-4 mb-lg-5 mt-lg-4">
        <ul className="nav nav-tabs nav-tabs-icon-text w-100 flex-nowrap" id="myTab" role="tablist">
          {
            props.tabList.map(itm => {
              return (
                <li className="nav-item w-100 me-2 p-1" role="tab" aria-selected="false" tabIndex={-1} key={Math.random() * 31}>
                  <p style={{ cursor: "pointer" }} data-tab-id={itm} onClick={activate} id={itm}
                    className={`nav-link justify-content-start pr-10 pb-2 ps-1 pe-lg-none pb-lg-15 ps-lg-3 me-lg-5 text-tab ${itm === active && "active"}`} >
                    <svg className="icon me-1 mr-lg-10" style={{ pointerEvents: "none" }} >
                      <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-pa" />
                    </svg>
                    {itm}
                  </p>
                </li>
              )
            })
          }
        </ul>
      </div>
    </div>
  )
}


export default TabHeader;