import React from "react";


function WorkPath(props) {

  const siteUrl = JSON.parse(localStorage.getItem("configuration")).data.current.siteUrl;

  const externalBaseServiceSiteCheck = () => {
    if (props.workPathInfo.medium[1].includes("levanto"))
      return "https://comune.levanto.sp.it/servizi";
    else if (props.workPathInfo.medium[1].includes("riccodelgolfo"))
      return "https://www.comune.riccodelgolfo.sp.it/servizi/servizi.html";
    else
      return props.workPathInfo.medium[1];
  }

  const externalServiceSiteCheck = () => {
    if (props.workPathInfo && props.workPathInfo.medium && props.workPathInfo.medium[1]) {
      if (props.workPathInfo.medium[1].includes("levanto")) {
        return "https://comune.levanto.sp.it";
      }
    }
    if (props.workPathInfo && props.workPathInfo.base && props.workPathInfo.base[1]) {
      return props.workPathInfo.base[1].endsWith('/')
        ? props.workPathInfo.base[1].slice(0, -1)
        : props.workPathInfo.base[1];
    }
    return '';
  }


  const externalSiteCheck = () => {
    if (props.workPathInfo?.base?.length > 1 && props.workPathInfo.base[1]) {
      if (props.workPathInfo.base[1].includes("levanto"))
        return "https://comune.levanto.sp.it";
      else if (props.workPathInfo.base[1].includes("riccodelgolfo"))
        return "https://www.comune.riccodelgolfo.sp.it/homepage.html";
      else
        return props.workPathInfo.base[1].endsWith('/') ? props.workPathInfo.base[1].slice(0, -1) : props.workPathInfo.base[1];
    }
    return "";
  }


  const riccoCheck = () => {
    if (Array.isArray(props?.workPathInfo?.serviceName))
      return `${props?.workPathInfo?.medium[1]?.includes("riccodelgolfo") ? "https://www.comune.riccodelgolfo.sp.it/servizi/anagrafe_e_stato_civile/servizio_21.html" : `${externalServiceSiteCheck()}${props.workPathInfo.serviceName[1]}`}`

    return "";
  }

  return (
    <div className="container" id="main-container">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-10">
          <div className="cmp-breadcrumbs " role="navigation">
            <nav className="breadcrumb-container" aria-label="breadcrumb">
              <ol
                className="breadcrumb p-0"
                data-element="breadcrumb"
                itemScope
                itemType="http://schema.org/BreadcrumbList"
              >
                <meta name="numberOfItems" content={3} />
                <meta name="itemListOrder" content="Ascending" />
                <li
                  itemProp="itemListElement"
                  itemScope
                  itemType="http://schema.org/ListItem"
                  className="breadcrumb-item trail-begin"
                >
                  <a
                    href={externalSiteCheck()}
                    rel="home"
                    itemProp="item"
                  >
                    <span itemProp="name">{props.workPathInfo.base[0]}</span>
                  </a>
                  <meta itemProp="position" content={1} />
                </li>

                {
                  props.workPathInfo.medium &&
                  <li
                    itemProp="itemListElement"
                    itemScope
                    itemType="http://schema.org/ListItem"
                    className="breadcrumb-item"
                  >
                    <span className="separator">/</span>
                    <a
                      href={externalBaseServiceSiteCheck()}
                      itemProp="item"
                    >
                      <span itemProp="name">{props.workPathInfo.medium[0]}</span>
                    </a>
                    <meta itemProp="position" content={2} />
                  </li>
                }

                {
                  props.workPathInfo.category &&
                  <>
                    <meta name="itemListOrder" content="Ascending" />
                    <li
                      itemProp="itemListElement"
                      itemScope
                      itemType="http://schema.org/ListItem"
                      className="breadcrumb-item trail-begin"
                    >
                      <span className="separator">/</span>
                      {
                        props.workPathInfo.category[1] === "" ?
                          <span itemProp="name">{props.workPathInfo.category[0]}</span> :
                          <a
                            href={`${props.workPathInfo.medium[1].includes("riccodelgolfo") ? "https://www.comune.riccodelgolfo.sp.it/EG0/EGSCHTST2.HBL?en=eg183&MESSA=PUBBLICA&SRL=6" : ` ${externalServiceSiteCheck()}${props.workPathInfo.category[1]}`}`}
                            rel="home"
                            itemProp="item"
                          >
                            <span itemProp="name">{props.workPathInfo.category[0]}</span>
                          </a>


                      }
                      <meta itemProp="position" content={1} />
                    </li>
                  </>
                }

                {
                  props.workPathInfo.serviceName &&
                  <>
                    <meta name="itemListOrder" content="Ascending" />
                    <li
                      itemProp="itemListElement"
                      itemScope
                      itemType="http://schema.org/ListItem"
                      className="breadcrumb-item trail-begin"
                    >
                      <span className="separator">/</span>
                      {
                        props.workPathInfo.serviceName[1] === "" ?
                          <span itemProp="name">{props.workPathInfo.serviceName[0]}</span> :
                          <a
                            href={riccoCheck()}
                            rel="home"
                            itemProp="item"
                          >
                            <span itemProp="name">{props.workPathInfo.serviceName[0]}</span>
                          </a>


                      }
                      <meta itemProp="position" content={1} />
                    </li>
                  </>
                }

                <li
                  itemProp="itemListElement"
                  itemScope
                  itemType="http://schema.org/ListItem"
                  className="breadcrumb-item active"
                >
                  <span className="separator">/</span>
                  <span itemProp="item">
                    <span itemProp="name">{props.workPathInfo.end}</span>
                  </span>
                  <meta itemProp="position" content={3} />
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}


export default WorkPath;