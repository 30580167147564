import React, { useState, useEffect } from "react";
import SideList from "../../UI-component/SideList";
import SummaryCard from "../../UI-component/summary/SummaryCard";
import PopupTextField from "../../UI-component/ui-el/PopupTextField";
import CustomerFormBox from "../../UI-component/registryForms/CustomerFormBox";
import ErrorAlert from "../../UI-component/ErrorAlert";

import stepSlice from "../../context/StepsContext";
import { useSelector, useDispatch } from "react-redux";

import { onDataChange2, onFileSet2 } from "../../util/Dynamics";
import NextBtnForm from "../../UI-component/NexBtnForm";
import SuccessfullPopup from "../../UI-component/SuccessfullPopup";

function DatiSegnalazione(props) {
  const dispatcher = useDispatch();
  const disserviceCtxData = useSelector(state => state.stepSlice.data.disservizio);
  const authorData = useSelector(state => state.stepSlice.data.autore_della_segnalazione);

  const [authData, setAutData] = useState(/* props.data?.autore_della_segnalazione ??*/ authorData);
  const [loading, setLoading] = useState(false);
  const [isAttachmentOpen, setIsAttachmentOpen] = useState(false);

  const [error, setError] = useState({
    autore: false,
    location: false,
    disservice: false
  });

  const [disserviceData, setDisserviceData] = useState({
    disservizio: {
      cerca_un_luogo: disserviceCtxData?.cerca_un_luogo ?? "",
      tipo_di_disservizio: disserviceCtxData?.tipo_di_disservizio ?? "",
      titolo: disserviceCtxData?.titolo ?? "",
      dettagli: disserviceCtxData?.dettagli ?? "",
      base64_immagine1: disserviceCtxData?.base64_immagine1 ?? "",
      immagine1: disserviceCtxData?.immagine1 ?? "",
      base64_immagine2: disserviceCtxData?.base64_immagine2 ?? "",
      immagine2: disserviceCtxData?.immagine2 ?? "",
      base64_immagine3: disserviceCtxData?.base64_immagine3 ?? "",
      immagine3: disserviceCtxData?.immagine3 ?? "",
      base64_immagine4: disserviceCtxData?.base64_immagine4 ?? "",
      immagine4: disserviceCtxData?.immagine4 ?? "",
      base64_immagine5: disserviceCtxData?.base64_immagine5 ?? "",
      immagine5: disserviceCtxData?.immagine5 ?? "",
      base64_immagine6: disserviceCtxData?.base64_immagine6 ?? "",
      immagine6: disserviceCtxData?.immagine6 ?? "",
      base64_immagine7: disserviceCtxData?.base64_immagine7 ?? "",
      immagine7: disserviceCtxData?.immagine7 ?? "",
      base64_immagine8: disserviceCtxData?.base64_immagine8 ?? "",
      immagine8: disserviceCtxData?.immagine8 ?? "",
      base64_immagine9: disserviceCtxData?.base64_immagine9 ?? "",
      immagine9: disserviceCtxData?.immagine9 ?? "",
      base64_immagine10: disserviceCtxData?.base64_immagine10 ?? "",
      immagine10: disserviceCtxData?.immagine10 ?? ""
    }
  });

  const errorChecker = () => {
    let isError = {
      autore: false,
      location: false,
      disservice: false
    };

    if (authData?.length <= 0)
      isError.autore = true;

    if (disserviceData.disservizio.cerca_un_luogo === "")
      isError.location = true;

    if (!disserviceData.disservizio.tipo_di_disservizio
      || !disserviceData.disservizio.titolo
      || !disserviceData.disservizio.dettagli)
      isError.disservice = true;

    setError(isError);
  }

  useEffect(() => {
    errorChecker();
  }, [disserviceData, authData])


  const update = (data) => {
    if (typeof data === "object") {
      if (data.removing) {
        setAutData(undefined);
        return;
      }

      setAutData(data.data);
    }

    if (Array.isArray(data)) {
      setAutData([]);
    }
  }

  const onNext = () => {
    dispatcher(stepSlice.actions
      .dynamicSave({ id: "Autore della segnalazione", data: authData }));

    dispatcher(stepSlice.actions
      .dynamicSave({ id: "disservizio", data: disserviceData.disservizio }));

    props.next();
  }

  const save = async () => {
    const data = {
      autore_della_segnalazione: authData?.length > 0 ? [{ ...authData[0], selected: true }] : [],
      disservizio: disserviceData.disservizio,
    }

    setLoading(true);

    await props.save(data);

    dispatcher(stepSlice.actions
      .dynamicSave({ id: "Autore della segnalazione", data: data.autore_della_segnalazione }));

    dispatcher(stepSlice.actions
      .dynamicSave({ id: "Disservizio", data: data }));

    setLoading(false);
  }

  const switchAttachmentTab = () => {
    setIsAttachmentOpen(!isAttachmentOpen);
  }

  return (
    <div className="row">
      <SideList
        infoList={[
          { element: "Autore della segnalazione", id: "#report-author" },
          { element: "Luogo", id: "#report-place" },
          { element: "Disservizio", id: "#report-info" },
        ]} />

      <div className="col-12 col-lg-8 offset-lg-1">
        <div className="steppers-content" aria-live="polite">
          <div className="it-page-sections-container">

            {
              <ErrorAlert errors={[{ name: "Autore della segnalazione", id: "#isee-info" }]} err={error.autore} />
            }

            {
              <ErrorAlert errors={[{ name: "Luogo", id: "#isee-info" }]} err={error.location} />
            }

            {
              <ErrorAlert errors={[{ name: "Disservizio", id: "#isee-info" }]} err={error.disservice} />
            }

            <CustomerFormBox id="Autore della segnalazione"
              cards={authData?.length > 0 ? [authData] : []}
              type={false}
              description="Informazione su di te"
              title="Autore della segnalazione"
              service={props.info.code}
              update={update}
              prefData={props.info.data?.autore_della_segnalazione}
            />

            <SummaryCard mod={false} type="Luogo">
              <PopupTextField
                defaultValue={disserviceCtxData?.cerca_un_luogo ? disserviceCtxData?.cerca_un_luogo : ""}
                onChange={(e) => { onDataChange2(e, setDisserviceData) }}
                id="cerca_un_luogo" fieldType="text" fieldTitle="Indirizzo*" description="Inserisci l'indirizzo" />
            </SummaryCard>

            <SummaryCard mod={false} cardTitle="" type="Disservizio">
              <PopupTextField
                defaultValue={disserviceData.disservizio?.tipo_di_disservizio ? disserviceData.disservizio.tipo_di_disservizio : disserviceCtxData?.tipo_di_disservizio}
                id="tipo_di_disservizio"
                onChange={(e) => { onDataChange2(e, setDisserviceData) }}
                fieldType="text"
                fieldTitle="Tipo di disservizio*"
                description="Inserire il tipo di disservizio" />

              <PopupTextField
                defaultValue={disserviceCtxData?.titolo ? disserviceCtxData.titolo : ""}
                id="titolo"
                fieldTitle="Titolo*"
                onChange={(e) => { onDataChange2(e, setDisserviceData) }}
                fieldType="text" description="Inserire il titolo per il disservizio" />
              <PopupTextField
                defaultValue={disserviceCtxData?.dettagli ? disserviceCtxData.dettagli : ""}
                onChange={(e) => { onDataChange2(e, setDisserviceData) }} fieldType="text"
                id="dettagli"
                fieldTitle="Dettagli*"
                description="Inserire al massimo 200 caratteri" />


              <PopupTextField
                onChange={(e) => { onFileSet2(e, setDisserviceData) }}
                fieldType="file"
                id="immagine1"
                defaultValue={disserviceData?.disservizio?.immagine1}
                fieldTitle="Allegato 1" description="Seleziona un'immagine o un file da allegare alla segnalazione" />

              <PopupTextField
                onChange={(e) => { onFileSet2(e, setDisserviceData) }}
                fieldType="file"
                id="immagine2"
                defaultValue={disserviceData?.disservizio?.immagine2}
                fieldTitle="Allegato 2" description="Seleziona un'immagine o un file da allegare alla segnalazione" />

              <PopupTextField
                onChange={(e) => { onFileSet2(e, setDisserviceData) }}
                fieldType="file"
                id="immagine3"
                defaultValue={disserviceData?.disservizio?.immagine3}
                fieldTitle="Allegato 3" description="Seleziona un'immagine o un file da allegare alla segnalazione" />

              <PopupTextField
                onChange={(e) => { onFileSet2(e, setDisserviceData) }}
                fieldType="file"
                id="immagine4"
                defaultValue={disserviceData?.disservizio?.immagine4}
                fieldTitle="Allegato 4" description="Seleziona un'immagine o un file da allegare alla segnalazione" />

              <PopupTextField
                onChange={(e) => { onFileSet2(e, setDisserviceData) }}
                fieldType="file"
                id="immagine5"
                defaultValue={disserviceData?.disservizio?.immagine5}
                fieldTitle="Allegato 5" description="Seleziona un'immagine o un file da allegare alla segnalazione" />

              <PopupTextField
                onChange={(e) => { onFileSet2(e, setDisserviceData) }}
                fieldType="file"
                id="immagine6"
                defaultValue={disserviceData?.disservizio?.immagine6}
                fieldTitle="Allegato 6" description="Seleziona un'immagine o un file da allegare alla segnalazione" />

              <PopupTextField
                onChange={(e) => { onFileSet2(e, setDisserviceData) }}
                fieldType="file"
                id="immagine7"
                defaultValue={disserviceData?.disservizio?.immagine7}
                fieldTitle="Allegato 7" description="Seleziona un'immagine o un file da allegare alla segnalazione" />

              <PopupTextField
                onChange={(e) => { onFileSet2(e, setDisserviceData) }}
                fieldType="file"
                id="immagine8"
                defaultValue={disserviceData?.disservizio?.immagine8}
                fieldTitle="Allegato 8" description="Seleziona un'immagine o un file da allegare alla segnalazione" />

              <PopupTextField
                onChange={(e) => { onFileSet2(e, setDisserviceData) }}
                fieldType="file"
                id="immagine9"
                defaultValue={disserviceData?.disservizio?.immagine9}
                fieldTitle="Allegato 9" description="Seleziona un'immagine o un file da allegare alla segnalazione" />

              <PopupTextField
                onChange={(e) => { onFileSet2(e, setDisserviceData) }}
                fieldType="file"
                id="immagine10"
                defaultValue={disserviceData?.disservizio?.immagine10}
                fieldTitle="Allegato 10" description="Seleziona un'immagine o un file da allegare alla segnalazione" />
            </SummaryCard>
          </div>
        </div>
        <NextBtnForm
          saveRequest={save}
          next={onNext}
          back={props.back}
          noSave={false}
          disabled={error.autore || error.location || error.disservice} />
        {
          props.saved &&
          <SuccessfullPopup />
        }

        {
          props.fail &&
          <SuccessfullPopup isError={true} />
        }

      </div>
    </div>
  )
}


export default DatiSegnalazione;