import React, { useEffect, useState } from "react";
import CustomerCardHeader from "./CustomerCardHeader";
import CustomerCard from "./CustomerCard";
import { AddConsumerPopup } from "../AddingForms/AddConsumerPopup";
import { uniqueIdBuilder } from "../../util/Dynamics";

function FamilyFormBox(props) {
    const [cards, setCards] = useState(props.cards);
    const [show, setShow] = useState(false);
    const [typeData, setTypeData] = useState("");

    const setType = (type) => {
        setTypeData(type);
    }

    const addCustomer = () => {
        setShow(true);
    }

    const close = () => {
        setShow(false);
    }

    const onRemove = (e, data, index) => {

        let idx = null;

        for (let i of cards) {
            if (i) {
                idx = i.findIndex((el) => {
                    return el.anagrafica.codice_fiscale === data.anagrafica.codice_fiscale;
                });

                if (idx)
                    break;
            }
        }


        //TODO: FIX ONLY ONE GOTTA BE REMOVED
        setCards(prev => {
            const obj = structuredClone(prev);
            obj[0].splice(idx, 1);
            prev = obj;

            return [...prev]
        });

        props.update({
            id: props.id,
            data: cards,
            index,
            removing: true
        });
    }


    useEffect(() => {
        setCards(props.cards);
    }, [props.cards]);


    // console.log("cards", cards, cards?.length)
    return (
        <>
            {
                !props.optionalTitle &&
                <section
                    className="it-page-section"
                    id={props.id}>
                    <div className="cmp-card mb-40">
                        {
                            show &&
                            <AddConsumerPopup
                                close={close}
                                id={"nucleo_familiare"}
                                title="Componente nucleo familiare"
                                typeData={typeData}
                                service={"DBE-family"}
                                update={props.update}
                                generic={props?.generic} />
                        }

                        <div className="card has-bkg-grey shadow-sm p-big">
                            <CustomerCardHeader
                                description={props.description}
                                type={props.type}
                                title={props.title}
                                defaultValue={props?.cards?.[0]?.[0]?.anagrafica?.type || props?.prefData?.[0]?.type?.type || props?.tp || typeData}
                                disabled={cards?.length > 0}
                                setType={setType}
                                generic={props?.generic ?? []} />
                            <div className="card-body p-0">
                                
                                {
                                    cards !== undefined &&
                                    cards.map((card, idx) => {
                                        if (card && card?.length > 0) {
                                            // console.log("card", card);
                                            return <CustomerCard
                                                info={card}
                                                key={card[0]?.anagrafica?.codice_fiscale || uniqueIdBuilder()}
                                                id={"nucleo_familiare"}
                                                title="Componente nucleo familiare"
                                                service={"DBE-family"}
                                                onRemove={onRemove}
                                                typeData={typeData}
                                                update={props.update}
                                            />
                                        }
                                    })
                                }


                                {(!cards?.[0] || cards?.[0]?.length < 7) &&
                                    <button type="button" className="btn plus-text mt-20 p-0"
                                        onClick={addCustomer}>
                                        <span className="rounded-icon">
                                            <svg className="icon icon-primary icon-sm" aria-hidden="true">
                                                <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-plus-circle" />
                                            </svg>
                                        </span>
                                        <span>Aggiungi componente nucleo familiare</span>
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
    )
}


export default FamilyFormBox;