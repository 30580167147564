import { api, apiAuth } from "./Configs/AxiosConfigs";
import { defineCancelApiObject } from "./Configs/AxiosUtils";

export const PublicServiceRequestAPI = {
  sendServiceRequest: async function (formDataJSON, cancel = false) {
    const serviceData = formDataJSON.service_data;

    const serviceId = serviceData?.service_id.toLowerCase().trim();

    const formData = new FormData();

    formData.append("service_data", JSON.stringify({ service_id: serviceId.toUpperCase(), status: serviceData.status, data: { ...serviceData.data.data } }));

    if (serviceData.data?.files) {
      for (let i of Object.keys(serviceData.data?.files)) {
        formData.append(`base64_${i}`, serviceData.data.files[i]);
      }
    }

    const response = await api.request({
      url: `ext/service-request-${serviceId}`,
      method: "POST",
      data: formData,
      signal: cancel
        ? cancelApiObject[this?.sendServiceRequest?.name]?.handleRequestCancellation()
          ?.signal
        : undefined,
    });
    return response.data;
  },
  getRequestByUser: async function (cancel = false) {
    const response = await api.request({
      url: "ext/service-request-by-user/",
      method: "GET",
      signal: cancel ? cancelApiObject[this?.getRequestByUser?.name]?.handleRequestCancellation()?.signal : undefined,
    })
    return response.data;
  },
  getSaved: async function (practiceNumber) {
    const response = await api.request({
      url: `ext/service-request-by-number?practice_number=${practiceNumber}`,
      method: "GET",
    })
    return response.data;
  },
  fileUpload: async function (formData) {
    const response = await api.request({
      url: `ext/service-request-psp-upload-file`,
      method: "POST",
      data: formData,
    })
    return response.data;
  }
};

// defining the cancel API object for ServiceRequestAPI
const cancelApiObject = defineCancelApiObject(PublicServiceRequestAPI);
