import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SummaryCard from "../../../UI-component/summary/SummaryCard";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import FormBoxField from "../../../UI-component/ui-el/FormBoxField";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import stepSlice from "../../../context/StepsContext";
import Loader from "../../../UI-component/Loader";

function Summary(props) {
    const dispatcher = useDispatch();
    const ctxApplicant = useSelector(state => state.stepSlice.data.richiedente);
    const ctxApplicant2 = useSelector(state => state.stepSlice.data.richiedente_2);
    const ctxMarriage = useSelector(state => state.stepSlice.data.preferenze.matrimonio);
    const prefs = useSelector(state => state.stepSlice.data.preferenze);

    const [applicant1, setApplicant1] = useState(ctxApplicant);
    const [applicant2, setApplicant2] = useState(ctxApplicant2);
    const [marriage, setMarriage] = useState(ctxMarriage);
    const [loading, setLoading] = useState(false);

    const onNext = async () => {
        const obj = structuredClone(prefs);

        obj.matrimonio = marriage;

        const data = {
            richiedente: [applicant1[0]],
            richiedente_2: [applicant2[0]],
            preferenze: obj,
        }

        dispatcher(stepSlice.actions
            .dynamicSave({ id: "Richiedente", data: { ...data.richiedente, selected: true } }));
        dispatcher(stepSlice.actions
            .dynamicSave({ id: "Richiedente_2", data: { ...data.richiedente_2[0], selected: true } }));

        dispatcher(stepSlice.actions.dynamicSave({
            id: "Preferenze", internalId: "matrimonio", data: { ...marriage }
        }));


        props.send(data);
    }

    const update = (data) => {
        if (data.id === "Richiedente")
            setApplicant1(data.data);
        else if (data.id === "Richiedente 2" || data.id === "Richiedente2")
            setApplicant2(data.data);
        else
            setMarriage(data.data.matrimonio);
    }

    const save = async () => {
        const obj = structuredClone(prefs);

        obj.matrimonio = marriage;

        const data = {
            richiedente: applicant1?.length > 0 ? [{ ...applicant1[0], selected: true }] : [],
            richiedente_2: applicant2?.length > 0 ? [{ ...applicant2[0], selected: true }] : [],
            preferenze: obj
        }

        dispatcher(stepSlice.actions
            .dynamicSave({ id: "Richiedente", data: { ...data.richiedente[0], selected: true } }));
        dispatcher(stepSlice.actions
            .dynamicSave({ id: "Richiedente_2", data: { ...data.richiedente_2[0], selected: true } }));



        setLoading(true);

        await props.save(data);


        dispatcher(stepSlice.actions.dynamicSave({
            id: "Preferenze", internalId: "matrimonio", data: { ...marriage }
        }));


        setLoading(false);
    }


    return (

        <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2">
                <div className="steppers-content" aria-live="polite">
                    <div className="it-page-sections-container">
                        <SummaryCard
                            type="richiedente 1"
                            cardTitle={`${applicant1[0]?.anagrafica?.nome} ${applicant1[0]?.anagrafica.cognome}`}
                            taxID={applicant1[0]?.anagrafica.codice_fiscale}
                            service={props.info.code}
                            registryData={applicant1[0]}
                            update={update}
                            mod={true}
                        >
                            <FormBoxField title="Nome" value={applicant1[0]?.anagrafica.nome} />
                            <FormBoxField title="Cognome" value={applicant1[0]?.anagrafica.cognome} />
                            <FormBoxField title="Codice Fiscale" value={applicant1[0]?.anagrafica.codice_fiscale} />
                            <FormBoxField title="Data di nascita" value={applicant1[0]?.anagrafica.data_di_nascita} />
                            <FormBoxField title="Luogo di nascita" value={applicant1[0]?.anagrafica.luogo_di_nascita} />
                            <FormBoxField title="Stato civile" value={applicant1[0]?.anagrafica.stato_civile} />
                            <FormBoxField title="Con chi" value={applicant1[0]?.anagrafica.con_chi === " " ? "Non presente" : applicant1[0].anagrafica.con_chi} />
                            <FormBoxField title="Comune di registrazione dell'atto" value={applicant1[0]?.anagrafica.comune_di_registrazione_atto === " " ? "Non presente" : applicant1[0].anagrafica.comune_di_registrazione_atto} />
                            <FormBoxField title="Data di registrazione dell'atto" value={applicant1[0]?.anagrafica.data_di_registrazione_atto === " " ? "Non presente" : applicant1[0].anagrafica.data_di_registrazione_atto} />
                            <FormBoxField title="Indirizzo residenza" value={applicant1[0]?.indirizzi.residenza} />
                            <FormBoxField title="Comune residenza" value={applicant1[0]?.indirizzi.domicilio} />
                            <FormBoxField title="Telefono" value={applicant1[0]?.contatti.telefono} />
                            <FormBoxField title="Email" value={applicant1[0]?.contatti.email} />
                        </SummaryCard>
                        <SummaryCard
                            type="richiedente 2"
                            cardTitle={`${applicant2[0]?.anagrafica?.nome} ${applicant2[0]?.anagrafica.cognome}`}
                            taxID={applicant2[0]?.anagrafica.codice_fiscale}
                            service={props.info.code}
                            registryData={applicant2[0]}
                            update={update}
                            mod={true}>
                            <FormBoxField title="Nome" value={applicant2[0]?.anagrafica.nome} />
                            <FormBoxField title="Cognome" value={applicant2[0]?.anagrafica.cognome} />
                            <FormBoxField title="Codice Fiscale" value={applicant2[0]?.anagrafica.codice_fiscale} />
                            <FormBoxField title="Data di nascita" value={applicant2[0]?.anagrafica.data_di_nascita} />
                            <FormBoxField title="Luogo di nascita" value={applicant2[0]?.anagrafica.luogo_di_nascita} />
                            <FormBoxField title="Stato civile" value={applicant2[0]?.anagrafica.stato_civile} />
                            <FormBoxField title="Con chi" value={applicant2[0]?.anagrafica.con_chi === " " ? "Non presente" : applicant2[0].anagrafica.con_chi} />
                            <FormBoxField title="Comune di registrazione dell'atto" value={applicant2[0]?.anagrafica.comune_di_registrazione_atto === " " ? "Non presente" : applicant2[0].anagrafica.comune_di_registrazione_atto} />
                            <FormBoxField title="Data di registrazione dell'atto" value={applicant2[0]?.anagrafica.data_di_registrazione_atto === " " ? "Non presente" : applicant2[0].anagrafica.data_di_registrazione_atto} />
                            <FormBoxField title="Indirizzo residenza" value={applicant2[0]?.indirizzi.residenza} />
                            <FormBoxField title="Comune residenza" value={applicant2[0]?.indirizzi.domicilio} />
                            <FormBoxField title="Telefono" value={applicant2[0]?.contatti.telefono} />
                            <FormBoxField title="Email" value={applicant2[0]?.contatti.email} />
                        </SummaryCard>

                        <SummaryCard
                            type="Preferenze"
                            mod={true}
                            cardTitle={`Preferenze di servizio`}
                            service="RPM"
                            registryData={ctxMarriage}
                            choiceData={[
                                {
                                    "options": [
                                        { id: 1, value: "Civile" },
                                        { id: 2, value: "Concordatario" },
                                        { id: 3, value: "Accatolico" },
                                        { id: 4, value: "Altro" }
                                    ],
                                    "title": [
                                        "MATRIMONIO",
                                        "TIPO_MATRIMONIO"
                                    ],
                                    "type": "Select"
                                },
                                {
                                    "options": [
                                        {
                                            "id": 1,
                                            "title": "m data"
                                        }
                                    ],
                                    "title": [
                                        "MATRIMONIO",
                                        "M DATA"
                                    ],
                                    "child_title": [],
                                    "type": "MultiText"
                                },
                                {
                                    "options": [
                                        {
                                            "id": 1,
                                            "title": "Numero 1"
                                        }
                                    ],
                                    "title": [
                                        "MATRIMONIO",
                                        "NUMERO 1"
                                    ],
                                    "child_title": [],
                                    "type": "MultiText"
                                },
                                {
                                    "options": [
                                        {
                                            "id": 1,
                                            "title": "Data marca 1"
                                        }
                                    ],
                                    "title": [
                                        "MATRIMONIO",
                                        "DATA MARCA 1"
                                    ],
                                    "child_title": [],
                                    "type": "MultiText"
                                },
                                {
                                    "options": [
                                        {
                                            "id": 1,
                                            "title": "Numero 2"
                                        }
                                    ],
                                    "title": [
                                        "MATRIMONIO",
                                        "NUMERO 2"
                                    ],
                                    "child_title": [],
                                    "type": "MultiText"
                                },
                                {
                                    "options": [
                                        {
                                            "id": 1,
                                            "title": "Data marca 2"
                                        }
                                    ],
                                    "title": [
                                        "MATRIMONIO",
                                        "DATA MARCA 2"
                                    ],
                                    "child_title": [],
                                    "type": "MultiText"
                                },
                            ]}
                            update={update}
                        >

                            <FormBoxField title="Tipo di matrimonio" value={marriage?.tipo_matrimonio || "Non compilato"} />
                            <FormBoxField title="Data del matrimonio (presumibile)" value={marriage?.m_data || "Non compilato"} />
                            <FormBoxField title="Numero 1" value={marriage?.numero_1 || "Non compilato"} />
                            <FormBoxField title="Data 1" value={marriage?.data_marca_1 || "Non compilato"} />
                            <FormBoxField title="Numero 2" value={marriage?.numero_2 || "Non compilato"} />
                            <FormBoxField title="Data 2" value={marriage?.data_marca_2 || "Non compilato"} />

                        </SummaryCard>

                        {/* Loader per indicare il caricamento */}
                        <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-55%,-50%)", zIndex: "10000" }}>
                            <Loader loading={loading} />
                        </div>
                        <NextBtnForm
                            next={onNext}
                            send={onNext}
                            back={props.back}
                            noSave={false}
                            saveRequest={save}
                            last={true}
                        />
                        {
                            props.saved &&
                            <SuccessfullPopup />
                        }

                        {
                            props.fail &&
                            <SuccessfullPopup isError={true} />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Summary;