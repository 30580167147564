import React, { useEffect, useState } from "react";
import SideList from "../../UI-component/SideList";
import SummaryCard from "../../UI-component/summary/SummaryCard";
import PopupTextField from "../../UI-component/ui-el/PopupTextField";
import { onDirectDataChange } from "../../util/Dynamics";
import { useDispatch, useSelector } from "react-redux";
import stepSlice from "../../context/StepsContext";
import ErrorAlert from "../../UI-component/ErrorAlert";
import NextBtnForm from "../../UI-component/NexBtnForm";
import SuccessfullPopup from "../../UI-component/SuccessfullPopup";
import Loader from "../../UI-component/Loader";

//OFFICE SELECTOR
function Fourth(props) {
    const dispatcher = useDispatch();
    const ctx = useSelector(state => state.stepSlice.data.ufficio);

    let user = JSON.parse(JSON.parse(localStorage.getItem("user")).data);

    const prefData = Array.isArray(props.info.data?.richiedente) ? props.info.data?.richiedente[0] : false;

    // console.log("///////////////// fourth prefData", prefData, ctx?.richiedente);
    const [applicant, setApplicant] = useState(
        // ctx?.richiedente ? (ctx?.richiedente?.length ? ctx.richiedente[0] : (ctx?.richiedente?.["0"] || ctx?.richiedente))
        // :
        {
            nome: prefData?.anagrafica?.nome || user.name,
            cognome: prefData?.anagrafica?.cognome || user.familyName,
            codice_fiscale: prefData?.anagrafica?.codice_fiscale || user?.fiscalNumber,
            email: ctx.richiedente?.email || prefData?.contatti?.email || user?.email,
            telefono: ctx.richiedente?.telefono || prefData?.contatti?.telefono || user?.mobilePhone
        }
    );

    const [loading, setLoading] = useState(false);

    const [error, setError] = useState({
        email: false,
        codice_fiscale: false,
        telefono: false,
        all: false
    });


    const update = (data) => {
        //TODO
    }

    const onNext = () => {
        dispatcher(stepSlice.actions.dynamicSave({ id: "Ufficio", internalId: "richiedente", data: applicant }));

        props.next();
    }

    const save = async () => {

        const data = {
            ufficio: ctx.ufficio,
            appuntamenti_disponibili: ctx.appuntamenti_disponibili,
            disponibilita: ctx.disponibilita,
            motivo: ctx.motivo,
            dettagli: ctx.dettagli,
            richiedente: applicant
        }

        setLoading(true);

        await props.save(data);

        dispatcher(stepSlice.actions.dynamicSave({ id: "Ufficio", internalId: "richiedente", data: applicant }));

        setLoading(false);
    }

    const errorChecker = () => {
        let err = false;

        const reg = new RegExp(`[\\w\\D]+.[\\w\\D]+@[\\w\\D]{3,20}[.][a-zA-Z\\D]{2,10}`);

        if (reg.test(applicant?.email)) {
            setError(prev => {
                return { ...prev, email: false }
            });
        }
        else {
            setError(prev => {
                return { ...prev, email: applicant?.email === "" ? false : true }
            });
            err = true;
        }

        const regCF = new RegExp("^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]{1}$");

        if (regCF.test(applicant?.codice_fiscale)) {
            setError(prev => {
                return { ...prev, codice_fiscale: false }
            });
        }
        else {
            setError(prev => {
                return { ...prev, codice_fiscale: applicant?.codice_fiscale === "" ? false : true }
            });
            err = true;
        }

        const regTel = new RegExp("[0-9]{8}");

        if (regTel.test(applicant?.telefono)) {
            setError(prev => {
                return { ...prev, telefono: false }
            });
        }
        else {
            setError(prev => {
                return { ...prev, telefono: applicant?.telefono === "" ? false : true }
            });
            err = true;
        }



        setError((prev) => {
            return { ...prev, all: err }
        })
    }

    useEffect(() => {
        errorChecker();
    }, [applicant])

    // console.log("////////////////// applicant", applicant, error, ctx?.richiedente);

    return (
        <React.Fragment>
            <SideList infoList={[{ element: "Richiedente", id: "#applicant" }]} />
            <section className="col-12 col-lg-8 offset-lg-1 section-wrapper">
                {
                    <ErrorAlert errors={[{ name: "Richiedente", id: "#isee-info" }]} err={error.all} />
                }
                <Loader loading={loading} />

                <SummaryCard mod={false} type="Richiedente">
                    <PopupTextField
                        defaultValue={applicant?.nome}
                        onChange={(e) => { onDirectDataChange(e, setApplicant) }}
                        fieldType="text"
                        id="nome"
                        fieldTitle="Nome*"
                        description="Inserisci il nome"
                        disabled={true}
                    />
                    <PopupTextField
                        defaultValue={applicant?.cognome}
                        onChange={(e) => { onDirectDataChange(e, setApplicant) }}
                        fieldType="text"
                        id="cognome"
                        fieldTitle="Cognome*"
                        description="Inserisci il cognome"
                        disabled={true}
                    />
                    <PopupTextField
                        defaultValue={applicant?.codice_fiscale}
                        onChange={(e) => { onDirectDataChange(e, setApplicant) }}
                        fieldType="text"
                        id="codice_fiscale"
                        fieldTitle="Codice Fiscale*"
                        description="Inserisci il codice fiscale"
                        straightErr={error.codice_fiscale}
                        disabled={true} />

                    <PopupTextField
                        defaultValue={applicant?.telefono}
                        onChange={(e) => { onDirectDataChange(e, setApplicant) }}
                        fieldType="text"
                        id="telefono"
                        fieldTitle="Telefono*"
                        description="Inserisci il numero di telefono"
                        straightErr={error.telefono} />

                    <PopupTextField
                        defaultValue={applicant?.email}
                        onChange={(e) => { onDirectDataChange(e, setApplicant) }}
                        fieldType="text"
                        id="email"
                        fieldTitle="Email*"
                        description="Inserisci la mail"
                        straightErr={error.email} />

                </SummaryCard>
                <NextBtnForm
                    saveRequest={save}
                    next={onNext}
                    back={props.back}
                    noSave={false}
                    disabled={error.all} />
                {
                    props.saved &&
                    <SuccessfullPopup />
                }

                {
                    props.fail &&
                    <SuccessfullPopup isError={true} />
                }
            </section>
        </React.Fragment>
    )
}

export default Fourth;
