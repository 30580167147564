import { api } from "./Configs/AxiosConfigs";
import { defineCancelApiObject } from "./Configs/AxiosUtils";

// Funzione di utilità per aggiornare il link
function updateLink(selector, newUrl) {
  const linkElement = document.querySelector(selector);
  if (linkElement) {
    linkElement.href = newUrl;
  }
}

// Funzione per osservare i cambiamenti nel DOM
function observeLink(selector, newUrl) {
  const targetNode = document.querySelector('body');
  const observerConfig = { childList: true, subtree: true };

  const observer = new MutationObserver(() => {
    const linkElement = document.querySelector(selector);
    if (linkElement) {
      linkElement.href = newUrl;
      observer.disconnect(); // Scollega l'osservatore una volta trovato l'elemento
    }
  });

  observer.observe(targetNode, observerConfig);
}

export const ConfigurationAPI = {
  getConfiguration: async function (cancel = false) {
    const frontendUrl = window.location.href;
    const response = await api.request({
      url: "/configuration/",
      method: "GET",
      headers: {
        'X-Frontend-URL': frontendUrl,
        'X-Token': localStorage.getItem('access_token')
      },
      signal: cancel ? cancelApiObject[this.getConfiguration.name].handleRequestCancellation().signal : undefined,
    });

    const data = response.data;
    if (data.success) {
      // Aggiorna il DOM con il nuovo url "Prenota appuntamento" usando la funzione di utilità
      // updateLink("#menu-info-1 > li:nth-child(2) > a", data.data.newUrl);
      // updateLink("#root > div > div:nth-child(4) > div > div.bg-grey-card > div > div > div > div > div > div > ul:nth-child(2) > li:nth-child(4) > a", data?.data?.newUrl);
      // updateLink("#root > div > div:nth-child(4) > div > div.bg-grey-card > div > div > div > div > div > div > ul:nth-child(2) > li:nth-child(3) > a", data?.data?.newUrl);

      // Usa MutationObserver per aggiornare dinamicamente gli altri link
      // observeLink("#root > div > div:nth-child(4) > div > div.bg-grey-card > div > div > div > div > div > div > ul:nth-child(2) > li:nth-child(4) > a", data.data.newUrl);
      // observeLink("#root > div > div:nth-child(4) > div > div.bg-grey-card > div > div > div > div > div > div > ul:nth-child(2) > li:nth-child(3) > a", data.data.newUrl);
    }

    return data;
  },
};

const cancelApiObject = defineCancelApiObject(ConfigurationAPI);

export default ConfigurationAPI;
