import React from "react";


function TotalPaymentInfo(props) {
    return (
        <div className="cmp-tot bg-white mt-4 pt-25 pb-25 pl-25 pr-25 pt-lg-40 pb-lg-40 pl-lg-40 pr-lg-40">
            <div className="tot-wrapper border-bottom border-light d-lg-flex justify-content-between">
                <p className="title-tot mb-0">Totale da versare</p>
                <span className="value-tot">{props.totalToBePaid}</span>
            </div>
            {/* <div className="form-group cmp-input mb-3">
            <label className="cmp-input__label" htmlFor="tot-payed-2"></label>
                <input type="text" className="form-control" id="tot-payed-2" name="tot-payed-2" required defaultValue={props.totalToBePaid ? props.totalToBePaid : "Inserisci importo*"} />
                <div className="d-flex">
                    <span className="form-text cmp-input__text">
                    Inserisci l’importo che desideri versare adesso</span>
                </div>
            </div> */}
            {/* <div className="wrapper d-flex justify-content-between border-bottom border-light subtitle-small">
            <p>Rimanente daversare </p>
            <span>{props.toBePaid}</span>
            </div> */}
        </div>
    )
}

export default TotalPaymentInfo;