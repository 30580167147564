import React, { useEffect, useState } from "react";
import CustomerFormBox from "../../UI-component/registryForms/CustomerFormBox";
import FormSlider from "../../UI-component/ui-el/FormSlider";
import CustomerPropertyFormBox from "../../UI-component/registryForms/CustomerPropertyFormBox";
import SideList from "../../UI-component/SideList";
import { useSelector, useDispatch } from "react-redux";
import ErrorAlert from "../../UI-component/ErrorAlert";
import NextBtnForm from "../../UI-component/NexBtnForm";
import stepSlice from "../../context/StepsContext";
import SuccessfullPopup from "../../UI-component/SuccessfullPopup";
import PopupSelectField from "../../UI-component/ui-el/PopupSelectField";
import { uniqueIdBuilder } from "../../util/Dynamics";
import SummaryCard from "../../UI-component/summary/SummaryCard";
import { ImuApi } from "../../Apis/ImuApi";

export default function PagamentiIMUDatiPersonali(props) {
  const dispatcher = useDispatch();
  const ctx = useSelector(state => state.stepSlice.data.test_contribuente);
  const taxPayerCtx = useSelector(state => state.stepSlice.data.imu.contribuente);
  const realtyCtx = useSelector(state => state.stepSlice.data.imu.immobili);
  const ctxCoobData = useSelector(state => state.stepSlice.data.imu.coobbligato);
  const isStopped = useSelector(state => state.stepSlice.data.stopped);


  const [testTaxData, setTestTaxData] = useState(taxPayerCtx.length > 0 ? taxPayerCtx : []);
  const [testRealtyData, setTestRealtyData] = useState(realtyCtx);
  const [testCoobData, setTestCoobData] = useState(ctxCoobData);
  const [year, setYear] = useState(Array.isArray(realtyCtx) ? realtyCtx[0]?.dati_immobile.anno || '' : "");


  // console.log("/////////// pagamenti imu", realtyCtx, Array.isArray(realtyCtx))
  const [isCoobIn, setIsCoobIn] = useState(true);

  const [error, setError] = useState({
    applicant: true,
    property: true
  });

  const errorChecker = () => {
    let err = {
      applicant: false,
      property: false
    };

    if (testTaxData?.length <= 0)
      err.applicant = true;

    if (testRealtyData?.length <= 0)
      err.property = true;

    setError(err);
  }

  useEffect(() => {
    errorChecker();
  }, [testTaxData, testRealtyData, testCoobData])


  const update = (data) => {
    const id = data.id.toLowerCase();

    // console.log("update", data, id);
    switch (id) {
      case "contribuente":
        if (data?.removing) {
          setTestTaxData([]);
          return;
        }
        const obj = { ...data.data[0], selected: true }
        setTestTaxData([obj]);
        break;
      case "immobili":
        setTestRealtyData(prev => {
          const obj = structuredClone(prev);

          if (data.removing)
            obj.splice(data.data, 1);
          else {
            if (data?.edit) {
              const index = obj.findIndex(el => {
                return el.id === data.data.id;
              });

              obj[index] = data.data;
            } else
              obj.push(data.data);
          }

          prev = obj;

          return [...prev];
        });
        break;
      case "coobbligato":
        setTestCoobData(data.data.coobbligato);
        break;
      default:
        break;
    }
  }

  const onNext = () => {
    dispatcher(stepSlice.actions.dynamicSave({ id: "Contribuente", data: testTaxData }));
    dispatcher(stepSlice.actions.dynamicSave({ id: "Immobili", data: testRealtyData }));
    dispatcher(stepSlice.actions.dynamicSave({ id: "Coobbligato", data: isCoobIn ? testCoobData : {} }));

    props.next();
  }

  const save = () => {
    dispatcher(stepSlice.actions.dynamicSave({ id: "Contribuente", data: testTaxData }));
    dispatcher(stepSlice.actions.dynamicSave({ id: "Immobili", data: testRealtyData }));
    dispatcher(stepSlice.actions.dynamicSave({ id: "Coobbligato", data: isCoobIn ? testCoobData : {} }));

    const data = {
      imu: {
        contribuente: testTaxData,
        coobbligato: isCoobIn ? testCoobData : {},
        immobili: testRealtyData
      }
    }

    props.save(data);
  }

  const onChangeYear = (e) => {
    // console.log("onChangeYear", e.target.options[e.target.selectedIndex].text)
    setYear(e.target.options[e.target.selectedIndex].text);
  }

  const getData = async (type, year) => {
    // console.log("get imu type", type, year);
    try {
      const res = await ImuApi.getImuConfig(type, year);
      if (!res.success)
        throw new Error(res.message);

      setTestRealtyData(prev => {
        let obj = structuredClone(prev);
        if (Array.isArray(prev)) {

          obj.forEach((itm) => {
            itm.dati_comunali.aliquota_acconto = res.data?.aliquota_acconto !== 0 ? res.data?.aliquota_acconto : "Esente IMU";
            itm.dati_comunali.aliquota_saldo = res.data?.aliquota_saldo !== 0 ? res.data?.aliquota_saldo : "Esente IMU";
            itm.dati_immobile.anno = year;
          });
        }
        return [...obj];
      });
    } catch (err) {
      console.log(err);
    }
  }

  const settingCobIn = (e) => {
    setIsCoobIn(e)
  }

  useEffect(() => {
    // console.log("enter hereeeee", year, Array.isArray(testRealtyData), testRealtyData)
    if (Array.isArray(testRealtyData)) {
      for (let i of testRealtyData) {
        getData(i.dati_immobile?.tipologia_immobile.split(" -")[0], year);
      }
    }
  }, [year])

  useEffect(() => {
    if(!testRealtyData?.length) setTestRealtyData(realtyCtx);
  }, [realtyCtx])

  useEffect(() => {

    return () => {
      // dispatcher(stepSlice.actions.dynamicSave({ id: "Contribuente", data: [] }));
    }
  }, [])

  return (
    <div className="row">
      <SideList
        infoList={[
          { element: "Contribuente", id: "#taxpayer" },
          { element: "Coobligato", id: "#co-obliged" },
          { element: "Anno di riferimento", id: "#co-obliged" },
          { element: "Immobili", id: "#properties" },
        ]}
      />
      <div className="col-12 col-lg-8 offset-lg-1">
        <div className="it-page-sections-container">
          {
            <ErrorAlert errors={[{ name: "Contribuente", id: "#isee-info" }]} err={error.applicant || isStopped} />
          }

          {
            <ErrorAlert errors={[{ name: "Immobili", id: "#isee-info" }]} err={error.property} />
          }

          <CustomerFormBox
            id="contribuente"
            cards={testTaxData?.length > 0 ? [testTaxData] : testTaxData}
            type={true}
            description="Seleziona o aggiungi il contribuente che pagherà l’imposta"
            title="Contribuente"
            service={props.info.code}
            generic={ctx}
            prefData={taxPayerCtx}
            update={update}
          />

          <FormSlider
            id="co-obliged"
            setIsCoobIn={settingCobIn}
            update={update} />

          <SummaryCard type="Anno di riferimento">
            <PopupSelectField
              defaultValue={year}
              onChange={onChangeYear}
              id={"anno"}
              fieldTitle={"Anno"}
              options={JSON.parse(localStorage.getItem("configuration")).data?.current?.imuYears?.split(",") || []}
              description="Seleziona l'anno di riferimento"
              key={uniqueIdBuilder()} />
          </SummaryCard>

          <CustomerPropertyFormBox
            year={year}
            id="properties"
            cards={testRealtyData?.length > 0 ? [testRealtyData] : testRealtyData}
            update={update}
          />
        </div>
        <NextBtnForm
          saveRequest={save}
          next={onNext}
          back={props.back}
          disabled={error.applicant || error.property || isStopped} />

        {
          props.saved &&
          <SuccessfullPopup />
        }

        {
          props.fail &&
          <SuccessfullPopup isError={true} />
        }
      </div>
    </div>
  );
}
