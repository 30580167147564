import { useEffect, useState } from "react";

import WorkPath from "../../UI-component/WorkPath";
import DynamicStepContent from "../../UI-component/DynamicStepContent";
import Privacy from "../_Partials/Privacy";
import { ServiceDetailsAPI } from "../../Apis/ServiceDetailsAPI";
import Guest from "../../Layouts/GuestLayout";
import General from "../_Partials/publics/disservice/General";
import Summary from "../_Partials/publics/disservice/Summary";

export default function PublicSegnalazioneDisservizio(props) {
  const [header, setHeader] = useState("");
  const [user, setUser] = useState("");
  const [testData, setTestData] = useState({});
  const pathname = window.location.pathname.replace("ext-", "");
  const searchPath = window.location.search;
  const home = JSON.parse(localStorage.getItem("configuration")).data.current.siteUrl;


  //initialize localStorage conf once when component mounts ?
  useEffect(() => {
    if (localStorage.getItem("access_token"))
      window.location.href = pathname + searchPath;

    localStorage.setItem("destination", pathname);

    //inizioalize first view first step
    if (localStorage.getItem("configuration")) {
      const config = JSON.parse(localStorage.getItem("configuration"));
      setHeader(config.data.current);
    }
    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      setUser(user);
    }

    (async () => {
      try {
        const saved = await ServiceDetailsAPI.getSaved("ssd");

        if (!saved.success) {
          return;
        }

        setTestData(saved.data);
      } catch (err) {
        console.log(err);
      }
    })()
  }, []);

  return (
    <Guest auth={user} errors={props.errors} header={header}>
      <WorkPath workPathInfo={{
        base: ["Home", header?.siteUrl],
        medium: ["Servizi", home + "servizi/"],
        category: "",
        serviceName: ["Segnalazione disservizio", "/servizio/segnalazione-disservizio/"],
        end: "Servizio digitale",
      }} />

      <div className="container" id="main-container">
        <div className="row justify-content-center" >
          <DynamicStepContent service={props.code}
            successTitle={"Segnalazione disservizio"}
            successDesc={`
              I campi contraddistinti dal simbolo asterisco sono obbligatori.
              `}
            stepList={["INFORMATIVA SULLA PRIVACY", "DATI DI SEGNALAZIONE", "RIEPILOGO"]} steps={[
              {
                component: Privacy, info: {
                  description: `Il ${JSON.parse(localStorage.getItem("configuration")).data.current.description}
                  esercita compiti e funzioni e gestisce servizi come indicato negli artt. 13/14 del Testo Unico Enti Locali (D.lgs. 267/2000 e ss integrazioni).
                  In questo contesto procede al trattamento dei dati personali dell’interessato necessari per fornire ogni tipo 
                  di servizio nel rispetto della normativa vigente (codice protezione dati personali e Regolamento europeo 16/679).
                  Per una completa informazione si invita a prendere conoscenza del contenuto della informativa resa ex art. 13
                  Regolamento europeo.`,
                  linkPrivacy: "",
                }
              },
              { component: General, info: { data: testData, code: props.code } },
              { component: Summary, info: { code: props.code } },
            ]} />

        </div>
      </div>

    </Guest>
  );
}


