import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import stepSlice from "../../../context/StepsContext";

export const useBonus = (parentSaveFunc, config, parentSendFunc) => {
    /********************* hooks go down here *********************/
    const ctxApplicant = useSelector(state => state.stepSlice.data.bonus.richiedente);
    const ctxIsee = useSelector(state => state.stepSlice.data.bonus.isee);
    const ctxCredits = useSelector(state => state.stepSlice.data.bonus.credito);
    const ctxFamily = useSelector(state => state.stepSlice.data.bonus.nucleo_familiare);
    const ctxDichiaro = useSelector(state => state.stepSlice.data.bonus.dichiaro);
    const ctxConsapevole = useSelector(state => state.stepSlice.data.bonus.consapevole);
    const ctxPrefs = useSelector(state => state.stepSlice.data.preferenze);
    const isStopped = useSelector(state => state.stepSlice.data.stopped);
    const ctx = useSelector(state => state.stepSlice.data);
    const dispatcher = useDispatch();
    /********************* state go down here *********************/
    const [data, setData] = useState({
        tipologia: ctxPrefs?.bonus?.tipologia,
        richiedente: ctxApplicant?.length > 0 ? [{ ...ctxApplicant[0], selected: true }] : [],
        credito: ctxCredits || {
            intestatario: config?.credito?.intestatario || "",
            banca: config?.credito?.banca || "",
            filiale: config?.credito?.filiale || "",
            indirizzo: config?.credito?.indirizzo || "",
            codice_iban: config?.credito?.codice_iban || "",
            iban: config?.credito?.iban || "",
            base64_iban: config?.credito?.base64_iban || "",
        },
        isee: ctxIsee?.length > 0 && ctxIsee?.[0] !== null ? ctxIsee : [],
        nucleo_familiare: ctxFamily || [],
        dichiaro: ctxDichiaro || [],
        consapevole: ctxConsapevole || [],
    });
    const [values, setValues] = useState({
        bonuses: (config?.data?.bonus || config?.bonus || [])?.map(bonus => ({id: bonus?.id || null, value: bonus.title || ""})) || []
    })
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    /********************* methods go down here *********************/
    ////////////////////// get functions //////////////////////
    const getBonusId = () => values?.bonuses?.find(bonus => bonus.value === data.tipologia)?.id || null

    const getBonusFields = () => {
        const bonusId = getBonusId();

        switch(bonusId){
            case "BA3":
                return ["richiedente", "isee", "nucleo_familiare", "dichiaro", "consapevole", "preferenze"]
            default:
                return ["richiedente", "isee", "credito", "preferenze"]
        }
    }

    ////////////////////// validate functions //////////////////////
    const isValidField = (id) => {
        switch(id){
            case "richiedente":
            case "isee":
            case "nucleo_familiare":
                return data[id]?.length >= 0 ? true : false;
            case "credito":
                // const credit = data?.[id] || {}
                const credit = {
                    intestatario: "",
                    banca: "",
                    filiale: "",
                    indirizzo: "",
                    codice_iban: "",
                    iban: "",
                    base64_iban: "",
                    ...data?.[id]
                }
                let errByKey = {}
                // console.log("Object.keys(credit)", Object.keys(credit))
                for (let i of Object.keys(credit)) {
                    let err = false;
                    if (credit[i] === "" || !credit[i]) {
                      err = true;
                    }
                    else if (i === "codice_iban") {
                        const regex = RegExp("^IT\\d{2}[A-Za-z]\\d{10}[0-9A-Za-z]{12}$");
                        err = !regex.test(credit[i]);
                    }
                    if(err) errByKey[i] = err;
                }
                return Object.keys(errByKey)?.length > 0 ? errByKey : true;
            case "preferenze":
                return data?.tipologia ? true : false
            case "dichiaro":
                return data?.dichiaro?.length === 2
            case "consapevole":
                return data?.consapevole?.length === 2
            default:
                return data[id] ? true : false
        }
    }

    const checkFields = (fields) => {
        // console.log("checkFields", fields)
        if(fields?.length > 0){
            let isValid = true;
            for(let key in fields){
                isValid = typeof isValidField(fields[key]) !== "object" && isValidField(fields[key]) === true;
                // console.log("field", fields[key], isValid)
                if(!isValid) break;
            }
            return isValid;
        }

        return false;
    }

    const isValidData = (isSendRequest) => {
        const bonusId = getBonusId();
        let requiredFields = [];
        // console.log("is valid data", isSendRequest)
        switch(bonusId){
            // case "BA3":
            //     break;
            default:
                if(isSendRequest) requiredFields = getBonusFields()
                else requiredFields = []
        }

        if(requiredFields?.length > 0) return checkFields(requiredFields)        

        return true;
    }

    const checkErrors = () => {
        let newError = {...error}

        const fields = getBonusFields() || []
        fields.map(field => {
            let isValid = isValidField(field)
            newError[field] = typeof isValid === "object" ? isValid : !isValid
        })
        
        setError(newError);
    }

    const isValidStep = (stepId) => {
        switch(stepId){
            case "general":
                return (error?.preferenze) ? false : true
            case "specific":
            case "summary":
                return (error?.richiedente 
                    || (typeof error.credito === "object" ? Object.keys(error.credito)?.length > 0 : error.credito) 
                    || error.isee 
                    || error.dichiaro || error.consapevole 
                    || isStopped) ? false : true
            default:
                return false;
        }
    }
    ////////////////////// UPDATE //////////////////////
    const parseField = (id, newData) => {
        switch(id){
            case "richiedente":
                return newData?.removing ? [] : [{ ...newData?.data?.[0], selected: true }]
            case "nucleo_familiare":
                // console.log("nucleo_familiare", data, newData)
                if(!newData?.removing && !newData?.isEdit){
                    return [
                        ...(data[id] || []),
                        {...newData?.data?.[0]}
                    ]
                }
                else if(newData?.removing && newData?.index !== undefined){
                    let newArrayData = []
                    data[id]?.map((member, idx) => {
                        if(idx !== newData?.index)
                            newArrayData.push(member)
                    })
                    // console.log("newArrayData", newArrayData)
                    return newArrayData
                }
                else if(newData?.isEdit){
                    let newArrayData = [...(data[id] || [])]
                    if(newArrayData[newData?.index])
                        newArrayData[newData?.index] = newData?.data?.[0]

                    return newArrayData
                }
                // return newData?.removing ? [] : [{ ...newData?.data?.[0], selected: true }]
                break;  
            default:
                return newData?.data || null
        }
    }

    const update = (newData) => {
        // console.log("useBonus update", newData, typeof newData === "object", Array.isArray(newData));
        if(typeof newData === "object" && !Array.isArray(newData)){
            const id = newData?.id?.toLowerCase();
            const internalId = newData?.internalId || null;
            if(id){
                if(internalId){
                    setData(oldData => ({
                        ...oldData,
                        [id]: {
                            ...(data?.[id] || {}),
                            [internalId]: newData?.data || null
                        }
                    }))
                }
                else{
                    setData(oldData => ({
                        ...oldData,
                        [id]: parseField(id, newData)
                    }))
                }
            }
        }
        else if (Array.isArray(newData)){
            let updatedData = {...data};
            newData.map(newDataItem => {
                const id = newDataItem?.id?.toLowerCase();
                const internalId = newDataItem?.internalId || null;
                if(id){
                    if(internalId){
                        updatedData = {
                            ...updatedData,
                            [id]: {
                                ...(updatedData?.[id] || {}),
                                [internalId]: newDataItem?.data || null
                            }
                        }
                    }
                    else{
                        updatedData = {
                            ...updatedData,
                            [id]: parseField(id, newDataItem)
                        }
                    }
                }
            })
            // console.log("updatedData", updatedData)
            setData(updatedData);
        }
    }

    ////////////////////// SAVE //////////////////////
    const savePrefs = () => {
        dispatcher(stepSlice.actions.dynamicSave({ id: "Preferenze", internalId: "bonus", data: {
            tipologia: data?.tipologia
        } }));
    }

    const save = async (isSendRequest) => {
        const fields = getBonusFields() || []
        let saveData = {}
        fields.map(field => {
            let isValid = typeof isValidField(field) !== "object" && isValidField(field) === true;
            // console.log("is valid data", field, isValid)
            if(data[field] && isValid) saveData[field] = data[field];
        })

        if(data?.tipologia){
            saveData.preferenze = {
                bonus: {tipologia: data.tipologia},
                // dichiaro: data?.dichiaro || [],
                // consapevole: data?.consapevole || [],
            }
        }

        // console.log("fields", saveData)
        // console.log("save data", saveData)
        let isSend = typeof isSendRequest === "boolean" ? isSendRequest : false;
        if(isValidData(isSend) && ((parentSaveFunc && !isSend) || (parentSendFunc && isSend))){
            dispatcher(stepSlice.actions.dynamicSave({ id: "Bonus", data: {...saveData} }));
            savePrefs();
            setLoading(true);
            if(isSend){
                await parentSendFunc(saveData);
            }
            else{
                await parentSaveFunc(saveData);
            }
            setLoading(false);
        }
    }

    const clear = () => {
        setData({})
    }

    /********************* useEffects go down here *********************/
    useEffect(() => {
        if((config?.data?.bonus || config?.bonus) && values?.bonuses?.length === 0){
            // console.log("config", config);
            setValues({
                bonuses: (config?.data?.bonus || config?.bonus || [])?.map(bonus => ({id: bonus?.id || null, value: bonus.title || ""})) || []
            })
        }
    }, [config])

    useEffect(() => {
        console.log("useBonus", data);
        dispatcher(stepSlice.actions.dynamicSave({ id: "Bonus", data: data || {} }));
        checkErrors();
    }, [data]);

    useEffect(() => {
        // console.log("useBonus error", error);
    }, [error])

    useEffect(() => {
        checkErrors();
    }, [])

    return {
        data,        // current data of the service request
        values,      // values to populate fields such as dropdowns
        loading,     // true = is saving request 
        error,       // error flag by data[id] eg. data["richiedente"] is invalid -> error.richiedente = true
        save,        // function to save the current service request
        savePrefs,   // function to save prefs into the store
        update,      // function to update the data object
        isValidStep, // function to check <stepId> data -> useful for nextButton disable status
        getBonusId,  // function to get the current bonusId
    }
}