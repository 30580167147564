import CheckedFormBox from "../UI-component/CheckedFormBox";
import ChoiceFormBox from "../UI-component/ChoiceFormBox";
import MultiTextBox from "../UI-component/MultiTextBox";
import SelectionFormBox from "../UI-component/SelectionFormBox";


//dinamically filters the data and sets the 
//data based on the given function.
//returns the modofied objects
//used to edit purposes
export const onDataChange2 = (e, func) => {
  const id = titleNormalizer(e.target.id);
  const itr = e.target.getAttribute("itr");

  func(prev => {
    let modified = { ...prev };
    Object.keys(modified).map(key => {

      Object.keys(modified[key]).map(iKey => {
        if (id === titleNormalizer(iKey)) {
          if (id === "codice_fiscale") {
            if (modified?.type) {
              if (modified.type?.type.includes("giuridica")) {
                const reg2 = new RegExp("^[0-9]{11}$");
                if (reg2.test(e.target.value)) {
                  modified[key][iKey] = e.target.value;
                }
              }
            } else {
              const reg = new RegExp("^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]{1}$");

              if (reg.test(e.target.value)) {
                modified[key][iKey] = e.target.value;
              }
            }
          } else if (id === "email") {
            const reg = new RegExp(`[\\w\\D]+.[\\w\\D]+@[\\w\\D]{3,20}[.][a-zA-Z\\D]{2,10}`);

            if (reg.test(e.target.value))
              modified[key][iKey] = e.target.value;
          } else {
            if (itr)
              modified[itr][iKey] = e.target.value;
            else {
              const obj = structuredClone(modified);
              obj[key][iKey] = e.target.value;
              modified = obj;
              // modified[key][iKey] = e.target.value;
            }
          }
        }
      })
    })

    return modified;
  })
}

//dinamically filters the data and sets the 
//data based on the given function.
//returns the modofied objects
//used to edit purposes
export const onDirectDataChange = (e, func, parentFields) => {
  const id = titleNormalizer(e.target.id);

  func(prev => {
    let modified = { ...prev };
    // console.log("Object.keys(modified)", Object.keys(modified))
    if(parentFields?.length > 0){
      let currentObj = modified;
      for(let field of parentFields){
        if(currentObj[field]) currentObj[field] = { ...currentObj[field] };
        else {
          currentObj[field] = {};  // Se non esiste, crea un nuovo oggetto
        }
        currentObj = currentObj[field];
      }
      console.log("obj", currentObj, id, Object.isFrozen(currentObj));
      currentObj[id] = e?.target?.value;
    }
    else{
      Object.keys(modified).map(key => {
        if (id === titleNormalizer(key)) {
  
          // if(titleNormalizer(id) === "email") {
          //   const reg = new RegExp(`[\\w\\D]+.[\\w\\D]+@[\\w\\D]{3,20}[.][a-zA-Z\\D]{2,10}`);
  
          //   if(reg.test(e.target.value)) 
          //     modified[key] = e.target.value;
          //   else            //TODO: TEST THIS!!!!!!!!!!!!!!!!!!!!!!!!
          //   modified[key] = e.target.value;
  
          // }else 
          modified[key] = e.target.value;
        }
      });
    }

    return modified;
  })
}

//dinamically filters the data and sets the 
//data based on the given function.
//returns the modofied objects
//used to (selection) edit purposes
export const onSelectData2 = (e, mainID, func, selectFunc) => {
  func(prev => {
    const newObj = { ...prev };

    if (newObj[mainID]) {
      const internal = { ...newObj[mainID] };

      internal[e.target.id] = e.target.options[e.target.selectedIndex].text;
      newObj[mainID] = internal;
    }
    else
      newObj[e.target.id] = e.target.options[e.target.selectedIndex].text;

    return newObj;
  })

  selectFunc(e.target.options[e.target.selectedIndex].text);
}

export const onFileSet2 = async (e, func) => {

  func(prev => {
    if (e.target.files.length <= 0) {
      const newObj = { ...prev };

      if (e.target.id.includes("isee")) {
        const id = `base64_${titleNormalizer(e.target.id)}`;
        newObj[id] = "";
        newObj[e.target.id] = "";

        return newObj;
      }

      if (e.target.id.startsWith("immagine")) {
        const id = `base64_${titleNormalizer(e.target.id)}`;
        newObj["disservizio"][id] = "";
        newObj["disservizio"][e.target.id] = "";
        return newObj;
      }

      if (e.target.id === "iban") {
        const id = `base64_${titleNormalizer(e.target.id)}`;
        newObj[id] = "";
        newObj[e.target.id] = "";
        return newObj;
      }

      const id = `base64_${titleNormalizer(e.target.id)}`;
      newObj["documenti"][id] = "";
      newObj["documenti"][e.target.id] = "";

      console.log(newObj);
      return newObj;
    }

    const url = URL.createObjectURL(e.target.files[0]);

    const newObj = { ...prev };

    if (e.target.id.includes("isee")) {
      const id = `base64_${titleNormalizer(e.target.id)}`;
      newObj[id] = url;
      newObj[e.target.id] = e.target.files[0].name;

      return newObj;
    }

    if (e.target.id.startsWith("immagine")) {
      const id = `base64_${titleNormalizer(e.target.id)}`;
      newObj["disservizio"][id] = url;
      newObj["disservizio"][e.target.id] = e.target.files[0].name;
      return newObj;
    }

    if (e.target.id === "iban") {
      const id = `base64_${titleNormalizer(e.target.id)}`;
      newObj[id] = url;
      newObj[e.target.id] = e.target.files[0].name;
      return newObj;
    }

    const id = `base64_${titleNormalizer(e.target.id)}`;
    newObj["documenti"][id] = url;
    newObj["documenti"][e.target.id] = e.target.files[0].name;

    return newObj;
  });

  // dispatcher(stepSlice.actions.dynamicSave({id: "disservizio",internalId: id, data: disserviceData.disservizio[id.toLowerCase()]})); 
}

// filter the form rendering with the given options
// build the forms dynamically based on the given data
export const dynamicRenderer = ({ type, title, options, description = "" }, defaultValue, indexer = [], oKey, classe, multiValues = [], update = () => { }) => {
  switch (type) {
    case "Select":
      return <SelectionFormBox
        key={uniqueIdBuilder()}
        showTitle={title[1] + "*"}
        title={title}
        description={description}
        choices={options}
        defaultValue={defaultValue}
        classDefaultValue={title[1] === "SCUOLA" ? classe : ""}
        oKey={oKey}
        update={update}
      />
    case "Choice":
      return <ChoiceFormBox
        key={uniqueIdBuilder()}
        type="radio"
        title={title}
        description={description}
        choices={options}
        defaultValue={defaultValue}
        indexer={indexer}
        update={update}
      />
    case "Checkbox":
      return <CheckedFormBox
        key={uniqueIdBuilder()}
        type="checkbox"
        title={title}
        description={description}
        choices={options}
        defaultValue={defaultValue}
        update={update}
      />
    case "MultiText":
      return <MultiTextBox
        key={uniqueIdBuilder()}
        type="text"
        multiValues={multiValues}
        title={title}
        description={description}
        choices={options}
        defaultValue={defaultValue}
        update={update}
      />
    default: break;
  }
}

//builds a unique identifier
//used for uniqueness of components
export const uniqueIdBuilder = () => {
  return parseInt(Date.now() * ((Math.random() * 31) * Math.random() * 9));
}

//normalizes the given string
//so that can be used for filtering purposes
export const titleNormalizer = (title) => {
  return title.replaceAll(" ", "_").toLowerCase();
}

export const titleExtractor = (title) => {
  if (title === "CodiceFiscale")
    return "Codice fiscale";

  if (title) {
    const extractedTitle = title.replaceAll("_", " ").toLowerCase();
    return extractedTitle[0].toUpperCase() + extractedTitle.slice(1);;
  }
  return;
}

//checks the validity of an object
//used for (form) purposes
export const checkValidity = (obj, half = false) => {
  let res = true;

  if (half) {
    Object.keys(obj).forEach(key => {
      if (obj[key] === "")
        res = false;
    })
    return res;
  }

  Object.keys(obj).forEach(key => {
    Object.keys(obj[key]).forEach(itm => {
      if (itm.includes("disabilita") || itm.includes("dieta")
        || itm.includes("planimetrico") || itm.includes("edilizia")
        || itm.includes("ufficio")
        || itm.includes("medico") || itm.includes("denuncia")
        || itm.includes("attestazione")
      ) {

      } else {
        if (obj[key][itm] === ""){
          console.log("checkValidity key", key, itm, obj[key][itm])
          res = false;
        }
      }
    })
  })

  return res;
}

// maps the the code of the service to the 
//property name that you find in the context
export const serviceFinder = (code) => {
  let service = null;

  switch (code) {
    case "RMS":
      service = "mensa_scolastica";
      break;
    case "RTS":
      service = "trasporto_scolastico";
      break;
    case "PSP":
      service = "suolo_pubblico";
      break;
    case "RAAT":
      service = "accessi";
      break;
    case "PPI":
      service = "parcheggio";
      break;
    case "PPC":
      service = "passo_carrabile";
      break;
    case "RPM":
      service = "matrimonio";
      break;
    case "DBE":
      service = "bonus";
      break;
    default:
      return "";
  }

  return service;
}

export const createDocs = (data) => {
  const documents = new Map();

  documents.set("RTSbeneficiary", {
    "base64_certificato_disabilita": data?.documenti?.base64_certificato_disabilita ?? "",
    "certificato_disabilita": data?.documenti?.certificato_disabilita ?? "",
  });

  documents.set("RPMbaseDoc", {
    "base64_carta_di_identita": data?.documenti?.base64_carta_di_identita ?? "",
    "carta_di_identita": data?.documenti?.carta_di_identita ?? "",
    "base64_tessera_sanitaria": data?.documenti?.base64_tessera_sanitaria ?? "",
    "tessera_sanitaria": data?.documenti?.tessera_sanitaria ?? "",

  });

  documents.set("baseDoc", {
    "base64_carta_di_identita": data?.documenti?.base64_carta_di_identita ?? "",
    "carta_di_identita": data?.documenti?.carta_di_identita ?? "",
  });

  documents.set("RSDdoc", {
    "base64_certificato_di_morte": data?.documenti?.base64_certificato_di_morte ?? "",
    "certificato_di_morte": data?.documenti?.certificato_di_morte ?? "",
  });

  documents.set("RMSbeneficiary", {
    "base64_certificato_disabilita": data?.documenti?.base64_certificato_disabilita ?? "",
    "certificato_disabilita": data?.documenti?.certificato_disabilita ?? "",
    "base64_certificato_dieta_patologica": data?.documenti?.certificato_dieta_patologica ?? "",
    "certificato_dieta_patologica": data?.documenti?.certificato_dieta_patologica ?? ""
  });

  documents.set("PSPbaseDoc", {
    "base64_carta_di_identita": data?.documenti?.base64_carta_di_identita ?? "",
    "carta_di_identita": data?.documenti?.carta_di_identita ?? "",
    "base64_schema_planimetrico": data?.documenti?.base64_schema_planimetrico ?? "",
    "schema_planimetrico": data?.documenti?.schema_planimetrico ?? "",
    "base64_pratica_edilizia": data?.documenti?.base64_pratica_edilizia ?? "",
    "pratica_edilizia": data?.documenti?.pratica_edilizia ?? "",
  });

  documents.set("PPIbaseDoc", {
    "base64_carta_di_identita": data?.documenti?.base64_carta_di_identita ?? "",
    "carta_di_identita": data?.documenti?.carta_di_identita ?? "",
    "base64_fototessera": data?.documenti?.base64_fototessera ?? "",
    "fototessera": data?.documenti?.fototessera ?? "",
    "base64_certificato_ufficio": data?.documenti?.base64_certificato_ufficio ?? "",
    "certificato_ufficio": data?.documenti?.certificato_ufficio ?? "",
    "base64_certificato_medico": data?.documenti?.base64_certificato_medico ?? "",
    "certificato_medico": data?.documenti?.certificato_medico ?? "",
    "base64_denuncia": data?.documenti?.base64_denuncia ?? "",
    "denuncia": data?.documenti?.denuncia ?? "",
  });

  return documents;
}

export const checkIrregulars = (internalItm) => {
  let inID = "";

  const irregularNames = [
    ["addr_sede_legale", "Indirizzo sede legale"],
    ["mun_sede_legale", "Comune sede legale"],
    ["addr_res_legale", "Indirizzo residenza del legale rappresentante"],
    ["mun_res_legale", "Comune residenza del legale rappresentante"],
    ["demoninazione", "Denominazione o ragione sociale"],
    ["iva", "Parita IVA"],
    ["nome_legale", "Nome legale rappresentante"],
    ["cognome_legale", "Cognome legale rappresentante"],
    ["data_nascita_legale", "Data di nascita del legale rappresentante"],
    ["luogo_nascita_legale", "Luogo di nascita del legale rappresentante"],
    ["codice_fiscale", "Codice Fiscale"],
    ["domicilio", "Comune residenza"],
    ["residenza", "Indirizzo residenza"],
    ["type", "Tipologia"],
    ["carta_di_identita", "Carta di identità"],
    ["certificato_disabilita", "Certificato di disabilità"],
  ]

  for (let j of irregularNames) {
    if (internalItm === j[0]) {
      inID = j[1];
      break;
    }
  }

  return inID;
}

// const onMultiChange = (e) => {
//   let modified = [];

//   for(let i of e.target.options) {
//       if(i.selected)
//           modified.push(i.text);
//   }

//   setData(prev => {
//       const newObj = {...prev};
//       newObj[e.target.id] = modified;

//       return newObj;
//   })
// }
