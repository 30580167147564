import AuthenticatedTenant from "../../Layouts/AuthenticatedLayoutTenant";
import HelpBox from "../../UI-component/HelpBopx";
import { useEffect, useState } from "react";
import WorkPath from "../WorkPath";
import { ServiceDetailsAPI } from "../../Apis/ServiceDetailsAPI";
import { PaymentApi } from "../../Apis/PaymentApi";
import { getCurrentDate } from "../service/utils";


const ICONS_BY_STATUS = {
  "success": "it-check-circle",
  "cancel": "it-warning-circle",
  "error": "it-close-circle",
}


const ICON_CLASS_BY_STATUS = {
  "success": "icon-success",
  "cancel": "icon-warning",
  "error": "icon-danger",
}

//TODO: ADD EMAIL ADDRS
function PagoPaEndingForm(props) {
  const [status, setStatus] = useState("");
  const [idRequest, setIdRequest] = useState('');
  const [service, setService] = useState('');
  const [amount, setAmount] = useState('');
  const [email, setEmail] = useState('');
  const [serviceID, setServiceID] = useState('');
  const [bread, setBread] = useState({});

  const home = JSON.parse(localStorage.getItem("configuration")).data.current.siteUrl;

  const checkPaymentStatus = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const status = urlParams.get('status');
    const id = urlParams.get('payment_id');
    const iuv = urlParams.get('iuv');
    if(status && id) await PaymentApi.checkPaymentStatus({id, status, iuv, page: "pagopa"});
  }

  useEffect(() => {
    window.scroll(0, 0);

    // console.log("http://servizi.brugnato.nemea.cloud:3000/pagopa?status=1&email=3&id_request=1&amount=3&description=2");
    const urlParams = new URLSearchParams(window.location.search);

    const status = urlParams.get('status');
    const email = urlParams.get('email');
    const id = urlParams.get('id_request');
    const amount = urlParams.get('amount');
    const serviceID = urlParams.get('service_id');
    const serviceName = urlParams.get('service_name');

    (async () => {
      try {
        const bread = await ServiceDetailsAPI.getBreadcrumbs(serviceID);
        setBread(bread);
        window.showRating();

      } catch (err) {
        console.log(err);
      }
    })()

    setStatus(status);
    setService(serviceName);
    setIdRequest(id);
    setAmount(amount);
    setEmail(email);
    setServiceID(email);


    checkPaymentStatus();
  }, [])

  

  return (
    <AuthenticatedTenant auth={{}} errors={{}} header={{}}>
      <div className="container">
        <div className="row justify-content-center">
          <WorkPath workPathInfo={{
            base: ["Home", home],
            medium: ["Servizi", home + "servizi/"],
            category: [bread.category_name, bread.category_url],
            serviceName: [bread.service_name, bread.service_url],
            end: "Pagamento",
          }} />
          <div className="col-12 col-lg-10">
            <div className="cmp-hero">
              <section className="it-hero-wrapper bg-white align-items-start" style={{ paddingTop: "30px" }}>
                <div className="it-hero-text-wrapper pt-0 ps-0 pb-40 pb-lg-60">

                  <div className="categoryicon-top d-flex">
                    <svg
                      className={`icon mr-10 icon-sm mb-1 ${ICON_CLASS_BY_STATUS[status]}`}
                      aria-hidden="true"
                    >
                      <use href={`../assets/bootstrap-italia/dist/svg/sprites.svg#${ICONS_BY_STATUS[status]}`}></use>
                    </svg>
                    {
                      status === "success" &&
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <h1
                          className="text-black hero-title"
                          data-element="page-name"
                        >
                          {"Pagamento effettuato"}
                        </h1>
                        <p className="titillium hero-text">
                          Grazie, abbiamo ricevuto il tuo pagamento per la pratica n.  {idRequest} - {bread.service_name}.
                        </p>
                        <p className="titillium hero-text">
                          <strong>Importo:</strong> {amount ? `${parseFloat(amount/100)?.toFixed(2)}€` : ""}
                        </p>
                        <p className="titillium hero-text">
                          <strong>Data pagamento:</strong> {getCurrentDate()}
                        </p>

                      </div>
                    }

                    {
                      status === "cancel" &&
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <h1
                          className="text-black hero-title"
                          data-element="page-name"
                        >
                          {"Pagamento annullato"}
                        </h1>
                        <p className="titillium hero-text">
                          Il pagamento per la pratica n.  {idRequest} - {bread.service_name} è stato annullato.
                        </p>
                        <p className="titillium hero-text">
                          <strong>Importo:</strong> {amount ? `${parseFloat(amount/100)?.toFixed(2)}€` : ""}
                        </p>
                        <p className="titillium hero-text">
                          <strong>Data pagamento:</strong> {getCurrentDate()}
                        </p>

                      </div>
                    }

                    {
                      status === "error" &&
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <h1
                          className="text-black hero-title"
                          data-element="page-name"
                        >
                          {"Pagamento rifiutato"}
                        </h1>
                        <p className="titillium hero-text">
                          Ci scusiamo, non abbiamo ricevuto il tuo pagamento per la pratica n.  {idRequest} - {bread.service_name}.
                        </p>
                        <p className="titillium hero-text">
                          <strong>Importo:</strong> {amount ? `${parseFloat(amount/100)?.toFixed(2)}€` : ""}
                        </p>
                        <p className="titillium hero-text">
                          <strong>Data pagamento:</strong> {getCurrentDate()}
                        </p>

                      </div>
                    }

                  </div>
                  <p className="titillium hero-text">
                    <br />
                    <br />
                    Abbiamo inviato il riepilogo all'email:
                    <br />
                    <strong id="email-recap">{email}</strong>
                  </p>

                  {
                    status.toLowerCase() === "success" &&
                    props.service !== "SPA" && props.service !== "SSD" && props.service !== "SRA" &&
                    <p className="mt-3 titillium hero-text" style={{ marginTop: '-10px !important' }}>
                      Puoi consultare lo stato del pagamento in qualunque momento dalla tua &nbsp;
                      <a href={`/`} className="t-primary text-paragraph">area riservata.</a>
                    </p>
                  }
                </div>

              </section>



            </div>

          </div>
        </div>

        <div >
          {/* <StarForm/> */}
          <HelpBox
            helpBoxInfo={{
              muniQuestionLink:
                "https://stellanello.nemea.cloud/domande-frequenti/",
              muniHelpLink: "https://stellanello.nemea.cloud/servizi/assistenza/",
              greenNumber: "tel:+39 0182 668000",
              bookLink: "https://stellanello.nemea.cloud/servizi/prenotazioni/",
              rDisservice: "",
            }}
          />
        </div>
      </div>
    </AuthenticatedTenant>

  )
}


export default PagoPaEndingForm;
