import React, { useEffect, useState } from "react";
// import ShareLinkHeader from "./ShareLinkHeader";
import { ConfigurationAPI } from "../Apis/ConfigurationAPI";

function LowerHeader() {
  const [header, setHeader] = useState(null);

  function b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }

  const headerDynamic = async () => {
    try {
      if (localStorage.getItem("configuration")) {
        const header = b64DecodeUnicode(JSON.parse(localStorage.getItem("configuration")).data?.header);

        setHeader(header);
        return;
      }

      await ConfigurationAPI.getConfiguration(false)
        .then((res) => {
          if (!res.success) {
            return;
          }

          const header = b64DecodeUnicode(res.data?.header);
          setHeader(header);
        });
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    headerDynamic();
  }, [])

  return (
    <>
      {
        header &&
        <div dangerouslySetInnerHTML={{ __html: header }}></div>
      }
    </>
  );
}

export default LowerHeader;
