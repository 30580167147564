import React, { useEffect, useState } from "react";
import PopupTextField from "../ui-el/PopupTextField";
import { onDataChange2, onFileSet2 } from "../../util/Dynamics";
import PopupBox from "../PopupBox";
import PopupSelectField from "../ui-el/PopupSelectField";


function MarriageUiForm(props) {
  const isApplicant2 = props.id === "Richiedente 2" || props.id === "Richiedente2";
  const prefData = props.pref;

  const [civ, setCiv] = useState(props?.defaultValue?.anagrafica?.stato_civile);


  const onChoice = (e) => {
    props.setCustomerData(prev => {
      const obj = structuredClone(prev);
      obj.anagrafica.stato_civile = e.target.options[e.target.selectedIndex].text;

      prev = obj;
      return { ...prev }
    });

    setCiv(e.target.options[e.target.selectedIndex].text);
  }


  useEffect(() => {
    setCiv(props?.defaultValue?.anagrafica?.stato_civile);
  }, [props?.defaultValue?.anagrafica?.stato_civile])

  return (
    <PopupBox close={props.close} save={props.save} title={props?.id}>
      {
        props.error &&
        <p style={{ color: "red", fontWeight: "bolder" }}>Controlla i campi email o codice fiscale/Completa tutti i campi</p>
      }

      <PopupTextField
        disabled={isApplicant2 ? false : true}
        defaultValue={isApplicant2 ? props?.isEdit ? props?.defaultValue?.anagrafica?.nome : "" : props?.isEdit ? props?.defaultValue?.anagrafica?.nome || prefData?.anagrafica?.nome : props.user.name || ""}
        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
        fieldType="text"
        id="nome"
        fieldTitle="Nome*"
        description={isApplicant2 ? "Inserire informazione per proseguire con la richiesta" : " "}
        error={props.error}
      />

      <PopupTextField
        defaultValue={isApplicant2 ? props?.isEdit ? props?.defaultValue?.anagrafica?.cognome : "" : props?.isEdit ? props?.defaultValue?.anagrafica?.cognome || prefData?.anagrafica?.cognome : props.user.familyName || ""}
        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
        fieldType="text"
        id="cognome"
        fieldTitle="Cognome*"
        disabled={isApplicant2 ? false : true}
        description={isApplicant2 ? "Inserire informazione per proseguire con la richiesta" : " "}
        error={props.error}
      />
      <PopupTextField
        description={isApplicant2 ? "Inserire informazione per proseguire con la richiesta" : " "}
        defaultValue={isApplicant2 ? props?.isEdit ? props?.defaultValue?.anagrafica?.codice_fiscale : "" : props?.isEdit ? props?.defaultValue?.anagrafica?.codice_fiscale || prefData?.anagrafica?.codice_fiscale : props.user.fiscalNumber || ""}
        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
        fieldType="text"
        id="codice_fiscale"
        fieldTitle="Codice Fiscale*"
        disabled={isApplicant2 ? false : true}
        regex="^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]{1}$"
        error={props.error}
      />
      <PopupTextField
        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
        fieldType="date"
        id="data_di_nascita"
        fieldTitle="Data di nascita*"
        defaultValue={isApplicant2 ? props?.isEdit ? props?.defaultValue?.anagrafica?.data_di_nascita : "" : props?.isEdit ? props?.defaultValue?.anagrafica?.data_di_nascita || prefData?.anagrafica?.data_di_nascita || props.user.dateOfBirth : props.user.dateOfBirth || ""}
        error={props.error}
        disabled={isApplicant2 ? false : true}
      />
      <PopupTextField
        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
        fieldType="text"
        id="luogo_di_nascita"
        fieldTitle="Luogo di nascita*"
        defaultValue={isApplicant2 ? props?.isEdit ? props?.defaultValue?.anagrafica?.luogo_di_nascita : "" : props?.isEdit ? props?.defaultValue?.anagrafica?.luogo_di_nascita || prefData?.anagrafica?.luogo_di_nascita || props.user.placeOfBirth : props.user.placeOfBirth || ""}
        error={props.error}
        disabled={isApplicant2 ? false : true}
      />
      <PopupSelectField
        defaultValue={props?.defaultValue?.anagrafica?.stato_civile || prefData?.anagrafica?.stato_civile}
        onChange={onChoice}
        id="stato_civile"
        fieldTitle={"Stato civile"}
        options={["Celibe/nubile", "Vedovo/a", "Libero/a dal vincolo matrimoniale"]}
        description="Seleziona lo stato civile" />

      {civ === "Vedovo/a" &&
        <>
          <PopupTextField
            defaultValue={(props?.isEdit) ? props?.defaultValue?.anagrafica?.con_chi || prefData?.anagrafica?.con_chi : prefData?.anagrafica?.con_chi || ""}
            onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
            fieldType="text"
            id="con_chi"
            fieldTitle="Con chi*"
            description={""}
            error={props.error}
          />

          <PopupTextField
            defaultValue={(props?.isEdit) ? props?.defaultValue?.anagrafica?.comune_di_registrazione_atto || prefData?.anagrafica?.comune_di_registrazione_atto : prefData?.anagrafica?.comune_di_registrazione_atto || ""}
            onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
            fieldType="text"
            id="comune_di_registrazione_atto"
            fieldTitle="Comune di registrazione dell'atto*"
            description={""}
            error={props.error}
          />

          <PopupTextField
            defaultValue={(props?.isEdit) ? props?.defaultValue?.anagrafica?.data_di_registrazione_atto || prefData?.anagrafica?.data_di_registrazione_atto : prefData?.anagrafica?.data_di_registrazione_atto || ""}
            onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
            fieldType="date"
            id="data_di_registrazione_atto"
            fieldTitle="Data di registrazione dell'atto*"
            description={""}
            error={props.error}
          /></>
      }

      <PopupTextField
        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
        fieldType="text"
        id="residenza"
        fieldTitle="Indirizzo residenza*"
        defaultValue={props?.isEdit ? props?.defaultValue?.indirizzi?.residenza || prefData?.indirizzi?.residenza : prefData?.indirizzi?.residenza || ""}
        error={props.error}
      />
      <PopupTextField
        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
        fieldType="text"
        id="domicilio"
        fieldTitle="Comune residenza*"
        defaultValue={props?.isEdit ? props?.defaultValue?.indirizzi?.domicilio || prefData?.indirizzi?.domicilio : prefData?.indirizzi?.domicilio || ""}
        error={props.error}
      />

      <>
        <PopupTextField
          onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
          fieldType="text"
          id="telefono"
          fieldTitle="Telefono*"
          defaultValue={props?.defaultValue?.contatti?.telefono}
          regex="[0-9]{8}"
          error={props.error}
        />
        <PopupTextField
          onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
          fieldType="email"
          id="email"
          fieldTitle="Email*"
          defaultValue={props?.defaultValue?.contatti?.email}
          regex={`[\\w\\D]+.[\\w\\D]+@[\\w\\D]{3,20}[.][a-zA-Z\\D]{2,10}`}
          error={props.error}
        />
      </>

      <PopupTextField
        onChange={(e) => { onFileSet2(e, props.setCustomerData) }}
        defaultValue={props.defaultValue?.documenti?.carta_di_identita || prefData?.documenti?.carta_di_identita || ""}
        fieldType="file"
        id={"carta_di_identita"}
        fieldTitle={"Carta di identità*"}
        error={props.error}
      />

      <PopupTextField
        onChange={(e) => { onFileSet2(e, props.setCustomerData) }}
        defaultValue={props.defaultValue?.documenti?.tessera_sanitaria || prefData?.documenti?.tessera_sanitaria || ""}
        fieldType="file"
        id={"tessera_sanitaria"}
        fieldTitle={"Codice fiscale o tessera sanitaria*"}
        error={props.error}
      />
    </PopupBox>





  )
}

export default MarriageUiForm;