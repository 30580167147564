import React, { useCallback, useEffect, useState } from "react";
import SideList from "../../../../UI-component/SideList";
import SelectionFormBox from "../../../../UI-component/SelectionFormBox";
import { OfficeAPI } from "../../../../Apis/OfficeAPI";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import stepSlice from "../../../../context/StepsContext";
import ErrorAlert from "../../../../UI-component/ErrorAlert";
import { PublicOfficeAPI } from "../../../../Apis/PublicOfficeAPI";
import NextBtnForm from "../../../../UI-component/NexBtnForm";

function Location(props) {
  const dispatcher = useDispatch();
  const ctx = useSelector(state => state.stepSlice.data.ufficio);

  const [offices, setOffices] = useState([]);

  const [testData, setTestData] = useState(ctx?.ufficio ?? {});

  const [isMounted, setIsMounted] = useState(false);

  const getData = useCallback(async () => {
    const offices = [];

    try {
      const res = await PublicOfficeAPI.getOffices();

      if (!res.success) {
        return;
      }

      res?.data.forEach(i => {
        offices.push({ id: i.id, value: i.title + " - " + i.address })
      });

      const urlParams = new URLSearchParams(window.location.search);

      if (urlParams.size > 0)
        directOffice(offices);
      // if (isMounted)
      setOffices(offices);
    } catch (err) {
      console.log(err);
    }
  }, [setOffices])

  const errorChecker = () => {
    if (testData?.value) {
      setError(prev => {
        return { ...prev, office: false }
      })
    }
    else {
      setError(prev => {
        return { ...prev, office: true }
      })
    }
  }

  const [error, setError] = useState({
    office: true,
  });

  const update = (data) => {
    setTestData({
      id: data.selectionID,
      value: data.data
    });
  }

  const onNext = () => {
    dispatcher(stepSlice.actions.dynamicSave({ id: "Ufficio", internalId: "ufficio", data: testData }));

    props.next();
  }

  useEffect(() => {
    setIsMounted(true);

    errorChecker();

    // if (isMounted === true) {
    getData();

    return () => setIsMounted(false);
    // }

  }, [])

  useEffect(() => {
    errorChecker();
  }, [testData])

  const directOffice = (offices) => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');

    let found = {};

    offices.forEach((itm) => {
      if (itm.id === parseFloat(id)) {
        found = itm;
        return;
      }
    });

    setTestData(found);
  }

  return (
    <React.Fragment>
      <SideList infoList={[{ element: "Ufficio", id: "#office" }]} />

      <div className="col-12 col-lg-8 offset-lg-1 section-wrapper">
        <div className="steppers-content" aria-live="polite">
          <div className="it-page-sections-container">
            {
              <ErrorAlert errors={[{ name: "Ufficio", id: "#isee-info" }]} err={error.office} />
            }

            <SelectionFormBox
              showTitle={"Ufficio*"}
              title="Ufficio"
              description="Scegli l'ufficio a cui vuoi richiedere l'appuntamento"
              selectTitle="Seleziona l'ufficio"
              choices={offices}
              defaultValue={testData?.value ?? "Seleziona un'opzione"}
              oKey={"Ufficio"}
              objectify={true}
              update={update}
              disabled={new URLSearchParams(window.location.search).size === 0 ? false : true}

            />
            <br></br>
          </div>
          <NextBtnForm
            next={onNext}
            back={props.back}
            noSave={true}
          />
          {/* <div style={{ padding: "1rem" }}>
            <button
              type="submit"
              className={`${`btn btn-primary ${error.office ? "disabled" : ""}`} `}
              style={{ padding: "0.5rem 5rem 0.5rem 5rem" }}
              data-bs-validate="validate"
              onClick={onNext}
            >
              <span className="text-button-sm">Avanti</span>
            </button>
          </div> */}
        </div>
      </div>
    </React.Fragment>
  )
}

export default Location;