import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SummaryCard from "../../../UI-component/summary/SummaryCard";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import FormBoxField from "../../../UI-component/ui-el/FormBoxField";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import Loader from "../../../UI-component/Loader";
import stepSlice from "../../../context/StepsContext";

function Summary(props) {
    const dispatcher = useDispatch();
    const ctx = useSelector(state => state.stepSlice.data);

    // Stato locale per gestire i dati aggiornati
    const [localCtx, setLocalCtx] = useState(ctx);
    const [applicant, setApplicant] = useState(ctx.richiedente[0]);
    const [pref, setPref] = useState(ctx.preferenze);
    const [loading, setLoading] = useState(false);

    const onNext = async () => {
        const data = {
            richiedente: [applicant],
            preferenze: pref
        };
        dispatcher(stepSlice.actions.dynamicSave({ id: "Preferenze", internalId: "accessi", data: pref }));
        dispatcher(stepSlice.actions.dynamicSave({ id: "Richiedente", data: { ...applicant, selected: true } }));



        props.send(data);
    };

    const save = async () => {
        const data = {
            richiedente: [{ ...applicant, selected: true }],
            preferenze: pref
        };

        dispatcher(stepSlice.actions.dynamicSave({ id: "Preferenze", internalId: "accessi", data: pref }));
        dispatcher(stepSlice.actions
            .dynamicSave({ id: "Richiedente", data: { ...applicant, selected: true } }));



        setLoading(true);

        await props.save(data);

        setLoading(false);
    };

    const update = (data) => {
        if (data.id === "Richiedente") {
            const updatedCtx = { ...localCtx, richiedente: [data.data[0]] };
            setLocalCtx(updatedCtx);
            setApplicant(data.data[0]);
            dispatcher(stepSlice.actions.dynamicSave({ id: "Richiedente", data: { ...data?.data?.[0], selected: true } }));
        } else if (data.id === "Preferenze") {
            const updatedCtx = { ...localCtx, preferenze: data.data };
            setLocalCtx(updatedCtx);
            setPref(data.data);
            dispatcher(stepSlice.actions.dynamicSave({ id: "Preferenze", internalId: "accessi", data: data.data }));
        }
    };

    return (
        <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2">
                <div className="steppers-content" aria-live="polite">
                    <div className="it-page-sections-container">
                        {/* Loader per indicare il caricamento */}
                        <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-55%,-50%)", zIndex: "10000" }}>
                            <Loader loading={loading} />
                        </div>

                        {/* Componente SummaryCard per il richiedente */}
                        <SummaryCard
                            type="richiedente"
                            cardTitle={`${applicant?.anagrafica?.nome} ${applicant?.anagrafica?.cognome}`}
                            mod={true}
                            update={update}
                            id={"Richiedente"}
                            taxID={applicant?.anagrafica.codice_fiscale}
                            service={props.info.code}
                            registryData={applicant}
                        >
                            <FormBoxField title="Nome" value={applicant?.anagrafica.nome} />
                            <FormBoxField title="Cognome" value={applicant?.anagrafica.cognome} />
                            <FormBoxField title="Codice Fiscale" value={applicant?.anagrafica.codice_fiscale} />
                            <FormBoxField title="Data di nascita" value={applicant?.anagrafica.data_di_nascita} />
                            <FormBoxField title="Luogo di nascita" value={applicant?.anagrafica.luogo_di_nascita} />
                            <FormBoxField title="Indirizzo residenza" value={applicant?.indirizzi.residenza} />
                            <FormBoxField title="Comune residenza" value={applicant?.indirizzi.domicilio} />
                            <FormBoxField title="Telefono" value={applicant?.contatti.telefono} />
                            <FormBoxField title="Email" value={applicant?.contatti.email} />
                            <FormBoxField title="Carta di identità" value={applicant?.documenti.carta_di_identita} />
                        </SummaryCard>

                        <SummaryCard
                            type="Preferenze"
                            mod={true}
                            cardTitle={`Preferenze di servizio`}
                            service="RAAT"
                            registryData={localCtx}
                            choiceData={[
                                {
                                    "options": [
                                        { id: 1, value: "Richiesta di accesso civico generalizzato" },
                                        { id: 2, value: "Richiesta di accesso agli atti amministrativi" }
                                    ],
                                    "title": [
                                        "ACCESSI",
                                        "TIPOLOGIA"
                                    ],
                                    "type": "Select"
                                },
                                {
                                    "options": [
                                        { id: 1, value: "Ritiro presso l'ufficio del Comune" },
                                        { id: 2, value: "Invio tramite email" },
                                        { id: 3, value: "Invio tramite raccomandata" }
                                    ],
                                    "title": [
                                        "ACCESSI",
                                        "MODALITA"
                                    ],
                                    "type": "Select"
                                },

                                {
                                    "options": [
                                        {
                                            "id": 1,
                                            "title": "Indirizzo spedizione"
                                        }
                                    ],
                                    "title": [
                                        "ACCESSI",
                                        "INDIRIZZO"
                                    ],
                                    "child_title": [],
                                    "type": "MultiText"
                                },
                                {
                                    "options": [
                                        {
                                            "id": 1,
                                            "title": "Documento"
                                        },
                                        {
                                            "id": 2,
                                            "title": "Informazione"
                                        },
                                        {
                                            "id": 3,
                                            "title": "Dato"
                                        }
                                    ],
                                    "title": [
                                        "ACCESSI",
                                        "CHIEDE"
                                    ],
                                    "child_title": [
                                        "Documento",
                                        "Informazione",
                                        "Dato"
                                    ],
                                    "type": "MultiText"
                                },
                                {
                                    "options": [
                                        {
                                            "id": 1,
                                            "title": "Motivo"
                                        }
                                    ],
                                    "title": [
                                        "ACCESSI",
                                        "MOTIVO"
                                    ],
                                    "child_title": [],
                                    "type": "MultiText"
                                }
                            ]}
                            update={update}
                        >

                            <FormBoxField title="Tipologia" value={pref.accessi.tipologia || "Non compilato"} />
                            <FormBoxField title="Modalità di ritiro" value={pref?.accessi?.modalita || "Non compilato"} />
                            {pref?.accessi?.modalita === "Invio tramite raccomandata" && (
                                <FormBoxField title="Indirizzo di spedizione" value={pref?.accessi?.indirizzo_spedizione || "Non compilato"} />
                            )}
                            <FormBoxField title="Documento" value={pref?.accessi?.chiede.documento || "Non compilato"} />
                            <FormBoxField title="Informazione" value={pref?.accessi?.chiede.informazione || "Non compilato"} />
                            <FormBoxField title="Dato" value={pref?.accessi?.chiede.dato || "Non compilato"} />
                            <FormBoxField title="Motivo" value={pref?.accessi?.motivo || "Non compilato"} />
                            {Array.isArray(pref?.accessi?.dichiaro) &&
                                pref?.accessi.dichiaro.map(el => {
                                    return <FormBoxField title="Dichiaro" value={el} key={el} />
                                })
                            }
                        </SummaryCard>

                        <NextBtnForm
                            send={onNext}
                            back={props.back}
                            saveRequest={save}
                            last={true}
                            noSave={false}
                        />
                        {props.saved && <SuccessfullPopup />}
                        {props.fail && <SuccessfullPopup isError={true} />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Summary;
