import React from "react";
import PopupTextField from "../ui-el/PopupTextField";
import { onDataChange2, onFileSet2 } from "../../util/Dynamics";
import PopupBox from "../PopupBox";


function VotiveUiForm(props) {

    const prefData = props.prefData;
    let user = localStorage.getItem("user") ? JSON.parse(JSON.parse(localStorage.getItem("user"))?.data) : {};

    return (
        <PopupBox close={props.close} save={props.save} title={props?.id}>
            {
                props.error &&
                <p style={{ color: "red", fontWeight: "bolder" }}>Controlla i campi email o codice fiscale/Completa tutti i campi</p>
            }

            <PopupTextField
                disabled={true}
                defaultValue={props?.defaultValue?.anagrafica?.nome}
                onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                fieldType="text"
                id="nome"
                fieldTitle="Nome*"
                description={" "}
                error={props.error}
            />
            <PopupTextField
                defaultValue={props?.defaultValue?.anagrafica?.cognome}
                onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                fieldType="text"
                id="cognome"
                fieldTitle="Cognome*"
                disabled={true}
                description={" "}
                error={props.error}
            />
            <PopupTextField
                description={" "}
                defaultValue={props?.defaultValue?.anagrafica?.codice_fiscale}
                onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                fieldType="text"
                id="codice_fiscale"
                fieldTitle="Codice Fiscale*"
                disabled={true}
                regex="^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]{1}$"
                error={props.error}
            />
            <PopupTextField
                onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                fieldType="date"
                id="data_di_nascita"
                fieldTitle="Data di nascita*"
                defaultValue={props?.defaultValue?.anagrafica?.data_di_nascita || user.dateOfBirth}
                error={props.error}
                disabled={true}
            />
            <PopupTextField
                onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                fieldType="text"
                id="luogo_di_nascita"
                fieldTitle="Luogo di nascita*"
                defaultValue={props?.defaultValue?.anagrafica?.luogo_di_nascita || user.dateOfBirth}
                error={props.error}
                disabled={true}
            />
            <PopupTextField
                onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                fieldType="text"
                id="residenza"
                fieldTitle="Indirizzo residenza*"
                defaultValue={props?.defaultValue?.indirizzi?.residenza}
                error={props.error}
            />
            <PopupTextField
                onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                fieldType="text"
                id="domicilio"
                fieldTitle="Comune residenza*"
                defaultValue={props?.defaultValue?.indirizzi?.domicilio}
                error={props.error}
            />
            <>
                <PopupTextField
                    onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                    fieldType="text"
                    id="telefono"
                    fieldTitle="Telefono*"
                    defaultValue={props?.defaultValue?.contatti?.telefono}
                    regex="[0-9]{8}"
                    error={props.error}
                />
                <PopupTextField
                    onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
                    fieldType="email"
                    id="email"
                    fieldTitle="Email*"
                    defaultValue={props?.defaultValue?.contatti?.email}
                    regex={`[\\w\\D]+.[\\w\\D]+@[\\w\\D]{3,20}[.][a-zA-Z\\D]{2,10}`}
                    error={props.error}
                />
            </>

            {/* <PopupTextField
                onChange={(e) => { onFileSet2(e, props.setCustomerData) }}
                defaultValue={props.defaultValue?.documenti?.carta_di_identita || prefData?.documenti?.carta_di_identita || ""}
                fieldType="file"
                id={"carta_di_identita"}
                fieldTitle={"Carta di identità*"}
                error={props.error}
            /> */}
        </PopupBox>





    )
}

export default VotiveUiForm;