import React from "react";
import CustomerRegistry from "./CustomerRegistry";
// import { useSelector } from "react-redux";
import { titleNormalizer, uniqueIdBuilder } from "../../util/Dynamics";

function CustomerCard(props) {
    const { typeData } = props;
    const onChoice = (e) => {
        // dispatcher(stepSlice.actions.changeSelected({id: props?.title, taxID: e.target.getAttribute("data-taxid")}))
        const tmp = props.info;

        tmp[0].selected = true;

        props.update({
            id: titleNormalizer(props.title),
            data: tmp
        })

    }



    const cardCreation = (itm, y, idx) => {
        const title = itm?.anagrafica?.nome ? `${itm?.anagrafica?.nome} ${itm?.anagrafica?.cognome}` : (itm?.anagrafica?.ragione_sociale || itm?.anagrafica?.demoninazione || "Informazioni mancanti")
        // console.log("cardCreation", itm, title);
        return (
            <>
                <label htmlFor={"a" + idx} style={{ display: "flex" }}>
                    <h3 className="big-title mb-0">{title}</h3>
                </label>
            </>
        )
    }

    // console.log("customer card", props);


    return (
        <>
            {
                props.info &&
                props.info.map((itm, y) => {
                    const idx = uniqueIdBuilder();
                    // console.log("itm", itm);
                    return (
                        <div
                            className="cmp-info-button-card radio-card"
                            key={uniqueIdBuilder()}>
                            <div className="card p-3 p-lg-4">
                                <div className="card-header mb-0 p-0">
                                    <div className="form-check m-0">
                                        <input
                                            type="button"
                                            value="Rimuovi"
                                            onClick={(e) => { props.onRemove(e, itm, y) }}
                                            className={"remove-btn"}
                                            style={{
                                                borderRadius: "4px",
                                                outline: "none",
                                                border: "none",
                                                background: "var(--primary)",
                                                color: "white",
                                                fontSize: "12px",
                                                width: "60px"
                                            }} />
                                        {
                                            cardCreation(itm, y, idx)
                                        }
                                    </div>
                                </div>
                                <div className="card-body p-0">
                                    {
                                        itm?.anagrafica?.codice_fiscale &&
                                        <p className="card-info">{typeData === "Persona giuridica" && itm?.anagrafica?.iva ? "Partita IVA" : (itm.anagrafica?.codice_fiscale.length === 11 ? `Codice Fiscale o Partita IVA` : `Codice Fiscale`)} <br />
                                            <span>{typeData === "Persona giuridica" && itm?.anagrafica?.iva ? itm?.anagrafica?.iva : itm?.anagrafica?.codice_fiscale}</span>
                                        </p>
                                    }
                                    <CustomerRegistry
                                        registryData={itm}
                                        key={uniqueIdBuilder()}
                                        index={y}
                                        id={props.id}
                                        taxID={itm?.anagrafica?.codice_fiscale}
                                        title={props?.title || props.id}
                                        typeData={props?.typeData}
                                        service={props.service}
                                        update={props.update} />
                                </div>
                            </div>
                        </div>
                    )
                })
            }

        </>
    )
}

export default CustomerCard;