import { api } from "./Configs/AxiosConfigs"


export const MessageApi = {

    getMessages: async function () {
        const response = await api.request({
          url: "/messages/",
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
          }
        })  
        return response.data;
      }
 
}
