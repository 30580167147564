

function LinkSkipper() {
    return (
        <div className="skiplink">
            <a className="visually-hidden-focusable" href="#main-container">Vai ai contenuti</a>
            <a className="visually-hidden-focusable" href="#footer">Vai al footer</a>
        </div>
    )
}

export default LinkSkipper;