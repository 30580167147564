import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideList from "../../../UI-component/SideList";
import ErrorAlert from "../../../UI-component/ErrorAlert";
import CustomerFormBox from "../../../UI-component/registryForms/CustomerFormBox";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import stepSlice from "../../../context/StepsContext";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import Loader from "../../../UI-component/Loader";

function General(props) {
  const dispatcher = useDispatch();
  const ctx = useSelector(state => state.stepSlice.data.cimitero);
  const preferences = useSelector(state => state.stepSlice.data.preferenze);

  const isStopped = useSelector(state => state.stepSlice.data.stopped);

  const [applicant, setApplicant] = useState(ctx.concessionario);
  const [deceased, setDeceased] = useState(ctx.defunto);
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState({
    applicant: false,
    deceased: false
  });

  const errorChecker = () => {
    // console.log("errorChecker", applicant, !applicant || applicant?.length <= 0 ? true : false, isStopped);
    setError(prev => {
      const appl = !applicant || applicant?.length <= 0 ? true : false;
      const dec = !deceased || deceased?.length <= 0 ? true : false;

      return { ...prev, applicant: appl, deceased: dec }
    })
  }

  useEffect(() => {
    errorChecker();
  }, [applicant, deceased])

  const update = (data) => {
    if (typeof data === "object") {
      let updatedData = {
        concessionario: applicant?.length > 0 ? [{ ...applicant[0], selected: true }] : [],
        defunto: deceased?.length > 0 ? [{ ...deceased[0], selected: true }] : [],
      }

      if (data.id.toLowerCase() === "concessionario") {
        if (data.removing) {
          setApplicant(undefined);
          updatedData.concessionario = []
          dispatcher(stepSlice.actions.dynamicSave({ id: "Cimitero", data: updatedData }));
          return;
        }

        setApplicant(data.data);
        updatedData.concessionario = [{...data.data?.[0], selected: true}]
      } else {
        if (data.removing) {
          setDeceased(undefined);
          updatedData.defunto = []
          dispatcher(stepSlice.actions.dynamicSave({ id: "Cimitero", data: updatedData }));
          return;
        }

        setDeceased(data.data);
        updatedData.defunto = [{...data.data?.[0], selected: true}]
      }

      dispatcher(stepSlice.actions.dynamicSave({ id: "Cimitero", data: updatedData }));
    }
  }

  const onNext = () => {
    const data = {
      concessionario: applicant?.length > 0 ? [{ ...applicant[0], selected: true }] : [],
      defunto: deceased?.length > 0 ? [{ ...deceased[0], selected: true }] : [],
    }

    dispatcher(stepSlice.actions.dynamicSave({ id: "Cimitero", data: data }));

    props.next();
  }

  const save = async () => {
    const data = {
      concessionario: applicant?.length > 0 ? [{ ...applicant[0], selected: true }] : [],
      defunto: deceased?.length > 0 ? [{ ...deceased[0], selected: true }] : [],
      preferenze: preferences
    }

    setLoading(true);

    await props.save(data);

    dispatcher(stepSlice.actions
      .dynamicSave({ id: "Cimitero", data: data }));
    dispatcher(stepSlice.actions
      .dynamicSave({ id: "Preferenze", internalId: "cimitero", data: { ...preferences.cimitero } }));

    setLoading(false);
  }

  useEffect(() => {
    errorChecker();
  }, [isStopped])

  return (
    <div className="row">
      <SideList
        infoList={[
          { element: "Concessionario", id: "#report-author" },
          { element: "Defunto", id: "#report-author" },
        ]} />

      <div className="col-12 col-lg-8 offset-lg-1">
        <div className="steppers-content" aria-live="polite">
          <div className="it-page-sections-container">
            {
              <ErrorAlert errors={[{ name: "Concessionario", id: "#isee-info" }]} err={error.applicant} />
            }

            {
              <ErrorAlert errors={[{ name: "Defunto", id: "#isee-info" }]} err={error.deceased} />
            }

            <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-55%,-50%)", zIndex: "10000" }}>
              <Loader loading={loading} />
            </div>
            <CustomerFormBox id="concessionario"
              cards={applicant?.length > 0 ? [applicant] : []}
              type={false}
              description="Informazione sul concessionario"
              title="Concessionario"
              service={props.info.code}
              update={update}
              prefData={props.info.data?.concessionario}
            />

            <CustomerFormBox id="defunto"
              cards={deceased?.length > 0 ? [deceased] : []}
              type={false}
              description="Informazioni sulla persona defunta"
              title="Defunto"
              service={props.info.code}
              update={update}
              prefData={props.info.data?.defunto}
            />
          </div>
        </div>
        <NextBtnForm
          next={onNext}
          back={props.back}
          noSave={false}
          saveRequest={save}
          disabled={error.applicant || error.deceased} />

        {
          props.saved &&
          <SuccessfullPopup />
        }

        {
          props.fail &&
          <SuccessfullPopup isError={true} />
        }
      </div>
    </div>
  )
}


export default General;